import React from 'react'
import {isOdd, toggleArrayItem} from '../../../utils/helpers'
import ChassisBreaker from './ChassisBreaker'
import ChassisEmptyPole from './ChassisEmptyPole'
import ChassisSelection from './ChassisSelection'

export default class ChassisBoard extends React.Component {

    constructor() {
        super()
        // Bind this to element function calls for better performance / practise
        // - ref: https://github.com/yannickcr/eslint-plugin-react/blob/master/docs/rules/jsx-no-bind.md
        this.deleteCall = this.deleteCall.bind(this)
        this.updateBrekerChassisNote = this.updateBrekerChassisNote.bind(this)

        // Internal states to track what mode the selections are
        this.state = {
            notesOpen: []
        }
    }

    clickCall(index) {
        this.props.clickedCallback(index)
    }

    deleteCall(chassisIndex, itemId) {
        this.props.deleteCallback(chassisIndex, itemId)
    }

    updateBrekerChassisNote(positionIndex, note) {
        this.props.updateBrekerChassisNote(positionIndex, note)
    }

    updateNotesCall(positionIndex) {
        const noteInput = this.refs['inputNotes-' + positionIndex].value
        this.props.updateNotesCallback(positionIndex, noteInput)
        this.togglelNotesCall(positionIndex)
    }

    togglelNotesCall(positionIndex) {
        this.setState({
            notesOpen: toggleArrayItem(this.state.notesOpen, positionIndex)
        })
    }

    renderRows() {
        const rowCounts = Math.floor(this.props.poles / 2)
        const rows = []
        for (let i = 0; i < rowCounts; i++) {
            // Find the row color based on the passed in prop
            const rowIndex = (i * 2 + 1) + this.props.indexOffset
            const rightIndex = rowIndex + 1
            const rightPoleDisabled = (this.props.disabledIndex2 && rightIndex === (this.props.hybridPoleCount + 2))
            const isLarge = this.props.hybridPoleCount ? (rightIndex <= this.props.hybridPoleCount) : false

            // Check if there is a breaker that is positioned on this index
            const leftBoardBreaker = this.props.breakers.find(item => item.ChassisPositionIndex === rowIndex)
            const rightBoardBreaker = this.props.breakers.find(item => item.ChassisPositionIndex === rightIndex)

            // Pushes in a row into the array to be rendered out
            rows.push(
                <React.Fragment key={ rowIndex }>
                    { !leftBoardBreaker ?
                            <ChassisEmptyPole rowIndex={ rowIndex } /> 
                        :
                            <div className="switchboard__slot">
                                <span className="switchboard__slot-number">{ rowIndex }</span>
        
                                { this.renderBreakers(rowIndex, isLarge) }
                            </div>
                    }

                    { !rightBoardBreaker && !rightPoleDisabled ?
                            <ChassisEmptyPole rowIndex={ rightIndex } /> 
                        :
                            <React.Fragment>
                            {
                                rightPoleDisabled ?
                                    <div className="switchboard__slot is-disabled">
                                        <span className="switchboard__slot-number">{ rightIndex }</span>
                                        <div className="switchboard__slot-text switchboard__slot-text-height">
                                            <span className="available-text">
                                                <span>{ this.props.disabledIndex2ComponentName }</span>
                                            </span>
                                        </div>
                                    </div>
                                :
                                    <div className={`switchboard__slot ${rightPoleDisabled ? 'disabled' : ''}`}>
                                        <span className="switchboard__slot-number">{ rightIndex }</span>
                                        
                                        { this.renderBreakers(rightIndex, isLarge) }
                                    </div>
                            }
                            </React.Fragment>
                    }
                </React.Fragment>
            )
        }

        return rows
    }

    renderNote(index) {
        const noteItem = this.props.breakers.find(item => item.ChassisPositionIndex === index)
        if (!noteItem) {
            return
        }

        const isOpen = this.state.notesOpen.includes(index)
        return (
            <div className={`chassis-note ${isOdd(index) ? 'left-side' : 'right-side'} ${isOpen ? 'open' : ''}`} >
                {isOpen &&
                    <div className="chassis-note-input" onClick={e => e.stopPropagation()}>
                        <textarea ref={`inputNotes-${index}`} defaultValue={noteItem.BreakerCircuitLabel}></textarea>
                        <div className="btn btn-icon note-cancel" onClick={this.togglelNotesCall.bind(this, index)}>
                            Cross Icon
                        </div>
                        <div className="btn btn-icon note-save" onClick={this.updateNotesCall.bind(this, index)}>
                            Tick Icon
                        </div>
                    </div>
                }
                {!isOpen && !noteItem.BreakerCircuitLabel &&
                    <div className="chassis-note-icon" onClick={this.togglelNotesCall.bind(this, index)}>
                        Plus Icon
                    </div>
                }
                {!isOpen && noteItem.BreakerCircuitLabel &&
                    <div className="chassis-note-icon" onClick={this.togglelNotesCall.bind(this, index)}>
                        Pen Icon
                    </div>
                }
            </div>
        )
    }

    renderEmptySlot() {
        return (
            <div className="switchboard__slot-text">
                <span className="available-text">
                    <span>Slot Available</span>
                </span>
                <span className="hover-text">
                    <span>Drag and drop a breaker from your library</span>
                </span>
            </div>
        )
    }

    renderBreakers(index, isLarge = false) {
        if (!this.props.breakers || !this.props.breakers.length) { 
            return this.renderEmptySlot()
        }

        // Check if there is a breaker that is positioned on this index
        const boardBreaker = this.props.breakers.find(item => item.ChassisPositionIndex === index)
        if (!boardBreaker) { 
            return this.renderEmptySlot()
        }
        const breakerObj = this.props.breakerData.find(dataItem => dataItem.ItemID === boardBreaker.BreakerTypeItemID)
        const displayCode = boardBreaker.ParentBreakerCurrentRating ? `${breakerObj.DrawingDesc}-${boardBreaker.ParentBreakerCurrentRating}A` : breakerObj.DrawingDesc
        // Render the breaker if we found one in this index position

        return (
            <ChassisBreaker
                itemId={boardBreaker.ItemID}
                code={displayCode}
                poles={breakerObj.PoleCount}
                BreakerCircuitLabelInput={boardBreaker.BreakerCircuitLabel}
                chassisPosIndex={boardBreaker.ChassisPositionIndex}
                deletedCallback={this.deleteCall}
                updateBrekerChassisNote={this.updateBrekerChassisNote}
                isLarge={isLarge}
                />
        )
    }

    render() { 
        return (
            <div className="switchboard">
                <ChassisSelection
                    chassis1filled={ this.props.chassis1filled }
                    chassis2filled={ this.props.chassis2filled }
                    selectedIndex={ this.props.selectedIndex }
                    selectedCallback={ this.props.selectedCallback } 
                    /> 

                <div className="switchboard__board">
                    <div className="switchboard__board-header">
                        <div className="switchboard__poles-info">
                            <div className="switchboard__poles-configured-text">
                                <span className="i-switchboard"></span> { this.props.configured } Poles Configured
                            </div>
                            <div className="switchboard__poles-remaining-text">
                                { this.props.remaining } Remaining
                            </div>
                        </div>

                        <div className={'switchboard__power-loss power-loss' + (this.props.powerLossCheckSuccess ? '' : ' is-error')}>
                            <div className="power-loss__text">
                                <span className="power-loss-icon i-lightning-bolt"></span>
                                <span>{ this.props.powerLossPercentage.toFixed(2) }% Component Thermal Losses</span> 
                            </div>
                            <div className="power-loss__meter">
                                <span className="power-loss__meter-amount" style={{width: this.props.powerLossPercentage + '%'}}></span>
                            </div>
                        </div>
                    </div>

                    <div className="switchboard__breaker-container">
                        {/* <!-- Add class to show available slots : 'show-available-slots' ? might be a better way to do this --> */}
                        <div className="switchboard__grid">
                            { this.renderRows() }
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
