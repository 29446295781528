import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { RegisterSide } from '../../components/registerSide/RegisterSide'
import { apiGetLoginSSO } from '../../utils/apiCalls'
import analyticsDataLayer from '../../services/AnalyticsDataLayer'
import { userTypes } from '../../data/userTypes'
export class Home extends Component {
    constructor(props) {
        super(props)

        this.state = {
            ssoLoginURL: '',
            pageError: ''
        }
    }

    componentDidMount() {
        const urlParams = new URLSearchParams(window.location.search)
        const error = urlParams.get('error')
        
        if (error) {
            this.setState({
                pageError: error
            })

            // Remove the error from the URL
            window.history.replaceState({}, document.title, window.location.pathname)
        }

        apiGetLoginSSO()
            .then((url) => {
                this.setState({
                    ssoLoginURL: url
                })
            })
    }

    onLoginButtonClick = (event) => {
        // Keep values the same as existing solution where cta_link is hardcoded
        analyticsDataLayer.ctaButtonClick({
            cta_name: 'Login',
            cta_link: 'https://secureidentity.schneider-electric.com/identity/setup/secur/RemoteAccessAuthorizationPage.apexp',
            cta_location: 'Registration'
        })
    }

    startRegistrationEvent = (userType) => {
        // Only Electrician (ex contractor has 3 steps in the registration form)
        if (userType === 'Electrician') {
            analyticsDataLayer.registrationStep(1, 3)
        } else {
            analyticsDataLayer.registrationStep(1, 2)
        }
    }

    render() {
        return (
            <div className="app">

                <div className="app__body app__body--register">

                    <RegisterSide />

                    <div className="app__register-content">
                        <div className="register">
                        
                            <div className="register__header">
                                
                                <h1 className="h3 register__header-title">
                                    Register 
                                </h1>
                                <div className="login__cta">
                                    <span className="p p--sm">
                                        Already have a FlexSelect account?
                                    </span>
                                    <a className="button button--1" href={this.state.ssoLoginURL} onClick={(e) => this.onLoginButtonClick(e)}>
                                        <span className="button__text">Login</span>
                                    </a>
                                </div>
                                
                            </div>
                            <div className="register__body">
                                <div className="register__body-content">
                                    {
                                        this.state.pageError !== '' &&
                                        <p className="alert alert--error">
                                            <span className="alert__icon">
                                                <span className="i-exclamation"></span>
                                            </span>
                                            <span className="alert__text">
                                                {this.state.pageError}
                                            </span>
                                        </p>
                                    }

                                    <form className="form form--register-landing">

                                        <div className="form__group">
                                            <label htmlFor="">
                                                What best describes you?
                                            </label>
                                        </div>

                                        {/* <!-- If Contractor is selected below then registration will require a 3rd extra step  --> */}
                                        <div className="resgister__types">
                                            {userTypes.flatMap((userType, index) => {
                                                if (!userType.isEnable) return []
                                                return [(
                                                    <Link key={userType.type} className="button button--cta" to={`/register/?type=${userType.type}`} onClick={() => this.startRegistrationEvent(userType.type)}>
                                                        <span className="button__icon-cta">
                                                            <span className={userType.icon}></span>
                                                        </span>
                                                        <span className="button__text">
                                                            {userType.name}
                                                        </span>
                                                        <span className="button__icon">
                                                            <span className="i-directional-right"></span>
                                                        </span>
                                                    </Link>
                                                )]}
                                            )}
                                        </div>

                                    </form>
                                </div>
                            </div>
                                
                        </div>
                    </div>
                    
                </div>
            </div>
        )
    }
}
