import React, { Component } from 'react'
import jQuery from "jquery"

export default class GlobalOverlay extends Component {
    componentDidMount() {
        jQuery('body').addClass('overlay-is-open')
    }

    onOverlayClick = () => {
        if (this.props.dismissOnClick !== undefined && this.props.dismissOnClick) {
            jQuery('body').removeClass('overlay-is-open')
        }
    }

    componentWillUnmount() {
        jQuery('body').removeClass('overlay-is-open')
    }

    render () {
        return (
            <div id="global-overlay" onClick={ this.onOverlayClick }>{ this.props.children }</div>
        )
    }
}