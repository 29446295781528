import React from 'react'
import BoardTile from './BoardTile'
import { isNullOrUndefined } from '../../../utils/helpers'

export default class BoardList extends React.Component {
    constructor(props) { 
        super(props)
    }

    render() {

        return (
            <div className="board-list">
                { this.props.boards.map(item => {
                    let drawingG = null;
                    if (this.props.isFinalised && !this.props.loadingDrawings) {
                        const drawingObj = this.props.boardDrawings.find(drawItem => drawItem.ProjectBoardID === item.ItemID);
                        if (drawingObj) {
                            drawingG = drawingObj.ItemGUID;
                        }
                    }
                    // Create the list of breaker details
                    let boardDescription = [];
                    if (!item.hasOwnProperty('BoardBOMComponents') || !item.BoardBOMComponents.length) {
                        item.ProjectBoardBreakerCollection.forEach(item => {
                            const desc = item.BreakerTypeReadOnly.DrawingDesc;
                            const inArray = boardDescription.find(item => item.Description === desc);
                            if (inArray) {
                                boardDescription = boardDescription.map(item => {
                                    if (item.Description === desc) {
                                        item.Quantity = item.Quantity + 1;
                                    }
                                    return item;
                                });
                            } else {
                                boardDescription.push({
                                    Quantity: 1,
                                    Description: desc,
                                    StockAvailability: 'N/A'
                                });
                            }
                        });
                    } else {
                        boardDescription = [...item.BoardBOMComponents];
                    }

                    return (
                        <BoardTile
                            key={item.ItemID}
                            id={item.ItemID}
                            name={item.Name}
                            chassis1percent={item.Chassis1PercentComplete}
                            chassis2percent={(item.BoardTypeReadOnly && item.BoardTypeReadOnly.ChassisCount) === 2 ? item.Chassis2PercentComplete : null}
                            boardBreakers={boardDescription}
                            drawingGuid={drawingG}
                            loadingDrawings={this.props.loadingDrawings}
                            editCallback={this.props.editCallback}
                            copyCallback={this.props.copyCallback}
                            configureChassisCallback={this.props.configureChassisCallback}
                            deleteCallback={this.props.deleteCallback}
                            showBoardDetailsCallback={this.props.showBoardDetailsCallback}
                            maxBoardLimitReached={this.props.maxBoardLimitReached}
                            isFinalised={this.props.isFinalised}
                            isValid={!isNullOrUndefined(item.BoardTypeReadOnly)}
                            fillQuoteRequirementError={this.props.fillQuoteRequirementError}
                            projectId={this.props.projectId} />
                    );
                })}
            </div>
        );
    }
}
