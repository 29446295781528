import React from 'react'
import { isNullOrUndefined } from './../../utils/helpers'

export default class RadioObjectGroup extends React.Component {
    static defaultProps = {
        enabled: true,
        displayGlobalWarnings: false
    }

    selectCall(value, item) {
        if (this.isEnabled(item) && this.props.selectedValue !== value) {
            this.props.selecteCallback(value)
        }
    }

    isEnabled(item) {
        if (this.props.enabled === true && this.props.enabledOptions) {
            if (this.props.enabledOptions.indexOf(item['value']) > -1) {
                return true
            } else {
                return false
            }
        } else {
            return this.props.enabled
        }
    }

    render() {
        let anyEnabledFields = false
        let anySelectedFields = false

        this.props.options.map((item) => {
            const isItemEnabled = this.isEnabled(item)
            const selected = !isNullOrUndefined(this.props.selectedValue) ? this.props.selectedValue === item['value'] : false

            if (isItemEnabled) anyEnabledFields = true
            if (selected) anySelectedFields = true
        })

        return (
            <div className={ 'form__group form__group--step' + 
                (this.props.layout ? ' ' + this.props.layout : '') +
                (anyEnabledFields ? ' is-active' : '') + 
                (anySelectedFields ? ' is-done' : '') +
                (this.props.boardClassName !== undefined ? ' ' + this.props.boardClassName : '')
            }>
                <h4 className="form-label-step">
                    <div className="form-label-step__icon">
                        <span className="i-check"></span>
                    </div>
                    <span className="form-label-step__text">
                        { this.props.title }
                    </span>
                </h4>

                {this.props.options.map((item, index) => {
                    const isItemEnabled = this.isEnabled(item)
                    const selected = !isNullOrUndefined(this.props.selectedValue) ? this.props.selectedValue === item['value'] : false
                    const selectorFriendlyName = this.props.title.split(' ').join('-').toLowerCase() 

                    return (
                        <React.Fragment key={item['value']}>

                            <div className={ `form__control radio radio--button ${ selected ? 'is-selected' : '' } ${ selected || isItemEnabled ? '' : 'is-disabled'}`} >
                                <input id={ selectorFriendlyName + '-' + index} type="radio" name={ this.props.title } className="form__control-input"
                                    checked={ selected } onChange={this.selectCall.bind(this, item['value'], item)}
                                />
                                <label htmlFor={ selectorFriendlyName + '-' + index} className="form__control-label button button--3">
                                    { item['label'] }
                                    { item['warning'] ? '*' : ''}
                                    { item['hasConditionalGlobalWarning'] && this.props.displayGlobalWarnings ? '*' : ''}
                                </label>
                            </div>

                            {(item['warning']) ? (
                                <div className="radio-object-warning">{ item['warning'] }</div>
                            ) : ''}
                        </React.Fragment>
                    )
                })}
            </div>
        )
    }
}
