import React, { Component } from 'react'
import { withRouter, Link } from 'react-router-dom'
import HeaderLogo from '../header-logo/HeaderLogo'
import HeaderBrandCommercial from '../header-brand-commercial/HeaderBrandCommercial'
import HeaderBrandResidential from '../header-brand-residential/HeaderBrandResidential'
import { apiGetLogoutSSO } from '../../utils/apiCalls'

const { CONST } = require('./../../CONST')

class HeaderProjectDashboard extends Component {
    logout = (event) => {
        event.preventDefault()
        apiGetLogoutSSO()
            .then((logoutURL) => {
                window.localStorage.removeItem('token')
                window.location.href = logoutURL
            })
            .catch(() => {
                window.alert('Failed to logout')
            })
    }

    render() {
        return (
            <div className="app__header">
                <header className="header">
                    <div className="header__section hs_minimal">
                        <div className="header-logo-container">
                            <HeaderLogo />
                            <span className="divider"></span>
                            {this.props.isCommercial ? <HeaderBrandCommercial/> : <HeaderBrandResidential/> }
                        </div>
                        
                        <div className="header__actions">
                            <Link className="button button--header-icon" to="/">
                                <span className="button__icon">
                                    <span className="i-home"></span>
                                </span>
                                <span className="button__text">
                                    My Projects
                                </span>
                            </Link>
                            <div className="header__dropdown">
                                <button className="button button--header-icon">
                                    <span className="button__icon">
                                        <span className="i-user-account"></span>
                                    </span>
                                    <span className="button__text">
                                        Account
                                    </span>
                                    <span className="i-arrow-down"></span>
                                </button>
                                <div className="header__dropdown-list">
                                    <li>
                                        <Link className="button button--header-icon" to="/user-profile">My Account</Link>
                                    </li>
                                    <li>
                                        <a href="#" className="button button--header-icon" onClick={(event) => this.logout(event)}>Sign out</a>
                                    </li>
                                </div>
                            </div>
                            <Link className="button button--header-icon" to={ CONST.PAGES.TECHNICAL_INFORMATION }>
                                <span className="button__icon">
                                    <span className="i-question"></span>
                                </span>
                                <span className="button__text">
                                   Help
                                </span>
                            </Link>
                        </div>
                    </div>

                    { this.props.children }
                    
                </header>
            </div>
        )
    }
}

export default withRouter(HeaderProjectDashboard)