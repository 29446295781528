import React from 'react'
import ReactDOM from 'react-dom'
import Pikaday from 'pikaday'

class Datepicker extends React.Component {
    componentDidMount() {
        // set min date to pick 4 days from now
        // http://stackoverflow.com/questions/563406/add-days-to-datetime
        Date.prototype.addDays = function(){
            const date = new Date(this.valueOf())
            const numDays = 4
            date.setDate(date.getDate() + numDays)

            // avoid weekends
            switch (date.getDay()) {
                case 0: // sunday
                    date.setDate(date.getDate() + 1)
                    break
                case 6: // saturday
                    date.setDate(date.getDate() + 2)
                    break
            }

            return date
        }

        const today = new Date()
        const minDate = today.addDays()
        const self = this

        new Pikaday({
            field: ReactDOM.findDOMNode(self.refs.pikaday),
            format: 'DD/MM/YYYY',
            firstDay: 1,
            minDate: minDate,
            yearRange: [2020, 2060],
            disableWeekends: true,
            onSelect: function() {
                self.props.handleDate(this.toString('DD/MM/YYYY'))
            }
        })
    }

    render() {
        return (
            <div className="form__group">
                <label className="form__label p--sm form__label-inline-block" htmlFor={`field-${this.props.datepickerID}`}>
                    {this.props.labelText}
                </label>
                <br />

                <input ref="pikaday" id={`field-${this.props.datepickerID}`} type="text" className="form__input input react-pikaday" placeholder={ this.props.placeholderText } defaultValue={this.props.defaultDate} />
            </div>
        )
    }
}

export default Datepicker
