import React, { Component } from 'react'

export class SvgChevronLeft extends Component {
  render() {
    return (
        <svg {...this.props} width="16" height="16" viewBox="7.5 4.5 9 15" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M16.5 6.019L14.986 4.5 7.5 12l7.486 7.5 1.514-1.52L10.533 12z" fill="#3DCD58"></path>
        </svg>
    );
  }
}


