import React from 'react'
import cloneDeep from 'lodash.clonedeep'
import RadioObjectGroup from '../../radio-object-group/RadioObjectGroup'

export default class BreakerDetailsFormContainerResidential extends React.Component {
    constructor(props) {
        super(props)

        // Internal states that no one else needs to know about, holds info on the selection options
        this.initStates(this.props)
    }

    componentWillReceiveProps(nextProps) {
        if (!this.props.breaker && !nextProps.breaker) {
            return
        }

        if (!this.props.breaker || !nextProps.breaker || this.props.breaker.ItemID !== nextProps.breaker.ItemID) {
            this.initStates(nextProps, true)
        }
    }

    initStates(propsData, useSetState = false) {
        // const existingBreaker = propsData.breaker ? propsData.breakerOptionData.find(item => item.ItemID === propsData.breaker.BreakerTypeItemID) : null
        const updatedState = {
            breakerDetails: {
                TypeOfDevice: null,
                NumberOfPoles: null,
                CurrentRating: null
            },
            validationMessage: '',
            residentialAllPartsAvailable: propsData.residentialAllPartsAvailable,
        }
        if (useSetState) {
            this.setState(updatedState)
        } else {
            this.state = updatedState
        }
    }

    createCall() {
        const finalSelections = cloneDeep(this.state.breakerDetails)
        const selectedBreaker = this.props.breakerOptionData.find(breaker => {
            return breaker.TypeOfDevice === finalSelections.TypeOfDevice &&
                breaker.NumberOfPoles === finalSelections.NumberOfPoles &&
                breaker.CurrentRating === finalSelections.CurrentRating
        })

        if (selectedBreaker === undefined) {
            this.setState({ validationMessage: 'There was an error finding a breaker, please contact the administrator of the website.' })
            return
        }

        this.props.createCallback(selectedBreaker.ItemID)
    }

    // Update the selected option value in local state
    optionSelectedCall(value, key) {
        const updatebreaker = cloneDeep(this.state.breakerDetails)
        updatebreaker[key] = value

        if (key === 'PoleType') updatebreaker['CurrentRating'] = null
        if (key === 'TypeOfDevice') {
            updatebreaker['CurrentRating'] = null
            updatebreaker['NumberOfPoles'] = null

            // Some devices have only one option for NumberOfPoles, in that case we select it by default
            const availableValues = this.getNumberOfPolesEnabledOptions(value)
            if (availableValues.length === 1) {
                updatebreaker['NumberOfPoles'] = availableValues[0]
            }
        }

        this.setState({ breakerDetails: updatebreaker }, () => {
            if (this.state.breakerDetails.CurrentRating !== null) {
                const finalSelections = cloneDeep(this.state.breakerDetails)
                const selectedBreaker = this.props.breakerOptionData.find(breaker => {
                    return breaker.TypeOfDevice === finalSelections.TypeOfDevice &&
                        breaker.NumberOfPoles === finalSelections.NumberOfPoles &&
                        breaker.CurrentRating === finalSelections.CurrentRating
                })

                const availabilityDataForSelectedBreaker = this.state.residentialAllPartsAvailable.find(item => item.ItemCode === selectedBreaker.Reference)
                if (!availabilityDataForSelectedBreaker.IsInStock) {
                    this.setState({
                        validationMessage: `${selectedBreaker.Description} is out of stock. Please, contact <a href="mailto:au.estimating@se.com">au.estimating@se.com</a>`,
                        isOutOfStock: true
                    })
                } else if (availabilityDataForSelectedBreaker.IsLowStock) {
                    this.setState({
                        validationMessage: 'Low stock, longer lead times may apply',
                        isOutOfStock: false
                    })
                } else {
                    this.setState({ validationMessage: '', isOutOfStock: false })
                }
            }
        })
    }

    getTypeOfDeviceEnabledOptions() {
        // this.props.incomerPhase // Single Phase, 2 Phase, 3 Phase
        let availableValues = []

        // TODO: this is based on the data provided from a spread sheet and how we store it in the DB, it works, but will be good to make it
        // better in the database, so we can perform more secure, not string based, checks
        // NOTE: this is not DRY, but very easy to read with very good debug feedback, it's prefered to leave it like this to now make a future 
        // developer loose time trying to understand the logic, but feel free if you think it can ba slightly writte in a more elegant way
        console.warn('Explanation of why type of the breakers where enabled')
        this.props.breakerOptionData.forEach(breakerOption => {
            if (this.props.incomerPhase === 'Single Phase' && breakerOption.IncomerPhase === 'Single, 2 or 3 phase') {
                if (availableValues.indexOf(breakerOption.TypeOfDevice) === -1) {
                    availableValues.push(breakerOption.TypeOfDevice)
                    console.log('The incomer phase of the board is "Single Phase", enabling breaker type: "' + breakerOption.TypeOfDevice + '", because its IncomerPhase is: "' + breakerOption.IncomerPhase + '" and skipping further checks if that type of device should be enabled. If this breaker type should not be enabled, then please review the data coming from the spreadsheet provided and database values imported from that spreadsheet.')
                    console.log(breakerOption)
                }
            }

            if (this.props.incomerPhase === '2 Phase' && breakerOption.IncomerPhase.indexOf('2 or 3 phase') > -1) {
                if (availableValues.indexOf(breakerOption.TypeOfDevice) === -1) {
                    availableValues.push(breakerOption.TypeOfDevice)
                    console.log('The incomer phase of the board is "2 Phase", enabling breaker type: "' + breakerOption.TypeOfDevice + '", because its IncomerPhase is: "' + breakerOption.IncomerPhase + '" and skipping further checks if that type of device should be enabled. If this breaker type should not be enabled, then please review the data coming from the spreadsheet provided and database values imported from that spreadsheet.')
                    console.log(breakerOption)
                }
            }

            if (this.props.incomerPhase === '3 Phase' && (breakerOption.IncomerPhase.indexOf('2 or 3 phase') > -1 || breakerOption.IncomerPhase === '3 phase')) {
                if (availableValues.indexOf(breakerOption.TypeOfDevice) === -1) {
                    availableValues.push(breakerOption.TypeOfDevice)
                    console.log('The incomer phase of the board is "3 Phase", enabling breaker type: "' + breakerOption.TypeOfDevice + '", because its IncomerPhase is: "' + breakerOption.IncomerPhase + '" and skipping further checks if that type of device should be enabled. If this breaker type should not be enabled, then please review the data coming from the spreadsheet provided and database values imported from that spreadsheet.')
                    console.log(breakerOption)
                }
            }
        })

        return availableValues
    }

    getNumberOfPolesEnabledOptions(typeOfDevice) {
        let availableValues = []

        this.props.breakerOptionData.forEach(breakerOption => {
            if (breakerOption.TypeOfDevice === typeOfDevice) {
                if (availableValues.indexOf(breakerOption.NumberOfPoles) === -1) {
                    availableValues.push(breakerOption.NumberOfPoles)
                }
            }
        })

        return availableValues
    }

    getCurrentRatingEnabledOptions() {
        let availableValues = []

        this.props.breakerOptionData.forEach(breakerOption => {
            if (breakerOption.TypeOfDevice === this.state.breakerDetails.TypeOfDevice &&
                breakerOption.NumberOfPoles === this.state.breakerDetails.NumberOfPoles) {
                if (availableValues.indexOf(breakerOption.CurrentRating) === -1) {
                    availableValues.push(breakerOption.CurrentRating)
                }
            }
        })

        return availableValues
    }

    render() {
        const breakerOptions = this.props.breakerOptions
        const breakerDetails = this.state.breakerDetails

        return (
            <div className="breaker-details-form-container slide-out-form-container">
                <div className="form-wrapper">
                    <div className="row">
                        <div className="col-6">
                            <RadioObjectGroup
                                title="Type of Device"
                                options={breakerOptions.TypeOfDevice}
                                selectedValue={breakerDetails.TypeOfDevice}
                                selecteCallback={value => this.optionSelectedCall(value, 'TypeOfDevice')}
                                layout={"layout-50 radio-button-row"}
                                enabledOptions={this.getTypeOfDeviceEnabledOptions()}
                            />
                        </div>

                        <div className="col-6">
                            <RadioObjectGroup
                                title="Pole Type"
                                enabled={breakerDetails.TypeOfDevice !== null}
                                options={breakerOptions.NumberOfPoles}
                                selectedValue={breakerDetails.NumberOfPoles}
                                selecteCallback={value => this.optionSelectedCall(value, 'NumberOfPoles')}
                                layout={"layout-auto radio-button-row"}
                                enabledOptions={this.getNumberOfPolesEnabledOptions(this.state.breakerDetails.TypeOfDevice)}
                            />
                        </div>
                    </div>
                    <hr />
                    <div className="row">
                        <div className="col-12">
                            <RadioObjectGroup
                                title="Current Rating"
                                enabled={breakerDetails.NumberOfPoles !== null}
                                options={breakerOptions.CurrentRating}
                                selectedValue={breakerDetails.CurrentRating}
                                selecteCallback={value => this.optionSelectedCall(value, 'CurrentRating')}
                                layout={"radio-button-row"}
                                enabledOptions={this.getCurrentRatingEnabledOptions()}
                            />
                        </div>
                    </div>
                </div>

                {
                    this.state.validationMessage &&
                    <div className="modal__validation-msg">
                        <span className="alert__icon">
                            <span className="i-exclamation"></span>
                        </span>
                        <span dangerouslySetInnerHTML={{ __html: this.state.validationMessage }}></span>
                    </div>
                }

                <div className="modal__footer">
                    {/* If the user specified Current Rating which is the very last input to choose, then enable Save/Edit button */}
                    <button className="button button--1" onClick={() => { this.createCall() }} disabled={this.state.breakerDetails.CurrentRating === null || this.state.isOutOfStock}>
                        <span className="button__text">
                            {!this.props.breaker && <span>Add Breaker</span>}
                            {this.props.breaker && <span>Edit Breaker</span>}
                        </span>
                    </button>
                </div>
            </div>
        )
    }
}