import React from 'react'
import cloneDeep from 'lodash.clonedeep'
import { toggleArrayItem } from '../../../utils/helpers'

export default class ChassisOptionsForm extends React.Component {
    constructor(props) {
        super(props)
        // Bind this to element function calls for better performance / practise
        // - ref: https://github.com/yannickcr/eslint-plugin-react/blob/master/docs/rules/jsx-no-bind.md
        this.updateCall = this.updateCall.bind(this)

        // Internal states that no one else needs to know about
        // holds info on the selection options
        this.initStates(this.props)
    }

    initStates(propsData) {
        this.state = {
            selectedOptions: cloneDeep(propsData.selected),
        }
        this.props.selecteCallback(cloneDeep(propsData.selected))
    }

    updateCall() {
        // Calls create / update callback based on is new breaker flag
        this.props.updateCallback(this.state.selectedOptions)
    }

    // Update the selected option value in local state
    toggleSelectedValue(value, isDisabled) {
        if (isDisabled) { return }

        var newSelectedOptions = toggleArrayItem(this.state.selectedOptions, value)
        this.setState({
            selectedOptions: newSelectedOptions
        }, () => {
            this.props.selecteCallback(this.state.selectedOptions)
        })
    }

    // Renders the loader / update breaker / add breaker button based on props passed in
    renderControl() {
        if (this.props.isLoading) {
            return (
                <div className="form-controls">
                    <span>Loading...</span>
                </div>
            )
        } else {
            return (
                <div className="form-controls">
                    <div className="btn btn-skin-1" onClick={this.updateCall}>Add to board</div>
                </div>
            )
        }
    }

    isValueSelected(value) {
        if (this.isOptionDisabled(value)) { return }

        return this.state.selectedOptions && this.state.selectedOptions.includes(value)
    }

    isOptionDisabled(value) {
        if (!this.props.isPole2Filled){ return false }

        // If pole 2 is filled, then we disable option 2 and 3
        return (value === 2 || value === 3)
    }

    render() {
        return (
            <div>
                <p className="p p--dark">Select extras</p>

                <div className="row row--sm-gutter">
                {this.props.options.map(opt => {
                    const isSelected = this.isValueSelected(opt.ItemID)
                    const isDisabled = this.isOptionDisabled(opt.ItemID)

                    return (
                        <div key={ opt.ItemID } className={`col-6 board-extras-col col--sm-gutter ${isSelected ? 'selected' : ''} ${isDisabled ? 'disabled' : ''}`}>
                            <div className="form__control checkbox checkbox--alt"> 
                                <input 
                                    id={ 'checkbox-extra-' + opt.ItemID }
                                    type="checkbox" 
                                    name={ 'checkbox-extra-' + opt.ItemID } 
                                    className="form__control-input" 
                                    onChange={ this.toggleSelectedValue.bind(this, opt.ItemID, isDisabled) } 
                                    checked={ isSelected } 
                                />
 
                                <label htmlFor={ 'checkbox-extra-' + opt.ItemID } className="form__control-label">
                                    <span className="p text-bold">{ opt.DisplayTitle }</span>
                                    <p className="p p--xs">{ opt.DisplayDescription }</p>
                                    { isDisabled &&
                                        <span className="checkbox-extra__alert">
                                            This option is only available when pole slot 2 is empty
                                        </span>
                                    }
                                </label>
                            </div>
                        </div>
                    )
                })}
                </div> 
            </div>
        )
    }
}
