import jQuery from "jquery"

class CommonFunctionsService {
    scrollToTop = () => {
        document.getElementsByClassName('body')[0].scrollTop = 0
    }

    closeModals = () => {
        jQuery('body').removeClass('modal-is-open')
    }
}

const commonFunctionsService = new CommonFunctionsService()
export default commonFunctionsService
  