import React from 'react'
import Axios from 'axios'
import {
    withRouter,
    Switch,
    Route
} from 'react-router-dom'
import Projects from './pages/projects/Projects'
import ProjectDetails from './pages/project-details/commercial/ProjectDetails'
import ProjectDetailsResidential from './pages/project-details/residential/ProjectDetailsResidential'
import Quote from './pages/quote/Quote'
import QuoteResidential from './pages/quote/QuoteResidential'
import { TechnicalInformation } from './pages/technical-information/TechnicalInformation'
import { Home } from './pages/home/Home'
import { Registration } from './pages/registration/Registration'
import ConfigChassis from './pages/config-chassis/ConfigChassis'
import ConfigChassisResidential from './pages/config-chassis/ConfigChassisResidential'
import { apiPostLoginSSOCallback } from './utils/apiCalls'
import { apiCallsCommercial } from './utils/apiCallsCommercial'
import analyticsDataLayer from './services/AnalyticsDataLayer'
import { apiCallsResidential } from './utils/apiCallsResidential'
import { UserProfile } from './pages/user-profile/UserProfile'
const { CONST } = require('./CONST')
const userType = {
    0: 'Large Contractor',
    1: 'Switchboard Manufacturer',
    2: 'Wholesaler',
    3: 'Contractor', //UserIsContractor
    4: 'Consultant', //IsConsultant
}
class App extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            isAuthenticating: true,
            userSettings: {}
        }
    }

    componentDidMount() {
        const urlParams = new URLSearchParams(window.location.search)
        const code = urlParams.get('code')
        const tokenParam = urlParams.get('token')
        const token = window.localStorage.getItem('token')

        if (code) {
            apiPostLoginSSOCallback(code)
                .then(response => {
                    if (response.token) {
                        window.localStorage.setItem('token', response.token)
                        this.setAxiosAuthorizationHeader(response.token)
                        // Remove the token from the URL
                        window.history.replaceState({}, document.title, window.location.pathname)
                        analyticsDataLayer.login()

                        this.initialize()
                    } else {
                        window.alert('Failed to get token from the server')
                    }
                })
                .catch((error) => {
                    // Redirect the user to the home page with the error message in the params
                    // Check that the message is a string, not an object
                    if (typeof error === 'object') {
                        error = JSON.stringify(error)
                    }

                    window.location.href = CONST.PAGES.HOME + `?error=${error}`
                })
        } else if (tokenParam) {
            window.localStorage.setItem('token', tokenParam)
            this.setAxiosAuthorizationHeader(tokenParam)
            this.initialize()
        } else if (token) {
            this.setAxiosAuthorizationHeader(token)
            this.initialize()
        } else {
            // Redirect to the login page if not already on the login page
            if (window.location.pathname.includes(CONST.PAGES.HOME)) {
                this.setState({ isAuthenticating: false })
            } else {
                this.props.history.push(CONST.PAGES.HOME)
                this.setState({ isAuthenticating: false })
            }
        }
    }

    // Set axios authorization header
    setAxiosAuthorizationHeader(token) {
        if (token) {
            Axios.defaults.headers.common['Authorization'] = `Bearer ${token}`
        } else {
            delete window.axios.defaults.headers.common['Authorization']
        }
    }

    initialize() {
        apiCallsResidential.apiGetAllParts().then((response) => {
            console.log('All parts residential:', response)
        })

        apiCallsCommercial.apiUserSettings()
            .then((userDataObj) => {
                // For the Medallia integration CLIP-606, we need to expose some of the global variables
                if (userDataObj !== null) {
                    window.surveyFederatedId = userDataObj.UserGuid
                    window.surveyLastName = userDataObj.UserLastName
                    window.surveyFirstName = userDataObj.UserFirstName
                    window.surveyEmailId = userDataObj.UserEmail
                    window.surveyPhoneNumber = userDataObj.UserPhone
                }

                this.setState({
                    userSettings: userDataObj,
                    isAuthenticating: false
                })
                this.addHomePageDataLayer(userDataObj, this.props.history.location)
                this.props.history.push('/')
            })
            .catch(() => {
                // Failed to get user setting, then take the user back to the login page
                if (window.location.href.indexOf('localhost') > -1) {
                    window.alert('You are recognized as working in the local environment, but not authorized to call APIs, please check the documentation.')
                } else {
                    this.props.history.push(CONST.PAGES.HOME)
                }
            })


        this.props.history.listen((location) => {
            let path = ''
            if (location?.pathname === '/') {
                path = 'home'
            } else {
                path = location?.pathname?.replace(/\//g, '')
            }

            let queryString = location?.search || ''
            let params = new URLSearchParams(queryString)

            let projectId = params.get('orderID') || params.get('projectID') || ''
            let boardID = params.get('boardid') || params.get('boardID') || ''

            analyticsDataLayer.pageView({
                'digital_platform': 'FlexSelect',
                'digital_platform_country': 'au',
                'environment': process.env.REACT_APP_ENVIRONMENT,
                'business_unit': '',
                'page_category': '/flex',
                'page_language': 'en',
                'page_sub_category': `${projectId}` || '',
                'page_sub_sub_category': `${boardID}` || '',
                'page_top_category': this.getTopCategory(location) || '',
                'user_persona': userType?.[this.state?.userSettings?.UserType] || '',
                'template': path
            })
        })
    }

    addHomePageDataLayer(userDataObj, location) {
        let path = ''
        if (location?.pathname === '/') {
            path = 'home'
        } else {
            path = location?.pathname?.replace(/\//g, '')
        }

        analyticsDataLayer.pageView({
            'digital_platform': 'FlexSelect',
            'digital_platform_country': 'au',
            'environment': process.env.REACT_APP_ENVIRONMENT,
            'business_unit': '',
            'page_category': '/flex',
            'page_language': 'en',
            'page_sub_category': '',
            'page_sub_sub_category': '',
            'page_top_category': this.getTopCategory(location),
            'user_persona': userType?.[userDataObj?.UserType] || '',
            'template': path
        })
    }

    getTopCategory(location) {
        let name = location?.pathname + (location?.search ? location.search : '') || ''
        return name
    }

    render() {
        if (this.state.isAuthenticating) {
            // We can improve this and put some global loader
            return ''
        } else {
            return (
                <Switch>
                    <Route path={CONST.PAGES.TECHNICAL_INFORMATION}>
                        <TechnicalInformation />
                    </Route>
                    <Route path={CONST.PAGES.PROJECT_DETAILS}>
                        <ProjectDetails />
                    </Route>
                    <Route path={CONST.PAGES.PROJECT_DETAILS_RESIDENTIAL}>
                        <ProjectDetailsResidential />
                    </Route>
                    <Route path={CONST.PAGES.CONFIGURE_CHASSIS}>
                        <ConfigChassis isCommercial={true} />
                    </Route>
                    <Route path={CONST.PAGES.CONFIGURE_CHASSIS_RESIDENTIAL}>
                        <ConfigChassisResidential isCommercial={false} />
                    </Route>
                    <Route path={CONST.PAGES.QUOTE}>
                        <Quote />
                    </Route>
                    <Route path={CONST.PAGES.QUOTE_RESIDENTIAL}>
                        <QuoteResidential />
                    </Route>
                    <Route path={CONST.PAGES.REGISTER}>
                        <Registration />
                    </Route>
                    <Route path={CONST.PAGES.HOME}>
                        <Home />
                    </Route>
                    <Route path={CONST.PAGES.PROFILE}>
                        <UserProfile />
                    </Route>
                    <Route path="/">
                        <Projects />
                    </Route>
                </Switch>
            )
        }
    }
}

export default withRouter(App)
