import { DragSource } from 'react-dnd'
import React from 'react'

export class FittedOption extends React.Component {
    constructor(props) {
        super(props)

        var opacity = this.props.isDragging ? 0.4 : 1

        this.state = {
            isDragging: opacity
        }
    }

    handleDeletingFittedOption (itemID) {
        this.props.handleDeletingFittedOption(itemID)
    }

    handleFittedOptionAutoAdd (itemID) {
        this.props.handleFittedOptionAutoAdd(itemID, this.props.quantity)
    }

    render() {
        const opacity = this.props.isDragging ? 0.4 : 1

        return (
        <div className={ 'breaker' + (this.props.isDisabled ? ' is-disabled' : '')} ref={ this.props.connectDragSource } style={{ opacity }}>
            <span className="breaker__number">{ this.props.usedCount }</span>
            <span className={ 'breaker__type breaker__type--' + this.props.poleCount }>
                <span className="pole-ui"></span>
                <span className="pole-ui"></span>
                <span className="pole-ui"></span>
                <span className="pole-ui"></span>
                <span className="pole-ui"></span>
                <span className="pole-ui"></span>
                <span className="pole-ui"></span>
                <span className="pole-ui"></span>
            </span>
            <span className="breaker__title">
                { this.props.description }

                {
                    this.props.isDisabled &&
                    <div className="breaker__subtitle">Cannot be fitted on to this board</div>
                }
            </span>
            <div className="breaker__actions">
                <button className="button--icon" onClick={ () => { this.handleDeletingFittedOption(this.props.ItemID) } }>
                    <span className="button__icon">
                        <span className="i-trash-bin"></span>
                    </span>
                </button>

                {!this.props.isDragAndDropEnabled && !this.props.isDisabled &&
                    <div className='breaker__quantity'>
                        <input className="input" type="number" value={ this.props.quantity } onChange={(e) => this.props.handleFittedOptionUpdateQuantity(e.target.value, this.props.ItemID)}  />
                    </div>
                }

                { !this.props.isDisabled && 
                    <button className="button button--outline button-auto-placement"  disabled={this.props.quantity < 1} onClick={ () => { this.handleFittedOptionAutoAdd(this.props.ItemID) } }>
                        <span className="button__icon">
                            <span className="i-plus"></span>
                        </span>
                    </button>
                }
            </div>
        </div>
        )
    }
}

export default DragSource('ResidentialChassisPole', 
    {
        beginDrag: (props) => {
            return { 
                ItemID: props.ItemID,
                FittedOptionItemID: props.FittedOptionItemID,
                poleCount: props.poleCount
            }
        },
        endDrag(props, monitor) {
            const item = monitor.getItem()
            const dropResult = monitor.getDropResult() 

            if (dropResult !== null && (props.isAllowedToDragNDropPole === true || props.isAllowedToDragNDropPole === undefined)) {
                props.addFittedOptionToBoard(dropResult.rowIndex, dropResult.poleIndex, item.ItemID, item.FittedOptionItemID)
            }
        },
        canDrag(props) {
            return props.isDragAndDropEnabled
        }
    }, 
    (connect, monitor) => {
        return {
            connectDragSource: connect.dragSource(),
            isDragging: monitor.isDragging()
        }
    }
)(FittedOption)
