import React, { Component } from 'react'
import { SvgFlexSelectLogo } from '../svg/SvgFlexSelectLogo'
import { SvgLifeIsOnSchneiderElectric } from '../svg/SvgLifeIsOnSchneiderElectric'
import { SvgChevronLeft } from '../svg/SvgChevronLeft'
import { SvgChevronRight } from '../svg/SvgChevronRight'
import analyticsDataLayer from '../../services/AnalyticsDataLayer'

export class RegisterSide extends Component {
    constructor(props) {
        super(props)

        this.state = {
            totalSlides: 3,
            currentSlide: 0
        }
    }

    findOutMore = () => {
        analyticsDataLayer.ctaButtonClick({
            cta_name: 'Find out more',
            cta_link: '',
            cta_location: 'Registration'
        })

        this.handleNextSlide()
    }

    handleNextSlide = () => {
        this.setState(prevState => ({
            currentSlide: prevState.currentSlide + 1
        }))
    }

    handlePrevSlide = () => {
        this.setState(prevState => ({
            currentSlide: prevState.currentSlide - 1
        }))
    }

    render() {
        return (
            <div className="app__register-side">
                <button
                    type="button"
                    className="carousel-back"
                    hidden={[0].includes(this.state.currentSlide)}
                    onClick={this.handlePrevSlide}
                >
                    <SvgChevronLeft />
                </button>

                {this.state.currentSlide === 0 && (
                    <>
                        <div className="register__cta">
                            <div className="register__cta-logo">
                                <SvgFlexSelectLogo />
                            </div>

                            <p className="register__cta-text">
                                {this.state.slide} Streamline your projects with our digital tool. Effortlessly configure, price, and order custom distribution boards and load centres, anytime and anywhere.
                                <br />
                                <br />
                                Dispatched within 5 business days for most selections.
                            </p>
                            
                            <button 
                                href="https://www.se.com/au/en/product-range-presentation/63384-flex-select/" 
                                className="button button--outline"
                                onClick={this.findOutMore}
                            >
                                <span className="button__text">
                                    Find out more
                                </span>
                            </button>
                        </div>

                        <div className="register__se-logo">
                            <SvgLifeIsOnSchneiderElectric />
                        </div>
                    </>
                )}

                {this.state.currentSlide === 1 && (
                    <div className="register__cta in-carousel">
                        <div className="register__carousel">
                            <p>Start your FlexSelect Journey today</p>
                            <ul>
                                <li>Experience effortless configuration, quoting, and ordering</li>
                                <li>Faster pricing: get instant indicative pricing and order at the click of a button</li>
                                <li>Faster delivery: most selections produced in just 5 days</li>
                                <li>Speedy installation: cut labour costs with loaded boards</li>
                                <li>Minimise onsite packaging waste</li>
                            </ul>
                        </div>
                    </div>
                )}

                {this.state.currentSlide === 2 && (
                    <div className="register__cta in-carousel">
                        <div className="register__carousel">
                            <p>Commercial Highlights</p>
                            <ul>
                                <li>Create fully customisable distribution boards</li>
                                <li>Layout and As-built drawings</li>
                                <li>Range of metering options</li>
                                <li>Faster installations</li>
                            </ul>
                        </div>
                    </div>
                )}

                {this.state.currentSlide === 3 && (
                    <div className="register__cta in-carousel">
                        <div className="register__carousel">
                            <p>Residential Highlights</p>
                            <ul>
                                <li>Peace of mind: 7-year warranty for MAX9 devices and enclosures</li>
                                <li>Instant PDF layouts complete with bill of materials</li>
                                <li>Choose between 4.5kA and 6kA with options for ResiMAX or MAX9</li>
                                <li>Speedy installation: pre-loaded boards with connected busbars for efficiency</li>
                                <li>Project focused: only 30 boards required to process orders</li>
                            </ul>
                        </div>
                    </div>
                )}

                <button
                    type="button"
                    className="carousel-next"
                    style={{ visibility: [0,this.state.totalSlides].includes(this.state.currentSlide) ? 'hidden': '' }}
                    onClick={this.handleNextSlide}
                >
                    <SvgChevronRight />
                </button>
            </div>
        )
    }
}
