import React from 'react'
import { isNullOrUndefined, drawPercentProgressBars } from './../../../utils/helpers'
import { apiUrlGetDocument } from './../../../utils/apiCallsResidential'
import { debounce } from 'lodash'
import analyticsDataLayer from '../../../services/AnalyticsDataLayer'

export default class BoardTile extends React.Component {

    constructor(props) {
        super(props)
        // Bind this to element function calls for better performance / practise
        // - ref: https://github.com/yannickcr/eslint-plugin-react/blob/master/docs/rules/jsx-no-bind.md
        this.editCall = this.editCall.bind(this)
        this.showBoardDetails = this.showBoardDetails.bind(this)
        this.copyCall = this.copyCall.bind(this)
        this.deleteCall = this.deleteCall.bind(this)

        this.state = {
            loading: false,
            quantity: this.props.quantity
        }
    }

    editCall() {
        if (this.props.isValid) {
            this.props.editCallback(this.props.id)
        }
    }

    showBoardDetails() {
        this.props.showBoardDetailsCallback(this.props.boardBreakers)
    }

    copyCall() {
        if (!this.props.maxBoardLimitReached && this.props.isValid) {
            this.props.copyCallback(this.props.id)
        }
    }

    deleteCall() {
        this.props.deleteCallback(this.props.id)
    }

    componentDidMount() {
        window.setTimeout(() => {
            this.drawPercent()
        }, 1000)
    }

    configureBoard(projectID, boardID) {
        this.props.configureChassisCallback(projectID, boardID)
    }

    drawPercent() {
        drawPercentProgressBars()
    }

    changeQuantity(updatedQuantity) {
        /* 
            There is no specific limit for the amount of boards that can be ordered, but ordering more than 999 doesn't seem realistic for residential boards
            Let's put some limit to prevent user specifying ridiculously high numbers. Ordering mora than 999 residential boards would mean
            that someone is trying to buy boards for a reasonable big neighborhood, honestly in that case they would probably not use this online tool
            It's good to have a limit instead of solving bugs where user tries to order a milion of residential boards and QA reporting isses
            We also limit it to 999 so we don't break existing UI, where you can't see more than 3 digits.
            Anyway, future developer don't get frustrated I left you enough comments everywhere and this projects had really good documentation
            Have a great day and stay positive! 
        */
        const updatedQuantityAsNumber = parseInt(updatedQuantity, 10)
        if (updatedQuantityAsNumber > 0 && !this.props.isUpdatingBoardQuantity) {
            if (updatedQuantityAsNumber < 999) {
                if (!this.props.isUpdatingBoardQuantity) {
                    this.setState({ quantity: updatedQuantityAsNumber }, () => this.quantityServerUpdate())
                    this.props.updateBoardQuantityCallback(this.props.id, updatedQuantityAsNumber, false)
                }
            } else {
                this.setState({ quantity: 999 }, () => this.quantityServerUpdate())
                this.props.updateBoardQuantityCallback(this.props.id, 999, false)
            }
        }
    }

    decreaseQuantity() {
        if (this.state.quantity > 1) {
            if (!this.props.isUpdatingBoardQuantity) {
                this.setState({ quantity: this.state.quantity - 1 }, () => this.quantityServerUpdate())
                this.props.updateBoardQuantityCallback(this.props.id, this.state.quantity - 1, false)
            }
        }
    }

    increaseQuantity() {
        if (!this.props.isUpdatingBoardQuantity) {
            if (this.state.quantity < 999) {
                this.setState({ quantity: this.state.quantity + 1 })
                this.props.updateBoardQuantityCallback(this.props.id, this.state.quantity + 1, false)
                this.quantityServerUpdate()
            }
        }
    }

    quantityServerUpdate = debounce(() => {
        this.props.updateBoardQuantityCallback(this.props.id, this.state.quantity, true)
        console.log('Debounced quantity, should save new quantity: ' + this.state.quantity)
    }, 2000)

    renderPercent(title, value, index) {
        if (isNullOrUndefined(value)) {
            return ''
        } else {
            return (
                <div className="card__chassis">
                    <div className="donut-container" >
                        <div className="donut progress-bar-percent" data-index={index} data-value={value ? value : '0'}></div>
                        <p className="card__chassis-title p p--xs p--caps">
                            {title}
                        </p>
                    </div>
                </div>
            )
        }
    }

    renderProjectBoardModals = (boardDescription = []) => {
        return (
            <div className="table-wrapper board-description">
                <table>
                    <tbody>
                        <tr>
                            <th>Qty</th>
                            <th>Item</th>
                        </tr>
                        {boardDescription.map((boardDescription, index) => {
                            return (
                                <tr key={index}>
                                    <td>{boardDescription.Quantity}</td>
                                    <td> {boardDescription.Description}</td>
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
            </div>
        )
    }

    componentWillReceiveProps(nextProps) {
        if (this.props.precentComplete !== nextProps.precentComplete) {
            window.setTimeout(() => {
                this.drawPercent()
            }, 1000)
        }
    }

    render() {
        return (
            <div className="card-container">
                {/* Add .is-error to put it in an error state */}
                <div className="card">
                    <h4 className="card__title h4">
                        {!this.props.isValid && '[DATA CORRUPTED] - '} {this.props.name}
                    </h4>
                    <div className="card__chassis-container">
                        {this.renderPercent('Quantity', this.props.isValid ? this.props.precentComplete : null, 1)}
                    </div>

                    <div className="card__quantity quantity">
                        {
                            !this.props.isFinalised &&
                            <button type="button" className="button button--markup decrease" onClick={() => this.decreaseQuantity()}>
                                <span className={'button__icon' + (this.props.isUpdatingBoardQuantity ? ' disabled-quantity' : '')}>
                                    <span className="i-remove"></span>
                                </span>
                            </button>
                        }

                        <div className="quantity__amount">
                            <div className="quantity__amount-input">
                                <input value={this.state.quantity} onChange={(event) => { this.changeQuantity(event.target.value) }}
                                    className="form__input input" type="number" disabled={this.props.isUpdatingBoardQuantity} readOnly={this.props.isFinalised} />
                            </div>
                        </div>

                        {
                            !this.props.isFinalised &&
                            <button type="button" className="button button--markup increase" onClick={() => this.increaseQuantity()} >
                                <span className={'button__icon' + (this.props.isUpdatingBoardQuantity ? ' disabled-quantity' : '')}>
                                    <span className="i-plus"></span>
                                </span>
                            </button>
                        }
                    </div>

                    <div className="card__actions card__actions--hover">
                        {!this.props.isFinalised &&
                            <React.Fragment>
                                {
                                    !this.props.maxBoardLimitReached &&
                                    <button className="button button--clear duplicate" onClick={this.copyCall}>
                                        <span className="button__icon">
                                            <span className="i-duplicate"></span>
                                        </span>
                                        <span className="button__text">Copy Board</span>
                                    </button>
                                }
                                <button className="button button--clear delete" onClick={this.deleteCall}>
                                    <span className="button__icon">
                                        <span className="i-trash-bin"></span>
                                    </span>
                                    <span className="button__text">Delete</span>
                                </button>
                            </React.Fragment>
                        }
                    </div>
                    <div className="card__actions">
                        {!this.props.isFinalised &&
                            <React.Fragment>
                                <button className={`button button--3 ${this.props.isValid || 'btn-disabled'}`} onClick={this.editCall}>
                                    <span className="button__icon">
                                        <span className="i-edit"></span>
                                    </span>
                                    <span className="button__text">
                                        Edit Board
                                    </span>
                                </button>
                                <button className="button button--1" onClick={() => { this.configureBoard(this.props.projectId, this.props.id) }}>
                                    <span className="card__text">
                                        Configure Board
                                    </span>
                                </button>
                            </React.Fragment>
                        }

                        {this.props.isFinalised &&
                            <React.Fragment>
                                <button className="button button--3" onClick={this.showBoardDetails}>
                                    <span className="button__icon">
                                        <span className="i-edit"></span>
                                    </span>
                                    <span className="button__text">
                                        View Details
                                    </span>
                                </button>

                                {
                                    this.props.drawingGuid ?
                                        <a href={`${apiUrlGetDocument}/${this.props.drawingGuid}`} className="button button--2" >
                                            <span className="button__icon">
                                                <span className="i-download"></span>
                                            </span>
                                            <span className="button__text" onClick={() => {
                                                analyticsDataLayer.downloadLayout({
                                                    "document_id": this.props.drawingGuid,
                                                    "document_name": "Download Drawings",
                                                    "document_type": "pdf"
                                                })
                                            }}>
                                                View Drawings
                                            </span>
                                        </a>
                                        :
                                        <button className="button button--2 disabled">
                                            <span className="button__icon">
                                                <span className="i-download"></span>
                                            </span>
                                            <span className="button__text">
                                                {
                                                    this.props.loadingDrawings ? 'Loading' : 'No board drawing available'
                                                }
                                            </span>
                                        </button>
                                }
                            </React.Fragment>
                        }
                    </div>
                </div>

            </div>
        )

    }
}
