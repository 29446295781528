import React from 'react'
import { isOdd } from '../../../utils/helpers'

export default class ChassisBreaker extends React.Component {

    constructor(props) {
        super(props)
        // Bind this to element function calls for better performance / practise
        // - ref: https://github.com/yannickcr/eslint-plugin-react/blob/master/docs/rules/jsx-no-bind.md
        this.editClickCall = this.editClickCall.bind(this)
        this.deleteCall = this.deleteCall.bind(this)
        this.updateBrekerChassisNoteCall = this.updateBrekerChassisNoteCall.bind(this)

        // Internal states that no one else needs to know about holds info on the selection options
        this.state = {
            showEditOptions: false,
            isDeleting: false,
            BreakerCircuitLabelInput: this.props.BreakerCircuitLabelInput
        }
    }

    deleteCall() {
        this.setState({ isDeleting: true })
        this.props.deletedCallback(this.props.chassisPosIndex, this.props.itemId)
    }

    editClickCall() {
        this.setState({ showEditOptions: !this.state.showEditOptions })
    }

    updateBrekerChassisNoteCall() {
        this.props.updateBrekerChassisNote(this.props.chassisPosIndex, this.state.BreakerCircuitLabelInput)
        this.setState({ showEditOptions: false })
    }

    render() {
        return (
            <form className={`switchboard__breaker is-${this.props.poles}-slot 
                    ${isOdd(this.props.chassisPosIndex) ? 'is-odd' : 'is-even'}
                    ${this.state.showEditOptions ? 'is-edit' : ''}
                `} onSubmit={ (event) => { event.preventDefault(); this.updateBrekerChassisNoteCall(); }}>

                <div className="switchboard__breaker-checklist">
                    <div className="switchboard__breaker-check">
                        { this.props.poles === 1 ? this.props.chassisPosIndex : this.props.chassisPosIndex - 2 }
                    </div>
                    <div className="switchboard__breaker-check">
                        { this.props.chassisPosIndex }
                    </div>
                    <div className="switchboard__breaker-check">
                        { this.props.chassisPosIndex + 2 }
                    </div>
                    <div className="switchboard__breaker-check">
                        { this.props.chassisPosIndex + 4 }
                    </div>
                    <div className="switchboard__breaker-check">
                        { this.props.chassisPosIndex + 6 }
                    </div>
                    <div className="switchboard__breaker-check">
                        { this.props.chassisPosIndex + 8 }
                    </div>
                </div>
                                    
                <div className="switchboard__breaker-content">
                    <input className="input" type="text" required value={ this.state.BreakerCircuitLabelInput } 
                        onChange={ (event) => this.setState({ BreakerCircuitLabelInput: event.target.value }) } maxLength="30" />

                    {
                        this.state.BreakerCircuitLabelInput !== '' && !this.state.showEditOptions &&
                        <div className="switchboard__breaker-note">
                            { this.state.BreakerCircuitLabelInput }
                        </div>
                    }

                    <span className="switchboard__breaker-text">
                        { this.props.code }
                    </span>
                </div>
                                    
                <div className="switchboard__breaker-actions">
            
                    <div className="actions actions--default">
                        <button className={ `button button--icon edit ${this.props.itemId === 0 ? 'is-disabled' : ''}`} type="button" onClick={ this.editClickCall }>
                            <span className="i-edit"></span>
                        </button>
                        <button className={ `button button--icon delete ${this.state.isDeleting ? 'is-deleting' : ''} ${this.props.itemId === 0 ? 'is-disabled' : ''}`} type="button" onClick={ this.deleteCall } disabled={this.props.itemId === 0}>
                            <span className="i-trash-bin"></span>
                        </button>
                    </div>
            
                    <div className="actions actions--edit">
                        <button className="button button--icon confirm" type="button" onClick={ this.updateBrekerChassisNoteCall }>
                            <span className="i-check"></span>
                        </button>
                        <button className="button button--icon cancel" type="button" onClick={ this.editClickCall }>
                            <span className="i-close"></span>
                        </button>
                    </div>
            
                </div>
            </form>
        )
    }
}
