import React from 'react'
import { Link, withRouter } from 'react-router-dom'
import Header from '../../../components/header/Header'
import { getUrlParameter, isNullOrUndefined } from '../../../utils/helpers'
import GlobalOverlay from '../../../components/global-overlay/GlobalOverlay'
import commonFunctionsService from '../../../services/CommonFunctionsService'
import Modal from '../../../components/modal/Modal'
import BoardDetailsResidential from './BoardDetails'
import cloneDeep from 'lodash.clonedeep'
import BoardList from './BoardList'
import { apiCallsCommercial } from '../../../utils/apiCallsCommercial'
import { apiCallsResidential } from '../../../utils/apiCallsResidential'
import BoardService from '../../../services/BoardService'
import analyticsDataLayer from '../../../services/AnalyticsDataLayer'

const { CONST } = require('./../../../CONST')

class ProjectDetailsResidential extends React.Component {
    constructor(props) {
        super(props)

        this.deleteBreakerFromBoard = this.deleteBreakerFromBoard.bind(this)

        this.state = {
            projectID: '',
            project: {},
            userData: {},
            loadingDrawings: false,
            orderSummaryId: null,
            currentBoard: null,
            boardDrawings: [],
            boardOptionData: null,
            breakerOptionData: [],
            boardOptions: {},
            apiErrorMessage: '',
            isLoading: true,
            apiError: false,
            apiMessage: 'Loading page',
            isChangingProjectTitle: false,
            hasSubmittedNewProjectTitle: false,
            newProjectTitle: '',
            isRequestingNewProjectTitleChange: false,
            changingTitleApiErrorMessage: false,
            isConfiguringBoard: false,
            isDuplicatingBoard: false,
            hasDuplicatedBoard: false,
            isDeletingBoard: false,
            toDeleteBoardId: null,
            pageError: '',
            wholesalerBranchID: 0,
            showBoardDetails: false,
            selectedBoardDescription: [],
            isCommercial: false,
            isUpdatingBoardQuantity: false,
            residentialAllPartsAvailable: []
        }
    }

    componentDidMount() {
        this.setState({ projectID: getUrlParameter('orderID') }, () => {
            this.initialize()
        })
    }

    initialize() {
        apiCallsResidential.apiGetProject(this.state.projectID)
            .then(projectData => {
                this.setState({
                    project: projectData,
                    newProjectTitle: projectData.Title
                })

                // On a finalised project we need to get drawing links and order summary info
                if (projectData.IsFinalised) {
                    this.setState({ loadingDrawings: true })

                    apiCallsResidential.apiGetLayoutDiagrams(this.state.projectID)
                        .then(response => {
                            // Split the response into distinct results
                            const orderSummaryArray = response.ProjectFileCollection.filter(x => x.ProjectBoardID === -1)
                            const drawingsArray = response.ProjectFileCollection.filter(x => x.ProjectBoardID !== -1)

                            if (orderSummaryArray.length > 0) {
                                this.setState({ orderSummaryId: orderSummaryArray[0].ItemGUID })
                            }

                            this.setState({
                                boardDrawings: drawingsArray,
                                loadingDrawings: false
                            })
                        })
                        .catch(error => {
                            this.setState({
                                apiErrorMessage: error.message,
                                loadingDrawings: false
                            })
                        })
                }

                return apiCallsResidential.apiGetBreakerOptions()
            })
            .then(breakerTypeData => {
                this.setState({ breakerOptionData: breakerTypeData })

                // Load the board options after the project has been loaded
                return apiCallsResidential.apiGetBoardOption()
            })
            .then(boardOptionData => {
                this.setState({ boardOptionData: boardOptionData }, () => this.updateProjectBoardCompletions())

                return apiCallsResidential.apiGetBoardOptionLabels()
            })
            .then(boardOptionLabels => {
                this.setState({ boardOptions: boardOptionLabels })

                // If the user opens a project and there are no boards, then open the modal
                if (this.state.project.ProjectBoards.length === 0) {
                    // Add some visual deleay to improve UX, without it it's just too fast and confusing
                    window.setTimeout(() => {
                        this.startConfiguringBoard()
                    }, 500)
                }

                return apiCallsCommercial.apiUserSettings()
            })
            .then(userData => {

                this.setState({
                    userData: userData,
                    wholesalerBranchID: userData.PreferredWholesaler1ItemID
                }, () => {
                    let shouldTriggerNewBoardCreation = getUrlParameter('newBoard')
                    if (shouldTriggerNewBoardCreation !== '') {
                        this.startConfiguringBoard()
                    }
                })
                return apiCallsResidential.apiGetAllParts()
            })
            .then(allParts => {
                this.setState({
                    residentialAllPartsAvailable: allParts,
                    isLoading: false
                })
            })
            .catch(error => {
                this.setState({ apiErrorMessage: error.message })
            })
    }

    updateProjectBoardCompletions() {
        const updatedProjectBoards = this.state.project.ProjectBoards.map(board => {
            const boardDetails = BoardService.getDetails(this.state.boardOptionData, board.EnclosureID, board.MainSwitchID, board.SPDID, board.MetalBlackPlateID)
            const boardCompletionDetails = BoardService.getBoardCompletionDetails(board, boardDetails)

            board.completionDetails = boardCompletionDetails

            return board
        })

        const updatedProject = cloneDeep(this.state.project)
        updatedProject.ProjectBoards = updatedProjectBoards

        this.setState({ project: updatedProject })
    }

    startConfiguringBoard = (boardID) => {
        if (boardID === undefined) {
            if (this.hasMaxBoardLimitBeenReached()) {
                this.setState({ pageError: 'You have reached the maximum number of boards per project and cannot ADD or COPY anymore.' })
            } else if (this.state.project.IsFinalised) {
                this.setState({ pageError: 'The order has alredy been sent and the project can no longer be modified.' })
            } else {
                this.setState({ isConfiguringBoard: true, currentBoard: null })
            }

            window.setTimeout(() => this.setState({ pageError: '' }), 5000)
        } else {
            const boardToEdit = this.state.project.ProjectBoards.find(board => board.ItemID === boardID)
            this.setState({ isConfiguringBoard: true, currentBoard: boardToEdit })
        }
    }

    stopConfiguringBoard = (board) => {
        this.setState({ isConfiguringBoard: false, currentBoard: null })

        if (!isNullOrUndefined(board)) {
            // Modal sucessfually create or updated a board, add it to the project states or update existing one
            const indexOfExistingBoard = this.state.project.ProjectBoards.findIndex(projectBoard => projectBoard.ItemGUID === board.ItemGUID)

            if (indexOfExistingBoard > -1) {
                this.state.project.ProjectBoards[indexOfExistingBoard] = board
            } else {
                this.state.project.ProjectBoards.push(board)
            }

            this.updateProjectBoardCompletions()
            this.forceUpdate()
        }

        commonFunctionsService.closeModals()
    }

    copyBoard = (boardId) => {
        this.setState({ isDuplicatingBoard: true })

        apiCallsResidential.apiCopyBoard(this.state.project.ItemID, boardId)
            .then(projectData => {
                this.setState({
                    project: projectData
                })

                this.setState({ isDuplicatingBoard: false, hasDuplicatedBoard: true })
                window.setTimeout(() => { this.setState({ hasDuplicatedBoard: false }) }, 1500)
            })
            .catch(error => {
                this.setState({ isDuplicatingBoard: false })
                alert(`There was an error copying the board: ${error.message}`)
            })
    }

    updateBoardQuantity = (boardId, newQuantity, isApiCall) => {
        const updatedBoard = this.state.project.ProjectBoards.find(board => board.ItemID === boardId)
        updatedBoard.Quantity = newQuantity

        const updatedProject = cloneDeep(this.state.project)

        const indexOfTheCurrentProjectBoard = updatedProject.ProjectBoards.findIndex(board => {
            return board.ItemID === updatedBoard.ItemID
        })

        updatedProject.ProjectBoards[indexOfTheCurrentProjectBoard] = updatedBoard

        if (isApiCall) {
            this.setState({ isUpdatingBoardQuantity: true })

            apiCallsResidential.apiSaveBoard(updatedBoard).then(() => {
                this.setState({ project: updatedProject })
                this.setState({ isUpdatingBoardQuantity: false })
            })
        } else {
            this.setState({ project: updatedProject })
        }
    }

    toDeleteBoard = (boardId) => {
        this.setState({ isDeletingBoard: true, toDeleteBoardId: boardId })
    }

    deleteBoardRequest() {
        this.setState({ isRequestingBoardDeletion: true })

        this.stopDeletingBoard()

        apiCallsResidential.apiDeleteBoard(this.state.projectID, this.state.toDeleteBoardId)
            .then(updatedProject => {
                this.setState({
                    project: updatedProject,
                    toDeleteBoardId: null,
                    isRequestingBoardDeletion: false
                }, () => this.updateProjectBoardCompletions())
            })
            .catch(error => alert(error.message))
    }

    startChangingProjectTitle = () => {
        this.setState({ isChangingProjectTitle: true })
    }

    stopChangingProjectTitle = () => {
        this.setState({ isChangingProjectTitle: false })
        commonFunctionsService.closeModals()
    }

    stopDeletingBoard = () => {
        this.setState({ isDeletingBoard: false })
        commonFunctionsService.closeModals()
    }

    changeProjectTitle = () => {
        this.setState({ hasSubmittedNewProject: true })

        if (this.state.newProjectTitle !== '') {
            this.setState({
                changingTitleApiErrorMessage: '',
                isRequestingNewProjectTitleChange: true
            })

            let projectData = cloneDeep(this.state.project)
            projectData.Title = this.state.newProjectTitle

            apiCallsResidential.apiSetProjectTitle(projectData)
                .then(response => {
                    // At this point we can read ItemGUID from the response and redirect user to that page
                    this.setState({
                        hasSubmittedNewProjectTitle: true,
                        isRequestingNewProjectTitleChange: false,
                        project: response
                    }, () => {
                        this.stopChangingProjectTitle()
                    })
                })
                .catch(error => {
                    this.setState({
                        changingTitleApiErrorMessage: error,
                        isRequestingNewProjectTitleChange: false
                    })
                })

        }
    }

    deleteBreakerFromBoard(rowIndex, poleIndex, boardBreakerId, boardID) {
        const indexOfTheCurrentProjectBoard = this.state.project.ProjectBoards.findIndex(board => board.ItemID === boardID)
        const selectedBoard = this.state.project.ProjectBoards[indexOfTheCurrentProjectBoard]
        const selectedBoardBreaker = selectedBoard.ProjectBoardBreakerCollection.find(boardBreaker => boardBreaker.ItemID === boardBreakerId)

        apiCallsResidential.apiSaveBreakerToChassis(this.state.projectID, boardID, boardBreakerId, selectedBoardBreaker.BreakerTypeItemID, rowIndex, poleIndex, true)
            .then(() => {
                const updatedBreakers = selectedBoard.ProjectBoardBreakerCollection.filter(item => item.ItemID !== boardBreakerId)
                const updatedProject = cloneDeep(this.state.project)
                const updatedBoard = cloneDeep(selectedBoard)
                updatedBoard.ProjectBoardBreakerCollection = updatedBreakers

                updatedProject.ProjectBoards[indexOfTheCurrentProjectBoard] = updatedBoard

                this.setState({ project: updatedProject })
            })
    }

    handelChange = (name, value) => {
        this.setState({ ['' + name]: value })
    }

    hasMaxBoardLimitBeenReached() {
        // Function to check if the user has reached max board limit or not
        // App should not let user copy or add boards if they are at the limit.
        // Hardcode the max board limit if its not returned from the API
        const boardLimit = this.state.project.MaxBoardLimit ? this.state.project.MaxBoardLimit : 8
        return (this.state.project.ProjectBoards.length >= boardLimit)
    }

    configureChassis = (projectID, boardID) => {
        this.props.history.push(CONST.PAGES.CONFIGURE_CHASSIS_RESIDENTIAL + '?projectID=' + projectID + '&boardID=' + boardID)
    }

    areEnoughBoardsToGenerateQuote = () => {
        let totalUserBoardsQuantity = 0
        this.state.project.ProjectBoards.map(board => {
            totalUserBoardsQuantity = totalUserBoardsQuantity + board.Quantity
        })

        return totalUserBoardsQuantity >= CONST.MINIMUM_RESIDENTIAL_BOARD_QUANTITY ? true : false
    }

    isEnoughFilledToGenerateQuote() {
        // Let's work with assumption all boards are all enough filled and invalidate this indicator as soon as any board is not enough filled
        let areAllProjectBoardsEnoughFilled = true

        if (this.state.boardOptionData !== null) {
            this.state.project.ProjectBoards.map(board => {
                const boardDetails = BoardService.getDetails(this.state.boardOptionData, board.EnclosureID, board.MainSwitchID, board.SPDID, board.MetalBlackPlateID)
                const boardCompletionDetails = BoardService.getBoardCompletionDetails(board, boardDetails)

                if (!boardCompletionDetails.isEnoughCompletedToSeeTheQuote) areAllProjectBoardsEnoughFilled = false
            })
        } else {
            areAllProjectBoardsEnoughFilled = false
        }

        return areAllProjectBoardsEnoughFilled
    }

    viewQuote() {
        if (this.state.isUpdatingBoardQuantity) return false
        // If you don't have any boards...
        if (!this.state.project.ProjectBoards || !this.state.project.ProjectBoards.length) {
            this.setState({
                pageError: 'Your project needs at least one board filled with 4 breakers to generate a quote.',
                fillQuoteRequirementError: true
            })
            window.setTimeout(() => this.setState({ pageError: '' }), 5000)

            return false
        }

        let areEnoughBoardsToGenerateQuote = this.areEnoughBoardsToGenerateQuote()
        if (!areEnoughBoardsToGenerateQuote) {
            this.setState({
                pageError: 'Board quantity is not enough to generate a quote.',
                fillQuoteRequirementError: true
            })
            return false
        }

        // You've got boards but not all of them are filled out to minimum levels...
        let noEnoughFilled = !this.isEnoughFilledToGenerateQuote()
        if (noEnoughFilled) {
            this.setState({
                pageError: 'Your project needs all boards filled with at least 4 breakers to generate a quote.',
                fillQuoteRequirementError: true
            })
            window.setTimeout(() => this.setState({ pageError: '' }), 5000)

            return false
        }

        // If you're a contractor you need a selected wholesaler
        if (this.state.userData.UserIsContractor && (this.state.wholesalerBranchID === 0 || this.state.wholesalerBranchID === -1)) {
            this.setState({ pageError: 'Current user doesn\'t have a preferred wholesalers configured.' })
            window.setTimeout(() => this.setState({ pageError: '' }), 5000)
            return false
        }

        // Finally made it through all the hoops, go to the Quote page.
        let pageLink = ''
        if (this.state.userData.UserIsContractor) {
            pageLink += CONST.PAGES.QUOTE_RESIDENTIAL + '?projectID=' + this.state.projectID + '&wholesalerBranchID=' + this.state.wholesalerBranchID
            this.props.history.push(pageLink)
        } else {
            pageLink += CONST.PAGES.QUOTE_RESIDENTIAL + '?projectID=' + this.state.projectID
            this.props.history.push(pageLink)
        }

        analyticsDataLayer.ctaButtonClick({
            cta_name: 'View Quote',
            cta_link: window.location.hostname + pageLink,
            cta_location: 'Project details'
        })
    }

    renderProjectBoardModals = () => {
        return (
            <table className="table">
                <thead>
                    <tr>
                        <th>Quantity</th>
                        <th>Item</th>
                    </tr>
                </thead>
                <tbody>
                    {this.state.selectedBoardDescription.map((boardDescription, index) => {
                        return (
                            <tr key={index}>
                                <td>{boardDescription.Quantity}</td>
                                <td>{boardDescription.Description}</td>
                            </tr>
                        )
                    })}
                </tbody>
            </table>
        )
    }

    showBoardDetails = boardDescription => {
        this.setState({ selectedBoardDescription: boardDescription, showBoardDetails: true })
    }

    hideBoardDetails = () => {
        this.setState({ showBoardDetails: false })

        commonFunctionsService.closeModals()
    }

    render() {
        return (
            <div className="app">
                {
                    this.state.isLoading &&
                    <GlobalOverlay>
                        <div>
                            <div className="globalOverlay__message">
                                <span className="globalOverlay__message-spinner">
                                    <span className="spinner"></span>
                                </span>
                                <span className="h3">Loading</span>
                            </div>
                        </div>
                    </GlobalOverlay>
                }

                {
                    this.state.isDuplicatingBoard &&
                    <GlobalOverlay>
                        <div>
                            <div className="globalOverlay__message">
                                <span className="globalOverlay__message-spinner">
                                    <span className="spinner"></span>
                                </span>
                                <span className="h3">Duplicating Board</span>
                            </div>
                        </div>
                    </GlobalOverlay>
                }

                {
                    this.state.hasDuplicatedBoard &&
                    <GlobalOverlay>
                        <div>
                            <div className="globalOverlay__message">
                                <span className="globalOverlay__message-success-icon">
                                    <span className="i-check"></span>
                                </span>
                                <span className="h3">Board copied successfully</span>
                            </div>
                        </div>
                    </GlobalOverlay>
                }

                {
                    this.state.isRequestingBoardDeletion &&
                    <GlobalOverlay>
                        <div>
                            <div className="globalOverlay__message">
                                <span className="globalOverlay__message-spinner">
                                    <span className="spinner"></span>
                                </span>
                                <span className="h3">Deleting Board</span>
                            </div>
                        </div>
                    </GlobalOverlay>
                }

                <Header isCommercial={false}>
                    <div className="header__section hs_breadcrumbs">
                        <div className="breadcrumbs">
                            <Link className="breadcrumb" to="/?showResidential=true">My Residential Projects</Link>
                            <span className="breadcrumb">{this.state.project.Title}</span>
                        </div>
                    </div>
                    <div className="header__section hs_board">
                        <div className="header__section-col header__title-container">
                            <h2 className="h3 header__title">
                                {this.state.project.Title}
                            </h2>
                            <button className="button button--3 button--icon" onClick={this.startChangingProjectTitle}>
                                <span className="button__icon">
                                    <span className="i-edit"></span>
                                </span>
                            </button>
                            <span className={'tag tag--2' +
                                (this.state.project.IsToBeApproved ? ' is-available' : '') +
                                (!this.state.project.IsFinalised ? ' is-in-progress' : '') +
                                (this.state.project.IsFinalised ? ' is-complete' : '')
                            }>

                                {this.state.project.IsToBeApproved && 'Available'}
                                {!this.state.project.IsFinalised && 'In Progress'}
                                {this.state.project.IsFinalised && 'Order Completed'}
                            </span>
                        </div>

                        {!this.state.isLoading && !this.state.project.IsFinalised &&
                            <div className="header__actions">
                                <button className={'button button--2 button--view-quote' + (!this.isEnoughFilledToGenerateQuote() || !this.areEnoughBoardsToGenerateQuote() || this.state.isUpdatingBoardQuantity ? ' disabled' : '')}
                                    onClick={() => this.viewQuote()}>
                                    <span className="button__icon">
                                        <span className="i-document"></span>
                                    </span>
                                    <span className="button__text">
                                        View quote
                                    </span>
                                </button>
                                <button className="button button--1" onClick={() => this.startConfiguringBoard()}>
                                    <span className="button__icon">
                                        <span className="i-plus"></span>
                                    </span>
                                    <span className="button__text">
                                        New Board
                                    </span>
                                </button>
                            </div>
                        }
                    </div>
                </Header>

                <div className="app__body app__body--boards">
                    {
                        this.state.project.ProjectBoards !== undefined &&
                        <div className="app__body-content">
                            {
                                this.state.pageError !== '' &&
                                <p className="alert alert--error">
                                    <span className="alert__icon">
                                        <span className="i-exclamation"></span>
                                    </span>
                                    <span className="alert__text">
                                        {this.state.pageError}
                                    </span>
                                </p>
                            }

                            {
                                this.state.project.ProjectBoards.length === 0 &&
                                <div className="board-message-mute">
                                    <svg width="54" height="54" viewBox="0 0 54 54" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M40.5 50.9766C41.3438 50.9766 41.9766 51.6797 41.9766 52.5234C41.9766 53.2969 41.3438 54 40.5 54H1.47656C0.703125 54 0 53.2969 0 52.5234V1.47656C0 0.703125 0.703125 0 1.47656 0H40.5C41.3438 0 41.9766 0.703125 41.9766 1.47656V19.4766C41.9766 20.3203 41.3438 21.0234 40.5 21.0234C39.6562 21.0234 39.0234 20.3203 39.0234 19.4766V18.6328H22.5V50.9766H40.5ZM19.4766 3.02344H3.02344V10.8984H19.4766V3.02344ZM39.0234 15.6797V3.02344H22.5V15.6797H39.0234ZM19.4766 30.6562V13.9219H3.02344V30.6562H19.4766ZM12.0234 24.75C11.1797 24.75 10.4766 24.1172 10.4766 23.2734C10.4766 22.4297 11.1797 21.7969 12.0234 21.7969H16.1719C17.0156 21.7969 17.6484 22.4297 17.6484 23.2734C17.6484 24.1172 17.0156 24.75 16.1719 24.75H12.0234ZM41.5547 24.3281C48.4453 24.3281 54 29.8828 54 36.7031C54 43.5938 48.4453 49.1484 41.5547 49.1484C34.7344 49.1484 29.1797 43.5938 29.1797 36.7031C29.1797 29.8828 34.7344 24.3281 41.5547 24.3281ZM41.5547 46.125C46.7578 46.125 50.9766 41.9062 50.9766 36.7031C50.9766 31.5 46.7578 27.2812 41.5547 27.2812C36.3516 27.2812 32.1328 31.5 32.1328 36.7031C32.1328 41.9062 36.3516 46.125 41.5547 46.125ZM46.8281 35.2266C47.6719 35.2266 48.3047 35.8594 48.3047 36.7031C48.3047 37.5469 47.6719 38.25 46.8281 38.25H43.1016V41.9766C43.1016 42.8203 42.3984 43.4531 41.5547 43.4531C40.7812 43.4531 40.0781 42.8203 40.0781 41.9766V38.25H36.3516C35.5078 38.25 34.8047 37.5469 34.8047 36.7031C34.8047 35.8594 35.5078 35.2266 36.3516 35.2266H40.0781V31.5C40.0781 30.6562 40.7812 29.9531 41.5547 29.9531C42.3984 29.9531 43.1016 30.6562 43.1016 31.5V35.2266H46.8281ZM3.02344 33.6797V40.0781H19.4766V33.6797H3.02344ZM3.02344 43.1016V50.9766H19.4766V43.1016H3.02344Z" fill="#CBCBCB" />
                                        <path d="M40.5 50.9766C41.3438 50.9766 41.9766 51.6797 41.9766 52.5234C41.9766 53.2969 41.3438 54 40.5 54H1.47656C0.703125 54 0 53.2969 0 52.5234V1.47656C0 0.703125 0.703125 0 1.47656 0H40.5C41.3438 0 41.9766 0.703125 41.9766 1.47656V19.4766C41.9766 20.3203 41.3438 21.0234 40.5 21.0234C39.6562 21.0234 39.0234 20.3203 39.0234 19.4766V18.6328H22.5V50.9766H40.5ZM19.4766 3.02344H3.02344V10.8984H19.4766V3.02344ZM39.0234 15.6797V3.02344H22.5V15.6797H39.0234ZM19.4766 30.6562V13.9219H3.02344V30.6562H19.4766ZM12.0234 24.75C11.1797 24.75 10.4766 24.1172 10.4766 23.2734C10.4766 22.4297 11.1797 21.7969 12.0234 21.7969H16.1719C17.0156 21.7969 17.6484 22.4297 17.6484 23.2734C17.6484 24.1172 17.0156 24.75 16.1719 24.75H12.0234ZM41.5547 24.3281C48.4453 24.3281 54 29.8828 54 36.7031C54 43.5938 48.4453 49.1484 41.5547 49.1484C34.7344 49.1484 29.1797 43.5938 29.1797 36.7031C29.1797 29.8828 34.7344 24.3281 41.5547 24.3281ZM41.5547 46.125C46.7578 46.125 50.9766 41.9062 50.9766 36.7031C50.9766 31.5 46.7578 27.2812 41.5547 27.2812C36.3516 27.2812 32.1328 31.5 32.1328 36.7031C32.1328 41.9062 36.3516 46.125 41.5547 46.125ZM46.8281 35.2266C47.6719 35.2266 48.3047 35.8594 48.3047 36.7031C48.3047 37.5469 47.6719 38.25 46.8281 38.25H43.1016V41.9766C43.1016 42.8203 42.3984 43.4531 41.5547 43.4531C40.7812 43.4531 40.0781 42.8203 40.0781 41.9766V38.25H36.3516C35.5078 38.25 34.8047 37.5469 34.8047 36.7031C34.8047 35.8594 35.5078 35.2266 36.3516 35.2266H40.0781V31.5C40.0781 30.6562 40.7812 29.9531 41.5547 29.9531C42.3984 29.9531 43.1016 30.6562 43.1016 31.5V35.2266H46.8281ZM3.02344 33.6797V40.0781H19.4766V33.6797H3.02344ZM3.02344 43.1016V50.9766H19.4766V43.1016H3.02344Z" fill="#CBCBCB" />
                                        <path d="M40.5 50.9766C41.3438 50.9766 41.9766 51.6797 41.9766 52.5234C41.9766 53.2969 41.3438 54 40.5 54H1.47656C0.703125 54 0 53.2969 0 52.5234V1.47656C0 0.703125 0.703125 0 1.47656 0H40.5C41.3438 0 41.9766 0.703125 41.9766 1.47656V19.4766C41.9766 20.3203 41.3438 21.0234 40.5 21.0234C39.6562 21.0234 39.0234 20.3203 39.0234 19.4766V18.6328H22.5V50.9766H40.5ZM19.4766 3.02344H3.02344V10.8984H19.4766V3.02344ZM39.0234 15.6797V3.02344H22.5V15.6797H39.0234ZM19.4766 30.6562V13.9219H3.02344V30.6562H19.4766ZM12.0234 24.75C11.1797 24.75 10.4766 24.1172 10.4766 23.2734C10.4766 22.4297 11.1797 21.7969 12.0234 21.7969H16.1719C17.0156 21.7969 17.6484 22.4297 17.6484 23.2734C17.6484 24.1172 17.0156 24.75 16.1719 24.75H12.0234ZM41.5547 24.3281C48.4453 24.3281 54 29.8828 54 36.7031C54 43.5938 48.4453 49.1484 41.5547 49.1484C34.7344 49.1484 29.1797 43.5938 29.1797 36.7031C29.1797 29.8828 34.7344 24.3281 41.5547 24.3281ZM41.5547 46.125C46.7578 46.125 50.9766 41.9062 50.9766 36.7031C50.9766 31.5 46.7578 27.2812 41.5547 27.2812C36.3516 27.2812 32.1328 31.5 32.1328 36.7031C32.1328 41.9062 36.3516 46.125 41.5547 46.125ZM46.8281 35.2266C47.6719 35.2266 48.3047 35.8594 48.3047 36.7031C48.3047 37.5469 47.6719 38.25 46.8281 38.25H43.1016V41.9766C43.1016 42.8203 42.3984 43.4531 41.5547 43.4531C40.7812 43.4531 40.0781 42.8203 40.0781 41.9766V38.25H36.3516C35.5078 38.25 34.8047 37.5469 34.8047 36.7031C34.8047 35.8594 35.5078 35.2266 36.3516 35.2266H40.0781V31.5C40.0781 30.6562 40.7812 29.9531 41.5547 29.9531C42.3984 29.9531 43.1016 30.6562 43.1016 31.5V35.2266H46.8281ZM3.02344 33.6797V40.0781H19.4766V33.6797H3.02344ZM3.02344 43.1016V50.9766H19.4766V43.1016H3.02344Z" stroke="#FAFAFA" />
                                    </svg>
                                    <p>
                                        You don’t have any board yet in your project.
                                    </p>
                                    <p>
                                        Add your first board above
                                    </p>
                                </div>
                            }

                            {
                                this.state.project.ProjectBoards.length > 0 &&
                                <BoardList
                                    boards={this.state.project.ProjectBoards}
                                    projectId={this.state.project.ItemID}
                                    maxBoardLimitReached={this.hasMaxBoardLimitBeenReached()}
                                    fillQuoteRequirementError={this.state.fillQuoteRequirementError}
                                    isFinalised={this.state.project.IsFinalised}
                                    boardDrawings={this.state.boardDrawings}
                                    isUpdatingBoardQuantity={this.state.isUpdatingBoardQuantity}
                                    loadingDrawings={this.state.loadingDrawings}
                                    editCallback={this.startConfiguringBoard}
                                    showBoardDetailsCallback={this.showBoardDetails}
                                    copyCallback={this.copyBoard}
                                    configureChassisCallback={this.configureChassis}
                                    updateBoardQuantityCallback={this.updateBoardQuantity}
                                    deleteCallback={this.toDeleteBoard} />
                            }
                        </div>
                    }
                </div>

                {
                    this.state.project.ItemID !== undefined &&
                    <div className="app__footer">
                        <div className="steps steps--progress">

                            <div className="is-done step step--progress">
                                <span className="step__number">
                                    <span className="i-check"></span>
                                </span>
                                <span className="step__text">
                                    Project Created
                                </span>
                            </div>

                            <div className={'step step--progress' + (this.state.project.ProjectBoards.length > 0 ? ' is-done' : '')}>
                                <span className="step__number">
                                    <span className="i-check"></span>
                                </span>
                                <span className="step__text">
                                    Board Added
                                </span>
                            </div>

                            <div className={'step step--progress' + (this.areEnoughBoardsToGenerateQuote() ? ' is-done' : '')}>
                                <span className="step__number">
                                    <span className="i-check"></span>
                                </span>
                                <span className="step__text">
                                    Board Quantity <span className="em">(Min {'30'} boards)</span>
                                </span>
                            </div>

                            <div className={'step step--progress' + (this.isEnoughFilledToGenerateQuote() ? ' is-done' : '')}>
                                <span className="step__number">
                                    <span className="i-check"></span>
                                </span>
                                <span className="step__text">
                                    All Boards Configured <span className="em">(Min 4 breakers per board)</span>
                                </span>
                            </div>

                            <div className={'step step--progress' + (this.state.project.IsFinalised ? ' is-done' : '')}>
                                <span className="step__number">
                                    <span className="i-check"></span>
                                </span>
                                <span className="step__text">
                                    Quote Generated
                                </span>
                            </div>

                            <div className={'step step--progress' + (this.state.project.IsFinalised ? ' is-done' : '')}>
                                <span className="step__number">
                                    <span className="i-check"></span>
                                </span>
                                <span className="step__text">
                                    Order to Complete
                                </span>
                            </div>

                        </div>
                    </div>
                }

                {this.state.isChangingProjectTitle &&
                    <Modal
                        modalClassName="modal--sm"
                        modalTitle="Change Project Title"
                        callback={this.stopChangingProjectTitle}>

                        <form className="form">
                            <div className={"form__group " + (this.state.hasSubmittedNewProjectTitle && this.state.newProjectTitle === '' ? 'is-invalid' : '')}>
                                <label className="form__label">Project name</label>
                                <input className="form__input input" type="text" name="newProjectTitle" value={this.state.newProjectTitle}
                                    onChange={(event) => this.handelChange(event.target.name, event.target.value)} required maxLength="128" autoFocus></input>

                            </div>

                            {
                                this.state.changingTitleApiErrorMessage &&
                                <div>{this.state.changingTitleApiErrorMessage} </div>
                            }

                            <div className="form__actions">
                                <button className="button button--text" onClick={this.stopChangingProjectTitle}>
                                    <span className="button__text">Cancel</span>
                                </button>
                                <button type="button" className={"button button--1 " + (this.state.isRequestingNewProjectTitleChange ? 'disabled' : '')}
                                    onClick={this.changeProjectTitle} disabled={this.state.isRequestingNewProjectTitleChange}>
                                    <span className="button__text">
                                        Change
                                    </span>
                                </button>
                            </div>
                        </form>

                    </Modal>
                }

                {this.state.isDeletingBoard &&
                    <Modal
                        modalClassName="modal--sm"
                        modalTitle="Confirm Board Deletion"
                        callback={this.stopDeletingBoard}>

                        <form className="form">
                            <p>Are you sure you want to delete this board?</p>

                            <div className="form__actions">
                                <button className="button button--text" onClick={this.stopDeletingBoard}>
                                    <span className="button__text">Cancel</span>
                                </button>
                                <button type="button" className={"button button--1 " + (this.state.isRequestingBoardDeletion ? 'disabled' : '')}
                                    onClick={() => this.deleteBoardRequest()} disabled={this.state.isRequestingBoardDeletion}>
                                    <span className="button__text">
                                        Yes, delete
                                    </span>
                                </button>
                            </div>
                        </form>

                    </Modal>
                }

                {
                    this.state.showBoardDetails &&
                    <Modal
                        modalClassName="modal--md modal--sm-header"
                        modalTitle="Board Details"
                        callback={this.hideBoardDetails}
                    >
                        {this.renderProjectBoardModals()}
                    </Modal>
                }

                {
                    this.state.isConfiguringBoard &&
                    <BoardDetailsResidential
                        board={this.state.currentBoard}
                        boardOptions={this.state.boardOptions}
                        boardOptionData={this.state.boardOptionData}
                        breakerOptionData={this.state.breakerOptionData}
                        deleteBreakerFromBoard={this.deleteBreakerFromBoard}
                        closeCallback={this.stopConfiguringBoard}
                        redirectToChassis={this.configureChassis}
                        projectID={this.state.projectID}
                        projectName={this.state.project.Title}
                        residentialAllPartsAvailable={this.state.residentialAllPartsAvailable}
                    />
                }
            </div>
        )
    }
}

export default withRouter(ProjectDetailsResidential)