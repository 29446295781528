import React, { Component } from 'react'

export class SvgLocation extends Component {
  render() {
    return (
        <svg {...this.props} width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M8 0.5C12.4094 0.5 16 4.09055 16 8.5C16 12.9094 12.4094 16.5 8 16.5C3.59055 16.5 0 12.9094 0 8.5C0 4.09055 3.59055 0.5 8 0.5ZM8.44094 15.5971C12.0105 15.3871 14.8871 12.5105 15.0971 8.94094H12.0315C11.7795 8.94094 11.5906 8.75197 11.5906 8.5C11.5906 8.24803 11.7795 8.05905 12.0315 8.05905H15.0971C14.8871 4.4895 12.0105 1.61286 8.44094 1.40289V4.4685C8.44094 4.72047 8.25197 4.90945 8 4.90945C7.74803 4.90945 7.55905 4.72047 7.55905 4.4685V1.40289C3.9895 1.61286 1.11286 4.4895 0.902887 8.05905H3.9685C4.22047 8.05905 4.40945 8.24803 4.40945 8.5C4.40945 8.75197 4.22047 8.94094 3.9685 8.94094H0.902887C1.11286 12.5105 3.9895 15.3871 7.55905 15.5971V12.5315C7.55905 12.2795 7.74803 12.0906 8 12.0906C8.25197 12.0906 8.44094 12.2795 8.44094 12.5315V15.5971Z" fill="white"/>
            <path d="M8 0.5C12.4094 0.5 16 4.09055 16 8.5C16 12.9094 12.4094 16.5 8 16.5C3.59055 16.5 0 12.9094 0 8.5C0 4.09055 3.59055 0.5 8 0.5ZM8.44094 15.5971C12.0105 15.3871 14.8871 12.5105 15.0971 8.94094H12.0315C11.7795 8.94094 11.5906 8.75197 11.5906 8.5C11.5906 8.24803 11.7795 8.05905 12.0315 8.05905H15.0971C14.8871 4.4895 12.0105 1.61286 8.44094 1.40289V4.4685C8.44094 4.72047 8.25197 4.90945 8 4.90945C7.74803 4.90945 7.55905 4.72047 7.55905 4.4685V1.40289C3.9895 1.61286 1.11286 4.4895 0.902887 8.05905H3.9685C4.22047 8.05905 4.40945 8.24803 4.40945 8.5C4.40945 8.75197 4.22047 8.94094 3.9685 8.94094H0.902887C1.11286 12.5105 3.9895 15.3871 7.55905 15.5971V12.5315C7.55905 12.2795 7.74803 12.0906 8 12.0906C8.25197 12.0906 8.44094 12.2795 8.44094 12.5315V15.5971Z" fill="white"/>
        </svg>
    );
  }
}
