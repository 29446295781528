import React, { Component } from 'react'
import jQuery from "jquery"

export default class Modal extends Component {
    componentDidMount() {
        jQuery('body').addClass('modal-is-open')
    }

    renderModalHeader = () => {
        if (this.props.modalTitle) {
            return (
                <div className="modal__header">
                    <button className="modal__close button button--icon" type="button" aria-label="close modal" onClick={this.closeModal}>
                        <span className="i-close"></span>
                    </button>
                    {
                        this.props.modalTitle !== undefined && this.props.modalTitle !== '' &&
                        <h4 className="modal__title h3">{this.props.modalTitle}</h4>
                    }
                </div>
            )
        }
    }

    closeModal = () => {
        if (this.props.callback !== undefined) {
            this.props.callback()
        }

        jQuery('body').removeClass('modal-is-open')
    }


    render() {
        return (
            <div id={ this.props.modalID } className={ 'modal ' + this.props.modalClassName }>
                <div className="modal-container">
                    { this.renderModalHeader() }

                    <div className="modal__body">
                        
                        { this.props.children }
                    </div>
                </div>
                
            </div>
        )
    }
}