import React from 'react'
import { drawPercentProgressBars, getNumberOfRowsFromPolesString, getPolesCountFromPolesString, getNumberOfPolesFromStringExpression } from './../../../utils/helpers'
import ChassisBreaker from './ChassisBreaker'
import ChassisContactor from './ChassisContactor'
import ChassisFittedOption from './ChassisFittedOption'
import BoardEmptyPole from './BoardEmptyPole'
import BoardMainSwitch from './BoardMainSwitch'
import WiserEnergyGateway from './WiserEnergyGateway'
import BoardSurgeProtection from './BoardSurgeProtection'
import BusbarService from './../../../services/BusbarService'

export default class ChassisBoardResidential extends React.Component {

    constructor() {
        super()
        // Bind this to element function calls for better performance / practise
        // - ref: https://github.com/yannickcr/eslint-plugin-react/blob/master/docs/rules/jsx-no-bind.md
        this.deleteCall = this.deleteCall.bind(this)
        this.deleteContactorCall = this.deleteContactorCall.bind(this)
        this.deleteFittedOptionCall = this.deleteFittedOptionCall.bind(this)
        this.togglePowerTag = this.togglePowerTag.bind(this)
        this.updateBrekerChassisNote = this.updateBrekerChassisNote.bind(this)
        this.updateContactorChassisNote = this.updateContactorChassisNote.bind(this)
        this.updateFittedOptionChassisNote = this.updateFittedOptionChassisNote.bind(this)
        this.updateFittedOptionPosition = this.updateFittedOptionPosition.bind(this)
        this.updateBreakerPosition = this.updateBreakerPosition.bind(this)
        this.updateContactorPosition = this.updateContactorPosition.bind(this)
        this.updateMainSwitchPosition = this.updateMainSwitchPosition.bind(this)
        this.updateWiserEnergyGatewayPosition = this.updateWiserEnergyGatewayPosition.bind(this)

        // Internal states to track what mode the selections are
        this.state = {
            isUpdatingDevice: false,
            notesOpen: []
        }
    }

    componentDidMount() {
        drawPercentProgressBars()
    }
    
    shouldComponentUpdate(nextProps) {
        // Probably will need to update the progress, but leave it commened for now.
        // drawPercentProgressBars()

        if (this.props.percentageConfigured !== nextProps.percentageConfigured) {
            console.warn('Should redraw percentages')
            window.setTimeout(() => {
                drawPercentProgressBars()
            }, 1000)
        }

        if (this.props.isUpdatingDevice !== nextProps.isUpdatingDevice) {
            this.setState({ isUpdatingDevice: nextProps.isUpdatingDevice })
        }

        return true
    }

    togglePowerTags = () => {
        this.props.updatePowerTagsEditing(!this.props.isEditingPowerTags)
    }

    deleteCall(rowIndex, poleIndex, itemId) {
        this.props.deleteCallback(rowIndex, poleIndex, itemId)
    }

    deleteContactorCall(rowIndex, poleIndex, itemId) {
        this.props.deleteContactorCallback(rowIndex, poleIndex, itemId)
    }

    deleteFittedOptionCall(rowIndex, poleIndex, itemId) {
        this.props.deleteFittedOptionCallback(rowIndex, poleIndex, itemId)
    }

    togglePowerTag(rowIndex, poleIndex, itemId, position, addPowerTag) {
        this.props.togglePowerTagCallback(rowIndex, poleIndex, itemId, position, addPowerTag)
    }

    updateBrekerChassisNote(itemId, rowIndex, poleIndex, note) {
        this.props.updateBrekerChassisNote(itemId, rowIndex, poleIndex, note)
    }
    
    updateContactorChassisNote(itemId, rowIndex, poleIndex, note) {
        this.props.updateContactorChassisNote(itemId, rowIndex, poleIndex, note)
    }

    updateFittedOptionChassisNote(itemId, rowIndex, poleIndex, note) {
        this.props.updateFittedOptionChassisNote(itemId, rowIndex, poleIndex, note)
    }

    updateBreakerPosition(itemId, rowIndex, poleIndex, note) {
        this.props.updateBreakerPosition(itemId, rowIndex, poleIndex, note)
    }

    updateContactorPosition(itemId, rowIndex, poleIndex, note) {
        this.props.updateContactorPosition(itemId, rowIndex, poleIndex, note)
    }

    updateFittedOptionPosition(itemId, rowIndex, poleIndex, note) {
        this.props.updateFittedOptionPosition(itemId, rowIndex, poleIndex, note)
    }

    updateMainSwitchPosition(updateMainSwitchPosition) {
        this.props.updateMainSwitchPosition(updateMainSwitchPosition)
    }

    updateWiserEnergyGatewayPosition(rowIndex, poleIndex) {
        this.props.updateWiserEnergyGatewayPosition(rowIndex, poleIndex)
    }

    getRowBusbar(rowArrayIndex, position) {
        return this.props.busbars.find(busbar => {
            return busbar.Row === rowArrayIndex + 1 && busbar.BusBarReadOnly.MountingPosition === position
        })
    }

    removeBusbar(ItemID, BusBarItemID) {
        this.props.removeBusbar(ItemID, BusBarItemID)
    }

    renderRows() {
        const rows = []
        const rowsCount = getNumberOfRowsFromPolesString(this.props.polesNeeded)
        const columnsCount = getPolesCountFromPolesString(this.props.polesNeeded)

        for (let rowArrayIndex = 0; rowArrayIndex < rowsCount; rowArrayIndex++) {
            const rowTopBusbar = this.getRowBusbar(rowArrayIndex, 'Top')
            const rowBottomBusbar = this.getRowBusbar(rowArrayIndex, 'Bottom')
            const isAllowedToDragNDropPole = rowTopBusbar === undefined && rowBottomBusbar === undefined

            rows.push(
                <div className={'switchboard__row-container ' +  ('is-' + columnsCount + '-pole')} key={rowArrayIndex}>
                    <div className="switchboard__res-row">
                        <p className="switchboard__row-title p--sm">
                            { rowArrayIndex === 0 ? 'First' : ''} { rowArrayIndex === 1 ? 'Second' : ''} { rowArrayIndex === 2 ? 'Third' : ''} { rowArrayIndex === 3 ? 'Fourth' : ''} row
                        </p>

                        {/* Top slots */}
                        <div className={'switchboard__row-extra ' + 
                            (rowTopBusbar !== undefined && rowTopBusbar.BusBarReadOnly.MountingPosition === 'Top' ? 'busbar-is-active' : '')}> 
                            {
                                !this.props.isEditingPowerTags && 
                                <span className="row-extra__text row-extra__text--available p p--xs">Space Available - Busbar</span>
                                // <span className="row-extra__text row-extra__text--available p p--xs">Space Available - Busbar or PowerTags</span>
                            }

                            {
                                rowTopBusbar !== undefined &&
                                <div className={'busbar-details ' + ('is-' + (rowTopBusbar.BusBarLength + rowTopBusbar.ExtraPoles )+ '-slot') + (' is-position-' + rowTopBusbar.StartPosition)}>
                                    <span className="row-extra__text p p--xs">Busbar { rowTopBusbar.BusBarReadOnly.NumberOfPoles }</span>
                                    <span className="row-extra__text p p--xs">Extra Poles &nbsp; &nbsp; { rowTopBusbar.ExtraPoles }</span>

                                    <div className="actions">
                                        <button className="button button--icon delete" type="button" 
                                            onClick={() => this.removeBusbar(rowTopBusbar.ItemID, rowTopBusbar.BusBarItemID)}>
                                            <span className="i-trash-bin"></span>
                                        </button>
                                    </div>
                                </div>
                            }
                        </div>

                        {/* Device columns */}
                        <div className="switchboard__row">
                            { this.renderPoles(rowArrayIndex, isAllowedToDragNDropPole) }
                        </div>

                        {/* Bottom slots */}
                        <div className={'switchboard__row-extra ' + 
                            (rowBottomBusbar !== undefined && rowBottomBusbar.BusBarReadOnly.MountingPosition === 'Bottom' ? 'busbar-is-active' : '')}> 
                            {
                                !this.props.isEditingPowerTags && 
                                <span className="row-extra__text row-extra__text--available p p--xs">Space Available - Busbar</span>
                                // <span className="row-extra__text row-extra__text--available p p--xs">Space Available - Busbar or PowerTags</span>
                            }

                            {/* Manipulate is-position-1 class to indicate where busbar starts */}
                            {
                                rowBottomBusbar !== undefined &&
                                <div className={'busbar-details ' +   ('is-' + (rowBottomBusbar.BusBarLength + rowBottomBusbar.ExtraPoles) + '-slot')  + (' is-position-' + rowBottomBusbar.StartPosition)}>
                                    <span className="row-extra__text p p--xs">Busbar { rowBottomBusbar.BusBarReadOnly.NumberOfPoles }</span>
                                    <span className="row-extra__text p p--xs">Extra Poles &nbsp; &nbsp; { rowBottomBusbar.ExtraPoles }</span>

                                    <div className="actions">
                                        <button className="button button--icon delete" type="button" 
                                            onClick={() => this.removeBusbar(rowBottomBusbar.ItemID, rowBottomBusbar.BusBarItemID)}>
                                            <span className="i-trash-bin"></span>
                                        </button>
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                </div>
            )
        }

        return rows
    }

    renderPoles(rowArrayIndex, isAllowedToDragNDropPole) {
        const poles = []
        const columnsCount = getPolesCountFromPolesString(this.props.polesNeeded)
        const rowTopBusbar = this.getRowBusbar(rowArrayIndex, 'Top')
        const rowBottomBusbar = this.getRowBusbar(rowArrayIndex, 'Bottom')
        const busbarsWithNeutralBlock = BusbarService.getBusbarsWithNeutralTerminalBlockReferences()
        const isRowWithSurgeProtection = rowArrayIndex === 0 && this.props.surgeProtection

        let poleArrayIndexOfMandatorySurgeProtectionMCB = null
        if (isRowWithSurgeProtection) {
            poleArrayIndexOfMandatorySurgeProtectionMCB = this.props.surgeProtectionDetails.SpaceUsed
        }

        let currentBusbar = rowTopBusbar
        if (rowBottomBusbar !== undefined) currentBusbar = rowBottomBusbar
        
        for (let poleArrayIndex = 0; poleArrayIndex < columnsCount; poleArrayIndex++) {
            let poleDisplayIndex = poleArrayIndex + 1
            if (poleDisplayIndex < 10) poleDisplayIndex = '0' + poleDisplayIndex
       
            let shouldDisplayNeutralBlock = false
            if (currentBusbar !== undefined) {
                if (busbarsWithNeutralBlock.indexOf(currentBusbar.BusBarReadOnly.Reference) > -1 && (this.props.mainSwitchPosition - 1) === poleArrayIndex) { 
                    shouldDisplayNeutralBlock = true
                }
            }

            const breaker = this.props.breakers.find(breaker => breaker.RowIndex === rowArrayIndex && breaker.PoleIndex === poleArrayIndex)
            const contactor = this.props.contactors.find(contactor => contactor.RowIndex === rowArrayIndex && contactor.PoleIndex === poleArrayIndex)
            const fittedOption = this.props.fittedOptions.find(fittedOption => fittedOption.RowIndex === rowArrayIndex && fittedOption.PoleIndex === poleArrayIndex)

            let shouldDisplayWiserEnergyGateway = false
            if (this.props.hasWiserEnergyGateway && this.props.wiserEnergyGatewayRow === rowArrayIndex && this.props.wiserEnergyGatewayPosition === poleArrayIndex) {
                shouldDisplayWiserEnergyGateway = true
            }

            // If board has a surge protection selected, then display it as first item in the first row
            if (rowArrayIndex === 0 && poleArrayIndex === 0 && this.props.surgeProtection) {
                poles.push(
                    <React.Fragment key={poleArrayIndex}>
                        <BoardSurgeProtection poleIndex={poleArrayIndex} poleDisplayIndex={poleDisplayIndex} spaceUsed={this.props.surgeProtectionDetails.SpaceUsed} /> 
                    </React.Fragment>)

            // Checks for the main switch
            } else if (rowArrayIndex === 0 && poleArrayIndex === this.props.mainSwitchPosition) { 
                poles.push(
                    <React.Fragment key={poleArrayIndex}>
                        <BoardMainSwitch 
                            poleIndex={poleArrayIndex} 
                            poleDisplayIndex={poleDisplayIndex} 
                            poles={this.props.mainSwtchIncomingPhases}
                            rating={this.props.mainSwitchRating}
                            updateMainSwitchPosition={this.updateMainSwitchPosition}
                            isAllowedToDragNDropPole={isAllowedToDragNDropPole}
                            canAddDeviceToPosition={(speceUsed, rowIndex, poleIndex, isMainSwitch) => 
                                this.props.canAddDeviceToPosition(speceUsed, rowIndex, poleIndex, isMainSwitch)}
                        />
                    </React.Fragment>)
            } else if (breaker !== undefined) {
                poles.push(
                    <React.Fragment key={poleArrayIndex}>
                        <ChassisBreaker
                            itemId={breaker.ItemID}
                            code={breaker.BreakerTypeReadOnly.TypeOfDevice}
                            poles={breaker.BreakerTypeReadOnly.SpaceUsed}
                            currentRating={breaker.BreakerTypeReadOnly.CurrentRating}
                            BreakerCircuitLabelInput={breaker.BreakerCircuitLabel}
                            RowIndex={breaker.RowIndex}
                            PoleIndex={breaker.PoleIndex}
                            TopPowerTag={breaker.TopPowerTag}
                            BottomPowerTag={breaker.BottomPowerTag}
                            isAllowedToDragNDropPole={isAllowedToDragNDropPole}
                            deletedCallback={this.deleteCall}
                            togglePowerTagCallback={this.togglePowerTag}
                            updateBrekerChassisNote={this.updateBrekerChassisNote}
                            updateBreakerPosition={this.updateBreakerPosition}
                            isMandatoryMCB={poleArrayIndex === poleArrayIndexOfMandatorySurgeProtectionMCB}
                            canAddDeviceToPosition={(speceUsed, rowIndex, poleIndex, isMainSwitch, breakerItemID) => 
                                this.props.canAddDeviceToPosition(speceUsed, rowIndex, poleIndex, isMainSwitch, breakerItemID)}
                        />
                    </React.Fragment>)
            } else if (contactor !== undefined) {
                poles.push(
                    <React.Fragment key={poleArrayIndex}>
                        <ChassisContactor
                            itemId={contactor.ItemID}
                            code={contactor.ContractorReadOnly.TypeOfDevice}
                            poles={contactor.ContractorReadOnly.SpaceUsed}
                            currentRating={contactor.ContractorReadOnly.CurrentRating}
                            customLabel={contactor.BreakerCircuitLabel}
                            RowIndex={contactor.RowIndex}
                            PoleIndex={contactor.PoleIndex}
                            isAllowedToDragNDropPole={isAllowedToDragNDropPole}
                            deletedCallback={this.deleteContactorCall}
                            updateContactorChassisNote={this.updateContactorChassisNote}
                            updateContactorPosition={this.updateContactorPosition}
                        />
                    </React.Fragment>)
            } else if (fittedOption !== undefined) {
                poles.push(
                    <React.Fragment key={poleArrayIndex}>
                        <ChassisFittedOption
                            itemId={fittedOption.ItemID}
                            code={fittedOption.FittedOptionReadOnly.TypeOfDevice}
                            poles={fittedOption.FittedOptionReadOnly.SpaceUsed}
                            currentRating={fittedOption.FittedOptionReadOnly.Rating}
                            customLabel={fittedOption.BreakerCircuitLabel}
                            RowIndex={fittedOption.RowIndex}
                            PoleIndex={fittedOption.PoleIndex}
                            isAllowedToDragNDropPole={isAllowedToDragNDropPole}
                            deletedCallback={this.deleteFittedOptionCall}
                            updateFittedOptionChassisNote={this.updateFittedOptionChassisNote}
                            updateFittedOptionPosition={this.updateFittedOptionPosition}
                        />
                    </React.Fragment>)
            } else if (shouldDisplayNeutralBlock) {
                poles.push(
                    <React.Fragment key={poleArrayIndex}>
                        <div className="switchboard__slot is-neutral-terminal-block">
                            <div className="special-slot">
                                <span className="switchboard__slot-number">{ poleDisplayIndex }</span>
                                <div className="switchboard__slot-text">
                                    Neutral terminal block
                                </div>
                            </div>
                        </div>
                    </React.Fragment>)
            } else if (shouldDisplayWiserEnergyGateway) {
                poles.push(
                    <React.Fragment key={poleArrayIndex}>
                        <WiserEnergyGateway 
                            poleIndex={poleArrayIndex} 
                            poleDisplayIndex={poleDisplayIndex} 
                            poles={3}
                            updateWiserEnergyGatewayPosition={this.updateWiserEnergyGatewayPosition}
                            isAllowedToDragNDropPole={isAllowedToDragNDropPole}
                            canAddDeviceToPosition={(speceUsed, rowIndex, poleIndex) => 
                                this.props.canAddDeviceToPosition(speceUsed, rowIndex, poleIndex)}
                        />
                    </React.Fragment>)
            } else {
                poles.push(
                    <React.Fragment key={poleArrayIndex}>
                        <BoardEmptyPole 
                            rowIndex={rowArrayIndex} 
                            poleIndex={poleArrayIndex} 
                            poleDisplayIndex={poleDisplayIndex}
                            isAllowedToDragNDropPole={isAllowedToDragNDropPole} />
                    </React.Fragment>)
            }
        }

        return poles
    }

    render() { 
        return (
            <div className={`switchboard ${this.props.isEditingPowerTags ? 'is-power-tags' : ''}`}>
                <div className="switchboard__res-header">
                    <div className="res-header__row">
                        <div className="switchboard__res-header__item">
                            <div className="donut-container">
                                <div className="donut progress-bar-percent" data-value={this.props.percentageConfigured}></div>
                            </div>
                            <span className="h4">{ this.props.boardName }</span>
                        </div>
                        <div className="switchboard__res-header__item">
                            <button className="button button--outline button-edit" onClick={this.props.openBusbarModalCallback} disabled={this.state.isUpdatingDevice}>
                                <span className="button__icon">
                                    <span className="i-plus"></span>
                                </span>
                                <span className="button__text">Busbar</span>
                            </button>
                            {/* <button className={`button button--outline button-edit ${this.props.isEditingPowerTags ? 'checked' : ''}`} 
                                onClick={ () => this.togglePowerTags()}>
                                <span className="button__slider"></span>
                                <span className="button__text">PowerTags</span>
                            </button> */}
                        </div>
                    </div>
                    <div className="res-header__row">
                        <div className="switchboard__board-header">
                            <span>{ this.props.configured } Pole Configured</span>
                            <span>{ this.props.remaining } of { this.props.total } poles Remaining</span>
                        </div>
                    </div>
                </div>
                
                <div className="switchboard__board">
                    <div className="switchboard__breaker-container">
                        { this.renderRows() }
                    </div>
                </div>
            </div>
        )
    }
}
