import React, { Component } from 'react'
import { Portal } from '../portal/Portal'
import { SvgLocation } from '../svg/SvgLocation'
import { apiWholesalerSearch } from '../../utils/apiCallsCommercial'
import { debounce } from 'lodash'
export class InputwholesalersLookUp extends Component {
    constructor(props) {
        super(props)

        if (!!props.existingWholesalerData) {
            console.log(props.existingWholesalerData)
            this.state = {
                isOpen: false,
                isDirty: false,
                list: [],
                selectedBranchName: props.existingWholesalerData.WholesalerBranchName,
                selectedWholesalerBranchID: props.existingWholesalerData.WholesalerBranchID,
                latitude: null,
                longitude: null,
                isPending: false
            }
        } else {
            this.state = {
                isOpen: false,
                isDirty: false,
                list: [],
                selectedBranchName: '',
                selectedWholesalerBranchID: null,
                latitude: null,
                longitude: null,
                isPending: false
            }
        }
    }

    toggleFlyout = () => {
        this.setState({ isOpen: !this.state.isOpen })
    }

    handleSearch = () => {
        const term = document.getElementById('search-wholesalers-input').value
        const { latitude, longitude } = this.state
        this.setState({ isPending: true })

        apiWholesalerSearch({
            SearchText: term,
            Latitude: latitude,
            Longitude: longitude
        })
            .then(response => {
                this.setState({ list: response })
            })
            .catch(error => {
                window.alert(`Failed to fetch wholesalers: ${error.message}`)
            })
            .finally(() => {
                this.setState({ isDirty: true })
                this.setState({ isPending: false })
            })
    }

    handleGeoSearch = (e) => {
        if (!navigator.geolocation || (this.state.latitude != null && !this.state.longitude != null))  {
            this.handleSearch()
            return
        }

        navigator.geolocation.getCurrentPosition(position => {
            this.setState({ latitude: position.coords.latitude })
            this.setState({ longitude: position.coords.longitude })
            this.handleSearch()
        })
    }

    fetchWholesalers = debounce(this.handleSearch, 2000)

    render() {
        return (
            <>
                <button className="wholesaler__trigger" type="button" onClick={this.toggleFlyout}>
                    <div className="wholesaler__title">
                        <span hidden={this.state.selectedBranchName !== ''}>{this.props?.placeholder || this.props?.label || 'Select and option'}</span>
                        {this.state.selectedBranchName !== '' && <p>{this.state.selectedBranchName}</p>}
                    </div>
                    <i className="wholesaler__icon i-plus"></i>
                </button>
                
                <div hidden>
                    <input type="hidden" id={this.props.id} value={this.state.selectedWholesalerBranchID} required={this.props?.required}/>
                    <label className='form__label p--sm' htmlFor={this.props.id}>{this.props?.label || 'Select and option'}</label>
                </div>

                {this.state.isOpen && (<Portal>
                    <div className="flyout is-open wholesaler-lookup__flyout">
                        <div className="flyout-container">
                            <div className="flyout__header">
                                <h3 className="flyout__title h3">Search Wholesalers</h3>
                                <button type="button" className="flyout__close button" onClick={this.toggleFlyout}>
                                    <span className="i-close"></span>
                                </button>
                            </div>
                            <div className="flyout__body">
                                <div className="form__section">
                                    <div className="row">
                                        <div className="col">
                                            <div className="form__group input-field">
                                                <input
                                                    id="search-wholesalers-input"
                                                    className="form__input input"
                                                    type="text"
                                                    placeholder="Search by name, postcode or suburb"
                                                    onChange={this.fetchWholesalers}
                                                />
                                                <div hidden={!this.state.isPending} className="loader">
                                                    <svg height="30" width="30">
                                                        <circle cx="15" cy="15" r="10" strokeWidth="2" fill="none"></circle>
                                                    </svg>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="form__actions">
                                    <button className="button button--1" type="button" onClick={this.handleGeoSearch}>
                                        <span className="button__text">
                                            Near Me
                                        </span>
                                        <span className="button__icon">
                                            <SvgLocation />
                                        </span>
                                    </button>
                                </div>

                                {this.state.isDirty && this.state.list.length === 0 && (
                                    <div>
                                        <p>No wholesalers match your search. Please try again.</p> 
                                    </div>
                                )}

                                {this.state.list.length > 0 && (
                                    <div className="wholesaler__list">
                                        {this.state.list.map((item) => (
                                            <button 
                                                key={item.ItemID} 
                                                className="wholesaler" 
                                                data-value={item.name}
                                                onClick={() => { 
                                                    this.setState({ selectedBranchName: item.WholesalerBranchName, selectedWholesalerBranchID: item.WholesalerBranchID }); this.toggleFlyout() 
                                                }}>
                                                <div className="wholesaler__title">
                                                    <p>{item.WholesalerBranchName}</p>
                                                    <span>{item.WholesalerBranchAddress}, {item.WholesalerBranchSuburb}, {item.WholesalerBranchPostcode} {item.WholesalerBranchState}</span>
                                                </div>
                                                <i className="wholesaler__icon i-plus"></i>
                                            </button>
                                        ))}
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </Portal>)}
            </>
        )
    }
}
