export const userTypes = [
    {
        name: 'Wholesaler',
        type: 'Wholesaler',
        icon: 'i-wholesaler',
        isEnable: true
    },
    {
        name: 'Switchboard Manufacturer',
        type: 'SwitchboardManufacturer',
        icon: 'i-switchboard-m',
        isEnable: true
    },
    {
        name: 'Contractor',
        type: 'Electrician',
        icon: 'i-contractor',
        isEnable: true
    },
    {
        name: 'Consultant',
        type: 'Consultant',
        icon: 'i-consultant',
        isEnable: true
    },
    {
        name: 'LargeContractor',
        type: 'LargeContractor',
        icon: 'i-contractor',
        isEnable: false
    }
]