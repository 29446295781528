import React from 'react'

export class BoardSurgeProtection extends React.Component {
    constructor (props) {
        super(props)
    }

    generateDevicePositions(startNumber, polesUsed) {
        const spaceUsed = parseInt(polesUsed, 10)
        const displayNumbers = []

        for (let i = 0; i < spaceUsed; i++) {
            let displayNumber = startNumber + i
            if (displayNumber < 10) displayNumber = '0' + displayNumber

            displayNumbers.push(
                <div className="breaker-check-container" key={displayNumber}>
                    <div className="switchboard__breaker-check">{ displayNumber }</div>
                </div>
            )
        }

        return displayNumbers
    }

    render () {
        return (
            <div className={`switchboard__slot is-disabled is-surge-protection is-${this.props.spaceUsed}-slot`}>
                <div className="special-slot">
                    <div className="switchboard__breaker-checklist">
                        { this.generateDevicePositions(this.props.poleIndex + 1, this.props.spaceUsed) }
                    </div>
                    {/* <span className="switchboard__slot-number">{ this.props.poleDisplayIndex }</span> */}
                    <div className="switchboard__slot-text">
                        Surge Protection
                    </div>
                </div>
            </div>
        )
    }
}

export default BoardSurgeProtection