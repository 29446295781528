import Axios from 'axios'

// Hardcoded label values
import { apiUrlGetBoardOptionLabels } from '../data/commercial/get-board-type-option-labels'
import { apiUrlGetBreakerOptionLabels } from '../data/commercial/get-breaker-type-option-labels'

const { CONST } = require('../CONST')

// -----------------------------------
// Defind consts for the api calls
// -----------------------------------
export const apiUrlGetDocument = `${CONST.API_PATH}/Project/GetDocument`
const apiUrlGetLayoutDiagrams = `${CONST.API_PATH}/Project/GetLayoutDiagrams`
const apiUrlWholesalerSearch = `${CONST.API_PATH}/Wholesaler/Wholesalers`
const apiUrlArchiveDeleteProject = `${CONST.API_PATH}/Project/Archive`
const apiUrlCopyProject = `${CONST.API_PATH}/Project/Copy`
const apiUrlActivityCount = `${CONST.API_PATH}/Project/ActivityCount`
const apiUrlUserSettings = `${CONST.API_PATH}/ReferenceData/GetUserSettings`
const apiUrlAllProjects = `${CONST.API_PATH}/Project/AllProjects`
const apiUrlGetProject = `${CONST.API_PATH}/Project/Get`
const apiUrlGetBoardOptions = `${CONST.API_PATH}/ReferenceData/GetBoardTypeOptions`
const apiUrlGetBreakerOptions = `${CONST.API_PATH}/ReferenceData/GetBreakerTypeOptions`
const apiUrlDeleteBoard = `${CONST.API_PATH}/Project/DeleteBoard`
const apiUrlCopyBoard = `${CONST.API_PATH}/Project/CopyBoard`
const apiUrlSaveBoard = `${CONST.API_PATH}/Project/SaveBoard`
const apiUrlGetBoardMiscParts = `${CONST.API_PATH}/ReferenceData/GetMiscellaneousParts`
const apiUrlGenerateQuote = `${CONST.API_PATH}/Project/Quote`
const apiUrlSaveProject = `${CONST.API_PATH}/Project/SaveProject`
const apiUrlSendOrder = `${CONST.API_PATH}/Project/PlaceOrder`
const apiUrlApplyPromoCode = `${CONST.API_PATH}/Project/ApplyPromoCode`
const apiUrlGetBoard = `${CONST.API_PATH}/Project/GetBoard`
const apiUrlSaveBoardBreakerToolbox = `${CONST.API_PATH}/Project/SaveToolboxBreaker`
const apiUrlSaveFavBreaker = `${CONST.API_PATH}/ReferenceData/SaveFavouriteBreaker`
const apiUrlSaveBreakerToChassis = `${CONST.API_PATH}/Project/SaveBreaker`
const apiUrlSaveBoardChassisOptions = `${CONST.API_PATH}/Project/SaveBoardFittedOptions`
const apiUrlSaveBoardMiscParts = `${CONST.API_PATH}/Project/SaveBoardLooseSupplyItems`
const apiUrlAttachPo = `${CONST.API_PATH}/Project/AttachPO`
const apiUrlEmailQuote = `${CONST.API_PATH}/Project/EmailQuote`
const apiURLGetAllParts = `${CONST.API_PATH}/ReferenceData/Parts`
const apiURLGetParentChild = `${CONST.API_PATH}/ReferenceData/GetParentChild`
const apiUrlXmasMsg = `${CONST.LEGACY_API_PATH}/LDBXmasHolidayHandler.ashx` // This is an old API - not in V3 space

// -----------------------------------
// Export clean api call functions for internal use
// @NOTE - All the api calls are done as an AJAX call, thus all these functions will return a promise
// -----------------------------------

// Get Documents
export function apiGetDocument(documentID = 0) {
    return processRequest(
        Axios.get(`${apiUrlGetDocument}/${documentID}`),
        'download document'
    )
}

// GetLayoutDiagrams
export function apiGetLayoutDiagrams(projectId = 0) {
    return processRequest(
        Axios.get(`${apiUrlGetLayoutDiagrams}/${projectId}?{}`),
        'retrieve layout diagrams'
    )
}

// Search for Wholesalers
export function apiWholesalerSearch(requestObj) {
    return processRequest(
        Axios.post(apiUrlWholesalerSearch, requestObj),
        'retrieve list of wholesalers'
    )
}

// Archive or Delete a Project
export function apiArchiveDeleteProject(requestObj) {
    return processRequest(
        Axios.post(apiUrlArchiveDeleteProject, requestObj),
        'retrieve project data'
    )
}

// Copy a whole Project
export function apiCopyProject(requestObj) {
    return processRequest(
        Axios.post(apiUrlCopyProject, requestObj),
        'retrieve project data'
    )
}

// Return the number of projects a user has complete, incomplete or to be approved.
export function apiActivityCount() {
    return processRequest(
        Axios.get(apiUrlActivityCount),
        'retrieve project data'
    )
}

// Get the logged in user information to update the LHS nav.
export function apiUserSettings() {
    return processRequest(
        Axios.get(apiUrlUserSettings),
        'retrieve project data'
    )
}

// Return a list of all projects that are complete/incomplete/to-be-approved.
export function apiAllProjects(requestObj) {
    return processRequest(
        Axios.post(apiUrlAllProjects, requestObj),
        'retrieve project data'
    )
}

// Get the project data
export function apiGetProject(projectId = 0) {
    return processRequest(
        Axios.get(`${apiUrlGetProject}/${projectId}?timestamp=${new Date().getTime()}`),
        'retrieve project data',
    )
}

// Get the board data
export function apiGetBoard(projectId, boardId) {
    return processRequest(
        Axios.post(apiUrlGetBoard, {
            "ProjectID": projectId,
            "ProjectBoardID": boardId
        }),
        'get board data'
    )
}

// Get the board misc parts data
export function apiGetBoardMiscParts(boardID) {
    let requestURL = apiUrlGetBoardMiscParts

    if (boardID !== null && boardID !== undefined) {
        requestURL = requestURL + '?boardID=' + boardID
    }

    return processRequest(
        Axios.get(requestURL),
        'retrieve board misc parts data'
    )
}

// Get the board options
export function apiGetBoardOption() {
    return processRequest(
        Axios.get(apiUrlGetBoardOptions),
        'retrieve board options data'
    )
}

// Get the board option labels - this returns a promise like other api calls even tho it is just
// loading hardcoded values - we'd like this to change in the future to have hte values come from server
export function apiGetBoardOptionLabels() {
    return new Promise(resolve => resolve(apiUrlGetBoardOptionLabels.Data) )
}

// Get the Breaker options
export function apiGetBreakerOptions() {
    return processRequest(
        Axios.get(apiUrlGetBreakerOptions),
        'retrieve breaker options data',
    )
}

// Get the breaker option labels - this returns a promise like other api calls even tho it is just
// loading hardcoded values - we'd like this to change in the future to have hte values come from server
export function apiGetBreakerOptionLabels() {
    return new Promise(resolve => resolve(apiUrlGetBreakerOptionLabels.Data) )
}

export function apiDeleteBoard(projectId, boardId) {
    return processRequest(
        Axios.post(apiUrlDeleteBoard, {
            "ProjectID": projectId,
            "BoardID": boardId
        }),
        'delete the board'
    )
}

export function apiSaveBreakerToChassis(boardId, breakerTypeId, parentBreakerTypeId, parentBoardBreakerItemId, chassisIndex, positionIndex, boardBreakerId = 0, isDeleted = false, notes = '') {
    return processRequest(
        Axios.post(apiUrlSaveBreakerToChassis, {
            "ItemID": boardBreakerId,
            "ProjectBoardID": boardId,
            "BreakerTypeItemID": breakerTypeId,
            "ParentBreakerTypeItemID": parentBreakerTypeId,
            "ParentBoardBreakerItemID": parentBoardBreakerItemId,
            "ChassisIndex": chassisIndex,
            "ChassisPositionIndex": positionIndex,
            "BreakerCircuitLabel": notes,
            "IsDeleted": isDeleted
        }),
        'save the chassis'
    )
}

export function apiSaveBoardChassisOptions(projectId, boardId, selectedOptionIds) {
    return processRequest(
        Axios.post(apiUrlSaveBoardChassisOptions, {
            "ProjectID": projectId,
            "BoardID": boardId,
            "ProjectBoardFittedOptionCollection": selectedOptionIds
        }),
        'add fitted options the chassis'
    )
}

export function apiSaveBoardMiscParts(projectId, boardId, selectedPartIds) {
    return processRequest(
        Axios.post(apiUrlSaveBoardMiscParts, {
            "ProjectID": projectId,
            "BoardID": boardId,
            "ProjectBoardLooseSupplyItemCollection": selectedPartIds
        }),
        'add misc parts the chassis'
    )
}

export function apiCopyBoard(projectId, boardId) {
    return processRequest(
        Axios.post(apiUrlCopyBoard, {
            "ProjectID": projectId,
            "BoardID": boardId
        }),
        'copy the board'
    )
}

export function apiSaveBoard(boardData) {
    return processRequest(
        Axios.post(apiUrlSaveBoard, boardData),
        'save the board'
    )
}

export function apiSaveBoardBreakerToolbox(boardId, breakerTypeId,
    parentBreakerTypeId = 0, toolboxBreakerId = 0, isDeleted = false) {
    return processRequest(
        Axios.post(apiUrlSaveBoardBreakerToolbox, {
            "ItemID": toolboxBreakerId,
            "ProjectBoardID": boardId,
            "BreakerTypeItemID": breakerTypeId,
            "ParentBreakerTypeItemID": parentBreakerTypeId,
            "IsDeleted": isDeleted
        }),
        'saving breaker to the board toolbox'
    )
}

export function apiSaveFavBreaker(userId, breakerTypeId, parentBreakerTypeId = 0, favBreakerId = 0, isDeleted = false) {
    return processRequest(
        Axios.post(apiUrlSaveFavBreaker, {
            "ItemID": favBreakerId,
            "UserID": userId,
            "BreakerTypeItemID": breakerTypeId,
            "ParentBreakerTypeItemID": parentBreakerTypeId,
            "IsDeleted": isDeleted
        }),
        'saving favourite breaker'
    )
}

export function apiSetProjectTitle(updatedProject) {
    return processRequest(
        Axios.post(apiUrlSaveProject, updatedProject),
        'save project'
    )
}

export function apiGenerateQuote(projectId, wholesalerBranchID = -1,
    companyName = '', custName = '', custAddress = '',
    custPhone = '', custEmail = '', custQuoteRef = '', custMarkup = 125) {
    const quoteData = companyName ? {
        "ProjectID": projectId,
        "WholesalerBranchID": wholesalerBranchID,
        "QuoteCompanyName": companyName,
        "QuoteCustomerName": custName,
        "QuoteCustomerAddress": custAddress,
        "QuoteCustomerPhone": custPhone,
        "QuoteCustomerEmail": custEmail,
        "QuoteCustomerQuoteRef": custQuoteRef,
        "QuoteCustomerMarkupPercent": custMarkup
    } : {
        "ProjectID": projectId,
        "WholesalerBranchID": wholesalerBranchID
    }
    return processRequest(
        Axios.post(apiUrlGenerateQuote, quoteData),
        'generate the quote'
    )
}

export function apiSave(saveProjectRequestObj) {
    return processRequest(
        Axios.post(apiUrlSaveProject, saveProjectRequestObj),
        'save the quote'
    )
}

export function apiSendOrder(projectId) {
    return processRequest( Axios.post(apiUrlSendOrder, { "ProjectID": projectId }), 'place the order' )
}

export function apiApplyPromoCode(applyPromoCodeRequestObj) {
    return processRequest(
        Axios.post(apiUrlApplyPromoCode, applyPromoCodeRequestObj),
        'apply the promotional code'
    )
}

export function apiAttachPo(projectId, quoteCents, discountedQuoteCents, file64String) {
    return processRequest(
        Axios.post(apiUrlAttachPo, {
            "ProjectID": projectId,
            "QuoteCentsIncGST": quoteCents,
            "QuoteDiscountedCentsIncGST": discountedQuoteCents,
            "POFileBast64String": file64String
        }),
        'attach purchase order file'
    )
}

export function apiEmailQuote(projectId, email = false, WholesalerIds) {
    let requestObject = {
        "ProjectID": projectId,
        "WholesalerManagedQuoteSendEmailToCustomer": email
    }

    if (WholesalerIds !== undefined) {
        requestObject.WholesalerIds = WholesalerIds
    }

    return processRequest(
        Axios.post(apiUrlEmailQuote, requestObject),
        'email quote'
    )
}

export function apiGetAllParts() {
    return processRequest(
        Axios.get(apiURLGetAllParts),
        'get all parts'
    )
}

export function apiGetParentChild() {
    return processRequest(
        Axios.get(apiURLGetParentChild),
        'get parent child'
    )
}

export function apiXmasMsg() {
    return new Promise((resolve, reject) => {
        Axios.get(apiUrlXmasMsg)
            .then(response => resolve(response.data))
            .catch(error => reject(new Error(`Failed to get xmas msg: ${error}`)))
    })
}

function processRequest(promiseRequest, actionName, dataName = 'Data') {
    return new Promise((resolve, reject) => {
        promiseRequest
            .then(response => {
                if (response.data.Success) {
                    // On successful response, resolve the promise
                    if (!response || !response.data || !response.data[dataName]) {
                        return resolve()
                    }
                    return resolve(response.data[dataName])
                }

                return reject(new Error(`${response.data.Error ? response.data.Error : response.data.Message}`))
            })
            // On server error, we reject the promise with an error message
            .catch((error) => {
                if (error.response.status === 401) {
                    // In this case, remove token from localstorage and redirect back to the login page
                    localStorage.removeItem('token')
                    window.location.href = CONST.PAGES.HOME
                } else {
                    return reject(new Error(`Failed to ${actionName}: ${error}`))
                }
            })
    })
}

export const apiCallsCommercial = {
    'apiGetDocument': apiGetDocument,
    'apiGetLayoutDiagrams': apiGetLayoutDiagrams,
    'apiWholesalerSearch': apiWholesalerSearch,
    'apiArchiveDeleteProject': apiArchiveDeleteProject,
    'apiCopyProject': apiCopyProject,
    'apiActivityCount': apiActivityCount,
    'apiUserSettings': apiUserSettings,
    'apiAllProjects': apiAllProjects,
    'apiGetProject': apiGetProject,
    'apiGetBoard': apiGetBoard,
    'apiGetBoardMiscParts': apiGetBoardMiscParts,
    'apiGetBoardOption': apiGetBoardOption,
    'apiGetBoardOptionLabels': apiGetBoardOptionLabels,
    'apiGetBreakerOptions': apiGetBreakerOptions,
    'apiGetBreakerOptionLabels': apiGetBreakerOptionLabels,
    'apiDeleteBoard': apiDeleteBoard,
    'apiSaveBreakerToChassis': apiSaveBreakerToChassis,
    'apiSaveBoardChassisOptions': apiSaveBoardChassisOptions,
    'apiSaveBoardMiscParts': apiSaveBoardMiscParts,
    'apiCopyBoard': apiCopyBoard,
    'apiSaveBoard': apiSaveBoard,
    'apiSaveBoardBreakerToolbox': apiSaveBoardBreakerToolbox,
    'apiSaveFavBreaker': apiSaveFavBreaker,
    'apiSetProjectTitle': apiSetProjectTitle,
    'apiGenerateQuote': apiGenerateQuote,
    'apiSave': apiSave,
    'apiSendOrder': apiSendOrder,
    'apiApplyPromoCode': apiApplyPromoCode,
    'apiAttachPo': apiAttachPo,
    'apiEmailQuote': apiEmailQuote,
    'apiGetAllParts': apiGetAllParts,
    'apiGetParentChild': apiGetParentChild,
    'apiXmasMsg': apiXmasMsg
} 