import React from 'react'
import { isNullOrUndefined, drawPercentProgressBars } from '../../../utils/helpers'

export default class ChassisSelection extends React.Component {
    componentDidMount() {
        this.drawPercent()
    }

    selectCall(index) {
        if (index !== this.props.selectedIndex) {
            this.props.selectedCallback(index)
        } 
    }

    shouldComponentUpdate(nextProps) {
        if (this.props.chassis1filled !== nextProps.chassis1filled ||
            this.props.chassis1filled !== nextProps.chassis1filled ||
            isNullOrUndefined(this.props.chassis1filled) !== isNullOrUndefined(nextProps.chassis1filled) ||
            
            this.props.chassis2filled !== nextProps.chassis2filled ||
            this.props.chassis2filled !== nextProps.chassis2filled ||
            isNullOrUndefined(this.props.chassis2filled) !== isNullOrUndefined(nextProps.chassis2filled)) {

            // Put a small delay to wait for the tab to render and attach correct data-value in the dom element for the jQuery plugin
            window.setTimeout(() => { this.drawPercent() }, 500)
        }

        return true
    }

    drawPercent() {
        drawPercentProgressBars()
    }

    renderTab(index, filled) {
        if (isNullOrUndefined(filled)) { return null }

        return (
            <div className={ 'switchboard__tab' + (this.props.selectedIndex === index ? ' is-active' : '')} onClick={this.selectCall.bind(this, index)}>
                <div className="switchboard__tab-donut donut-container">
                    <div className="donut progress-bar-percent" data-value={ filled ? filled : '0' }></div>
                </div>
                <span className="switchboard__tab-text h4">
                    Chassis { index }
                </span>
            </div>
        )
    }

    render() {
        return (
            <div className="switchboard__tabs">
                { this.renderTab(1, this.props.chassis1filled) }
                { this.renderTab(2, this.props.chassis2filled) }
            </div>
        )
    }
}
