import { isNullOrUndefined } from './../../utils/helpers'
import { filter, cloneDeep } from 'lodash'

export default class BoardDetailsFormBusinessLogic {
    // The logic is constructed with all the available options and all the type combination data
    // options - contains display values
    // data - array of all possible boards
    // selectedOptions - what the user selected so far
    constructor(options, data, selectedOptions) {
        this.options = options
        this.data = data
        this.setAndClearRelatedFields(selectedOptions)
    }

    // ------------------------------
    // Logic to handle auto selecting and clearing of data when an option is selected
    // ------------------------------
    setAndClearRelatedFields(selectedOptions) {
        // This function should check the remaining valid board type selections and
        // auto select the options that is the only available option left
        let filteredData = cloneDeep(this.data)
        let canEnableNext = true

        // Walk through the selectedOptions in order, from top down for each of the selectOption (top down in order)...
        for (let property in selectedOptions) {
            if (selectedOptions.hasOwnProperty(property) && property !== 'Name') {
                // check which option should be enabled
                canEnableNext = this.checkOptions(property, canEnableNext, selectedOptions, filteredData)

                // Filter down the filtered board type data with this option selection
                if (property === 'Metering') {
                    filteredData = filteredData.filter(filterItem =>
                        selectedOptions[property]
                            ? filterItem.Chassis1PowerMeterAmperes > 0
                            : filterItem.Chassis1PowerMeterAmperes === 0
                    )
                } else if (property === 'MeterType') {
                    if (selectedOptions[property] !== null) {
                        filteredData = filteredData.filter((filterItem) => {
                            // E.g. Full code: EM-100A-C1, but the value we select is just the prefix EM, DM, or PM
                            // So we filter boards with codes that start with the select value
                            var codePrefix = filterItem['PowerMeter' + filterItem.ChassisCount + 'Code'].substr(0, 2)

                            return codePrefix === selectedOptions[property]
                        })
                    }

                } else {
                    filteredData = filteredData.filter(
                        filterItem =>
                            isNullOrUndefined(selectedOptions[property]) ||
                            isNullOrUndefined(filterItem[property]) ||
                            selectedOptions[property] === filterItem[property]
                    )
                }
            }
        }

        // Return the list of filtered Data - it can be used to retrieve BoardTypeID
        return filteredData
    }

    // Check if the options under a specific config (optionName) can be enabled or not
    // While performing the check, the function may auto select options if it is the last option available
    // This will also auto de-select options if the selected value is not in the available options list
    checkOptions(optionName, canEnableNext, selectedOptions, filteredData) {
        if (!this.options[`Options${optionName}`]) {
            return
        }

        // Has enabled options under this config
        let hasEnabled = false

        // Loop through the options of the specified config - enable the config's options based on a number of logic checks
        this.options[`Options${optionName}`].forEach(item => {
            if (isNullOrUndefined(selectedOptions.Name, true) || !canEnableNext) {
                // Disable all options under the config if board name is blank
                item.enabled = false
            } else if (optionName === 'BreakingCapacity') {
                if (selectedOptions.ChassisType === 'Hybrid') {
                    // Hybrid board only allow 10kA
                    item.enabled = item.value === 10
                } else {
                    // All non-hybrid board option types are available in all breaking capcities
                    item.enabled = true
                }
            } else if (optionName === 'Metering') {
                // Metering data filters are different to others, it works off the "Chassis1PowerMeterAmperes" value
                const meteringData = filteredData.filter(filterItem =>
                    item.value
                        ? filterItem.Chassis1PowerMeterAmperes > 0
                        : filterItem.Chassis1PowerMeterAmperes === 0
                )

                // All checks passed, enable the option
                item.enabled = !!meteringData.length
            } else if (optionName === 'MeterType') {
                // This step will be triggered once all previous options were selected. We enable MeterType:
                // 1. If the previous "Metering" is true (Add Metering = Yes)
                // 2. Enable only options that we can find in already filtered data

                if (selectedOptions.Metering === true) {
                    var uniquePowerCodes = []

                    filteredData.forEach((el) => {
                        if (el.PowerMeter1Code !== '') {
                            if (uniquePowerCodes.indexOf(el['PowerMeter' + el.ChassisCount + 'Code']) === -1) {
                                uniquePowerCodes.push(el['PowerMeter' + el.ChassisCount + 'Code'])
                            }
                        }
                    })

                    const isCodePresent = uniquePowerCodes.some(fullCode => fullCode.substr(0, 2) === item.value)
                    if (isCodePresent) {
                        // Leave comments for debugging
                        // console.warn('Enabling ' + item.label + ' metering type option, because at least one board from already filtered (remaining/available) boards has that metering type present. Also because previous "Add metering" was selected as "Yes".')
                        // console.log(filteredData)
                        // console.table(filteredData, ['ItemID', 'ChassisCount', 'ChassisType', 'ChassisReference', 'EnclosureReference', 'IPRating', 'IsolatorSize', 'MainSwitchType', 'PowerMeter1Code', 'PowerMeter2Code', 'RatingAmpers'])
                        item.enabled = true
                    } else {
                        // console.warn('Metering option ' + item.label + ' was not enabled, because there are no available boards with that code. Available boards (filtered by previous selections): ')
                        // console.log(filteredData)
                        // console.table(filteredData, ['ItemID', 'ChassisCount', 'ChassisType', 'ChassisReference', 'EnclosureReference', 'IPRating', 'IsolatorSize', 'MainSwitchType', 'PowerMeter1Code', 'PowerMeter2Code', 'RatingAmpers'])
                        item.enabled = false
                    }
                } else {
                    item.enabled = false
                }
            } else {
                // Check the filtered data, check if there is any board type still available under the current option
                const currentData = filteredData.filter(filterItem => filterItem[optionName] === item.value)

                // All checks passed, enable the option
                item.enabled = !!currentData.length
            }

            // Set the has enabled flag if the config's option item has been enabled after the checks
            if (item.enabled) {
                hasEnabled = true
            }
        })


        // Now we check the selected option (if any), we need to de-select it if it is not one of the available options
        // This rule should only fire if the board name is filled in
        if (!isNullOrUndefined(selectedOptions[optionName]) && !isNullOrUndefined(selectedOptions.Name, true)) {
            const selectedValidOptions = this.options[`Options${optionName}`].filter(
                item => item.enabled && item.value === selectedOptions[optionName]
            )
            if (!selectedValidOptions.length) {
                selectedOptions[optionName] = null
            }
        }

        // Time to check the current config's options, if only one available option is left, then we need to set that option as the
        // selected option and also disable that option's selection (so the user dont get a choice on it)
        const availableOptions = this.options[`Options${optionName}`].filter(item => item.enabled)
        if (availableOptions.length === 1) {
            availableOptions[0].enabled = false
            selectedOptions[optionName] = availableOptions[0].value
            hasEnabled = false
        }

        // Return if the next config can be selected or not, it can be if this item it self can be enabled
        // AND it either has already got a option selection or none of the options are valid
        return canEnableNext && (!isNullOrUndefined(selectedOptions[optionName]) || !hasEnabled)
    }

    // ------------------------------
    // Logic to check if a radio group can be enabled or not
    //
    // Generally the fields before a radio group must have a value to enable the next lot
    // ------------------------------
    getEnableOptions(key) {
        let returnValue = this.options[`Options${key}`]
            ? this.options[`Options${key}`].map(item => (item.enabled ? item.value : null))
            : []

        return returnValue
    }
}