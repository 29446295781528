import React from 'react'
import { DragSource } from 'react-dnd'

export class BoardMainSwitch extends React.Component {
    constructor (props) {
        super(props)
    }

    generateDevicePositions(startNumber) {
        const spaceUsed = parseInt(this.props.poles, 10)
        const displayNumbers = []

        for (let i = 0; i < spaceUsed; i++) {
            let displayNumber = startNumber + i
            if (displayNumber < 10) displayNumber = '0' + displayNumber

            displayNumbers.push(
                <div className="breaker-check-container" key={displayNumber}>
                    <div className="switchboard__breaker-check">{ displayNumber }</div>
                </div>
            )
        }

        return displayNumbers
    }

    render () {
        if (this.props.isDragging) {
            return <div className={`switchboard__slot`} ref={this.props.connectDragSource} />
        }

        return (
            <div className={`switchboard__slot is-disabled is-main-switch is-${this.props.poles}-slot`} ref={ this.props.connectDragSource }>
                <div className="special-slot">
                    {/* <span className="switchboard__slot-number">{ this.props.poleDisplayIndex }</span> */}
                    <div className="switchboard__breaker-checklist">
                        { this.generateDevicePositions(this.props.poleIndex + 1) }
                    </div>
                    <div className="switchboard__slot-text">
                        Main switch <br></br> { this.props.rating }
                    </div>
                </div>
            </div>
        )
    }
}

export default DragSource('ResidentialChassisPole', 
    {
        beginDrag: (props) => {
            return {}
        },
        endDrag(props, monitor) {
            const item = monitor.getItem()
            const dropResult = monitor.getDropResult() 

            if (dropResult) {
                let poleIndex = dropResult.poleIndex
                let rowIndex = dropResult.rowIndex

                if (rowIndex > 0) {
                    window.alert('Main switch can not be placed in different row.')
                } else {   
                    // Since main switch can occupy 1, 2, or 3 positions, we need to check if it can be places there
                    if (props.canAddDeviceToPosition(props.poles, rowIndex, poleIndex, true)) {
                        props.updateMainSwitchPosition(poleIndex)
                    }
                }
            }
        },
        canDrag(props) {
            return props.isAllowedToDragNDropPole
        }
    }, 
    (connect, monitor) => {
        return {
            connectDragSource: connect.dragSource(),
            isDragging: monitor.isDragging()
        }
    }
)(BoardMainSwitch)
