import React from 'react'
import cloneDeep from 'lodash.clonedeep'
import RadioObjectGroup from '../../radio-object-group/RadioObjectGroup'

export default class AdditionalItemsFormContainer extends React.Component {
    constructor(props) {
        super(props)

        // Internal states that no one else needs to know about, holds info on the selection options
        this.initStates(this.props)
    }

    componentWillReceiveProps(nextProps) {
        if (!this.props.contactors && !nextProps.contactors) return

        // if (!this.props.contactors || !nextProps.contactors) {
        //     this.initStates(nextProps, true)
        // }
    }

    initStates(propsData, useSetState = false) {
        const updatedState = {
            selectedContactor: '',
            selectedFittedOption: '',
            validationMessage: '',
            outOfStock: false
        }
        if (useSetState) {
            this.setState(updatedState)
        } else {
            this.state = updatedState
        }
    }

    createCall() {
        if (this.state.selectedContactor !== '') {
            this.props.saveContactor(this.state.selectedContactor)
        } else if (this.state.selectedFittedOption !== '') {
            this.props.saveFittedOption(this.state.selectedFittedOption)
        }
    }

    render() {
        const contactorOptions = this.props.contactors.map(contactor => {
            return {
                value: contactor.ItemID,
                label: contactor.Description
            }
        })

        const fittedOptions = this.props.fittedOptions.map(fittedOption => {
            return {
                value: fittedOption.ItemID,
                label: fittedOption.Description
            }
        })

        const checkIfInStock = (availabilityItem) => {
            if (!availabilityItem?.IsInStock) {
                this.setState({
                    validationMessage: `${availabilityItem.Description} is out of stock. Please, contact <a href="mailto:au.estimating@se.com">au.estimating@se.com</a>`,
                    outOfStock: true
                })
            } else if (availabilityItem?.IsLowStock) {
                this.setState({
                    validationMessage: `Low stock, longer lead times may apply`,
                    outOfStock: false
                })
            } else {
                this.setState({
                    validationMessage: '',
                    outOfStock: false
                })
            }
        }

        const onSelectCell = (type, value) => {
            if (type === 'contactor') {
                let selectedItem = this.props.contactors.find(contactor => contactor.ItemID === value)
                let availabilityItem = this.props.residentialAllPartsAvailable.find(part => part.ItemCode === selectedItem.Rating)
                checkIfInStock(availabilityItem)
                this.setState({ selectedContactor: value, selectedFittedOption: '' })
            } else {
                let selectedItem = this.props.fittedOptions.find(fittedOption => fittedOption.ItemID === value)
                let availabilityItem = this.props.residentialAllPartsAvailable.find(part => part.ItemCode === selectedItem.Reference)
                checkIfInStock(availabilityItem)
                this.setState({ selectedFittedOption: value, selectedContactor: '' })
            }
        }

        return (
            <div className="breaker-details-form-container slide-out-form-container">
                <div className="form-wrapper">
                    <div className="row">
                        <div className="col-12">
                            <RadioObjectGroup
                                title="Contactors"
                                options={contactorOptions}
                                selectedValue={this.state.selectedContactor}
                                selecteCallback={value => { onSelectCell('contactor', value) }}
                                layout={"layout-33 radio-button-row"}
                            // enabledOptions={ this.getTypeOfDeviceEnabledOptions() }
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <RadioObjectGroup
                                title="Other Fitted Options"
                                options={fittedOptions}
                                selectedValue={this.state.selectedFittedOption}
                                selecteCallback={value => { onSelectCell('fittedOption', value) }}
                                layout={"layout-33 radio-button-row"}
                            // enabledOptions={ this.getTypeOfDeviceEnabledOptions() }
                            />
                        </div>
                    </div>
                </div>

                {
                    this.state.validationMessage &&
                    <div className="modal__validation-msg">
                        <span className="alert__icon">
                            <span className="i-exclamation"></span>
                        </span>
                        <span dangerouslySetInnerHTML={{ __html: this.state.validationMessage }}></span>
                    </div>
                }

                <div className="modal__footer">
                    <button className="button button--1" onClick={() => { this.createCall() }} disabled={this.state.outOfStock}>
                        <span className="button__text">
                            Add item
                        </span>
                    </button>
                </div>
            </div>
        )
    }
}