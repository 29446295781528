// This data defines what options to show for the board options, the values here are hardcoded
// and the format is what we'd expect from the server if these is one day to be maintained on
// the server side.
export const apiUrlGetBoardOptionLabels = {
    "Success": true,
    "Error": null,
    "Message": null,
    "Data": {
        "EnclosureType": [{
            "value": "Surface Mount",
            "label": "Surface Mount",
            "warning": ""
        },
        {
            "value": "Flush Mount",
            "label": "Flush Mount",
            "warning": ""
        }],
        "PolesNeeded": [
            { "value": "8", "label": "8", "warning": "" },
            { "value": "11", "label": "11", "warning": "" },
            { "value": "12", "label": "12", "warning": "" },
            { "value": "15", "label": "15", "warning": "" },
            { "value": "17", "label": "17", "warning": "" },
            { "value": "18", "label": "18", "warning": "" },
            { "value": "12x2", "label": "12x2", "warning": "" },
            { "value": "15x2", "label": "15x2", "warning": "" },
            { "value": "12x3", "label": "12x3", "warning": "" },
            { "value": "15x3", "label": "15x3", "warning": "" },
            { "value": "15x4", "label": "15x4", "warning": "" }
        ],
        "MetalBlackPlate": [{
            "value": true,
            "label": "Yes",
            "warning": ""
        },
        {
            "value": false,
            "label": "No",
            "warning": ""
        }],
        "IncomerPhase": [{
            "value": "Single Phase",
            "label": "1 Phase",
            "warning": ""
        },
        {
            "value": "2 Phase",
            "label": "2 Phases",
            "warning": ""
        },
        {
            "value": "3 Phase",
            "label": "3 Phases",
            "warning": ""
        }], 
        "MainSwitchType": [{
            "value": "Switch Isolator",
            "label": "Switch Isolator",
            "warning": ""
        },
        {
            "value": "Circuit breaker",
            "label": "Circuit Breaker",
            "warning": ""
        }], 
        "CircuitBreaker": [{
            "value": "4.5kA",
            "label": "4.5kA",
            "warning": ""
        },
        {
            "value": "6kA",
            "label": "6kA",
            "warning": ""
        }],
        "MainSwitchRating": [{
            "value": "40A",
            "label": "40A",
            "warning": ""
        },
        {
            "value": "50A",
            "label": "50A",
            "warning": ""
        },
        {
            "value": "63A",
            "label": "63A",
            "warning": ""
        },
        {
            "value": "80A",
            "label": "80A",
            "warning": ""
        },
        {
            "value": "100A",
            "label": "100A",
            "warning": ""
        }],
        "SurgeProtection": [{
            "value": true,
            "label": "Yes",
            "warning": ""
        },
        {
            "value": false,
            "label": "No",
            "warning": ""
        }],
        "MaxDischargeCapacity": [{
            "value": "20kA",
            "label": "20kA",
            "warning": ""
        },
        {
            "value": "40kA",
            "label": "40kA",
            "warning": ""
        }]
    }
}
