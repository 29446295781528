import React from 'react'
import { DropTarget } from 'react-dnd'

export class ChassisEmptyPole extends React.Component {
    constructor (props) {
        super(props)
    }

    render () {
        const isActive = this.props.canDrop && this.props.isOver

        return (
            // Add .is-disabled to visually indicate that the pole is locked
            <div className={ 'switchboard__slot' + (this.props.isDraggingItem ? ' show-available-slots' : '') + (isActive ? ' dragging-on-top' : '') }  
                ref={this.props.connectDropTarget}>
                <span className="switchboard__slot-number">{ this.props.rowIndex }</span>

                <div className="switchboard__slot-text ">
                    <span className="available-text">
                        {
                            isActive ?
                            <span>Release to drop</span>
                            :
                            <span>Slot Available</span>
                        }
                    </span>
                    <span className="hover-text">
                        <span>Drag and drop a breaker from your library</span>
                    </span>
                </div>
            </div>
        )
    }
}

export default DropTarget('ChassisPole',
    {
        drop: (props) => ({ 
            poleIndex: props.rowIndex
        })
    },
    (connect, monitor) => ({
        connectDropTarget: connect.dropTarget(),
        isOver: monitor.isOver(),
        canDrop: monitor.canDrop(),
        isDraggingItem: monitor.getItem() !== null
    })
)(ChassisEmptyPole)