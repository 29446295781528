import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { RegisterSide } from '../../components/registerSide/RegisterSide'
import { InputwholesalersLookUp } from '../../components/inputwholesalersLookUp/InputwholesalersLookUp'
import { apiRegisterUser } from '../../utils/apiCalls'
import analyticsDataLayer from '../../services/AnalyticsDataLayer'
import ReCAPTCHA from 'react-google-recaptcha'

const { CONST } = require('../../CONST')

const SECTIONS = {
    profileSection: 0,
    businessSection: 1,
    wholesalersSection: 2,
    thankYouSection: 3
}

const recaptchaRef = React.createRef()
const recaptchaRefElectrician = React.createRef()

export class Registration extends Component {
    constructor(props) {
        super(props)

        this.state = {
            queryParams: {},
            isLoading: false,
            isFinalStep: false,
            currentSection: SECTIONS.profileSection
        }
    }

    componentDidMount() {
      const urlParams = new URLSearchParams(window.location.search)
      const queryParams = {}
      for (const [key, value] of urlParams.entries()) {
        queryParams[key] = value
      }
      this.setState({ queryParams })
    }

    validateForm = (formId) => {
        const form = document.getElementById(formId)
        if (form.dataset.submitIntent === 'false') return false

        const formElements = form.elements
        let formIsValid = true

        for (let i = 0; i < formElements.length; i++) {
            const element = formElements[i]
            if (!["INPUT", "SELECT"].includes(element.nodeName)) continue

            const elementId = element.id
            const elementLabel = document.querySelector(`label[for="${elementId}"]`)?.textContent || 'Field'
            const elementValue = element.value
            const elementError = document.querySelector(`[data-error-for="${elementId}"]`)

            if (element.type === 'checkbox') {
                formIsValid = element.required ? element.checked : formIsValid
            } else {
                if (element.required && elementValue.trim() === '') {
                    elementError.textContent = elementLabel + ' is required'
                    formIsValid = false
                } else if (element.type === 'email' && !this.validateEmail(elementValue)) {
                    elementError.textContent = 'Please enter a valid email address'
                    formIsValid = false
                } else if (element.type === 'tel' && element.dataset?.postal === 'true' && !this.validatePostalCode(elementValue)) {
                    elementError.textContent = 'Please enter a valid postal code'
                    formIsValid = false
                } else if (element.type === 'tel' && ! element.dataset?.postal && !this.validatePhone(elementValue)) {
                    elementError.textContent = 'Please enter a valid phone number'
                    formIsValid = false
                } else {
                    elementError.textContent = ''
                }
            }

            elementError.style.display = !formIsValid ? 'block' : 'none'
        }

        return formIsValid
    }

    validateEmail = (email) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
        return emailRegex.test(email)
    }

    validatePhone = (phone) => {
        const phoneRegex = /^\d{7,20}$/
        return phoneRegex.test(phone)
    }

    validatePostalCode = (postalCode) => {
        const postalCodeRegex = /^\d{2,4}$/
        return postalCodeRegex.test(postalCode)
    }
    
    submitProfileForm = (event) => {
        event.preventDefault()
        event.target.dataset.submitIntent = 'true'
        const isValid = this.validateForm('profileForm')

        if (!isValid) return

        if (this.state.queryParams?.type !== 'Electrician') {
            this.setState({ isFinalStep: true })
        }

        if (this.state.queryParams?.type === 'Electrician') {
            analyticsDataLayer.registrationStep(2, 3)
        } else {
            analyticsDataLayer.registrationStep(2, 2)
        }
        
        analyticsDataLayer.ctaButtonClick({
            cta_name: 'Next Step',
            cta_link: '',
            cta_location: 'Registration'
        })

        this.setState({ currentSection: SECTIONS.businessSection })
    }
    
    submitBusinessForm = (event) => {
        event.preventDefault()
        event.target.dataset.submitIntent = 'true'
        const isValid = this.validateForm('businessForm')

        if (!isValid) return

        if (this.state.queryParams?.type === 'Electrician') {
            analyticsDataLayer.registrationStep(3, 3)
            analyticsDataLayer.ctaButtonClick({
                cta_name: 'Next Step',
                cta_link: '',
                cta_location: 'Registration'
            })

            this.setState({ currentSection: SECTIONS.wholesalersSection })
        } else {
            analyticsDataLayer.ctaButtonClick({
                cta_name: 'Register',
                cta_link: '',
                cta_location: 'Registration'
            })

            const recaptchaValue = recaptchaRef.current.getValue()
            if (!recaptchaValue) {
                window.alert('Please complete the reCAPTCHA challenge')
                return
            }

            this.registerUser(recaptchaValue)
        }
    }
    
    submitWholesalersForm = (event) => {
        event.preventDefault()
        event.target.dataset.submitIntent = 'true'
        const isValid = this.validateForm('wholesalersForm')

        if (!isValid) return

        const recaptchaValue = recaptchaRefElectrician.current.getValue()
        if (!recaptchaValue) {
            window.alert('Please complete the reCAPTCHA challenge')
            return
        }

        this.registerUser(recaptchaValue)
    }

    registerUser = (recaptchaValue) => {
        this.setState({ isLoading: true })
        let registerRequest = {
            "firstName": '',
            "lastName": '',
            "email": '',
            "phoneNumber": '',
            "businessName": '',
            "businessPhoneNumber": '',
            "businessWebsite": '',
            "accountId": '',
            "businessPostalAddress": '',
            "businessPostalPostCode": '',
            "businessPostalCity": '',
            "businessPostalState": '',
            "userType": '',
            "branchId": 0,
            "preferredWholesaler1Id": 0,
            "preferredWholesaler2Id": 0,
            "preferredWholesaler3Id": 0,
            "userSubscriptionIndustryNews": false,
            "businessAbnacnnumber": '',
            "isBusinessABNACNMatchingWholesaler": false,
            "acceptedTermsAndConditions": false,
            "UseRecaptchaResponse": recaptchaValue
          }

        // Read data from the form and populate the registerRequest object
        registerRequest.firstName = document.getElementById('register-first-name').value
        registerRequest.lastName = document.getElementById('register-last-name').value
        registerRequest.email = document.getElementById('register-email').value
        registerRequest.phoneNumber = document.getElementById('register-phone').value
        registerRequest.businessName = document.getElementById('register-business-name').value
        registerRequest.businessPhoneNumber = document.getElementById('register-business-phone').value
        registerRequest.businessWebsite = document.getElementById('register-business-website').value
        registerRequest.accountId = document.getElementById('register-business-account-number')?.value || ''
        registerRequest.businessPostalAddress = document.getElementById('register-business-address').value
        registerRequest.businessPostalPostCode = document.getElementById('register-business-postal-code').value
        registerRequest.businessPostalCity = document.getElementById('register-business-suburb').value
        registerRequest.businessPostalState = document.getElementById('register-business-state').value
        registerRequest.userType = this.state.queryParams?.type
        registerRequest.branchId = 0

        const preferredWholesaler1Id = document.getElementById('register-wholesaler-1').value
        const preferredWholesaler2Id = document.getElementById('register-wholesaler-2').value
        const preferredWholesaler3Id = document.getElementById('register-wholesaler-3').value

        if (preferredWholesaler1Id) registerRequest.preferredWholesaler1Id = parseInt(preferredWholesaler1Id)
        if (preferredWholesaler2Id) registerRequest.preferredWholesaler2Id = parseInt(preferredWholesaler2Id)
        if (preferredWholesaler3Id) registerRequest.preferredWholesaler3Id = parseInt(preferredWholesaler3Id)

        registerRequest.userSubscriptionIndustryNews = document.getElementById('keep-up-to-date').checked
        registerRequest.businessAbnacnnumber = document.getElementById('register-business-ABN-ACN')?.value || ''
        registerRequest.isBusinessABNACNMatchingWholesaler = false
        registerRequest.acceptedTermsAndConditions = document.getElementById('register-terms').checked

        apiRegisterUser(registerRequest)
            .then(() => {
                analyticsDataLayer.registrationComplete()
                this.setState({ currentSection: SECTIONS.thankYouSection })
            })
            .catch(error => {
                window.alert(`Registration failed: ${typeof error === 'object' ? error.message : error}`)
            })
            .finally(() => {
                this.setState({ isLoading: false })
            })
    }

    render() {
        return (
            <div className="app">

                <div className="app__body app__body--register">

                <RegisterSide />

                <div className="app__register-content">
                    {/* <!-- Step 1 --> */}
                    <div id="profileSection" hidden={this.state.currentSection !== SECTIONS.profileSection} className="register">
                        <div className="register__header register__header--step">
                            <Link type="button" className="button button--back" to="/home">
                                <span className="button__text">
                                    Back
                                </span>
                            </Link>
                            <h1 className="h3 register__header-title">
                                Let’s grab some details
                            </h1>
                            <div className="steps">
                                <div className="step is-active">
                                    <span className="step__number">1</span>
                                    <span className="step__text">Profile</span>
                                </div>
                                <div className="step">
                                    <span className="step__number">2</span>
                                    <span className="step__text">Business Details</span>
                                </div>
                                {/* <!-- Step 3 (If Large contractors is selected at start) --> */}
                                {this.state.queryParams?.type === 'Electrician' && (
                                    <div className="step">
                                        <span className="step__number">3</span>
                                        <span className="step__text">Select Wholesalers</span>
                                    </div>  
                                )}
                            </div>
                        </div>
                        <div className="register__body">
                            <div className="register__body-content">
                                <form id="profileForm" className="form" data-submit-intent="false" onSubmit={this.submitProfileForm} noValidate>
                                    <p>
                                        Please note to gain access to the tool your account will require approval. This can take up to 2 business days.
                                    </p>
                                    <div className="form__section">
                                        <h4 className="h5">
                                            Your details
                                        </h4>
                                        <div className="row">
                                            <div className="col col-6">
                                                <div className="form__group form__group--native-validation">
                                                    <input id="register-first-name" className="form__input input" type="text" maxLength="150" required
                                                        onBlur={() => this.validateForm('profileForm') } />
                                                    <label className='form__label p--sm' htmlFor="register-first-name">First Name</label>
                                                    <span data-error-for="register-first-name" className='form__group-error'></span>
                                                </div>
                                            </div>
                                            <div className="col col-6">
                                                <div className="form__group form__group--native-validation">
                                                    <input id="register-last-name" className="form__input input" type="text" maxLength="150" required
                                                        onBlur={() => this.validateForm('profileForm') } />
                                                    <label className='form__label p--sm' htmlFor="register-last-name">Last Name</label>
                                                    <span data-error-for="register-last-name" className='form__group-error'></span>
                                                </div>
                                            </div>
                                            <div className="col col-6">
                                                <div className="form__group form__group--native-validation">
                                                    <input id="register-email" className="form__input input" type="email" maxLength="255" required
                                                        onBlur={() => this.validateForm('profileForm') } />
                                                    <label className='form__label p--sm' htmlFor="register-email">Email Address</label>
                                                    <span data-error-for="register-email" className='form__group-error'></span>
                                                </div>
                                            </div>
                                            <div className="col col-6">
                                                <div className="form__group form__group--native-validation">
                                                    <input id="register-phone" className="form__input input" type="tel" pattern="\d{7,20}" maxLength="20" required
                                                        onBlur={() => this.validateForm('profileForm') } />
                                                    <label className='form__label p--sm' htmlFor="register-phone">Mobile Number</label>
                                                    <span data-error-for="register-phone" className='form__group-error'></span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    
                                    <div className="form__actions">
                                        <button type="submit" className="button button--1">
                                            <span className="button__text">
                                                Next Step
                                            </span>
                                            <span className="button__icon">
                                                <span className="i-directional-right"></span>
                                            </span>
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>

                    {/* <!-- Step 2 --> */}
                    <div id="businessSection" hidden={this.state.currentSection !== SECTIONS.businessSection} className="register">
                        <div className="register__header register__header--step">
                            <button type="button" className="button button--back"
                                onClick={() => { this.setState({ currentSection: SECTIONS.profileSection }) }}
                            >
                                <span className="button__text">
                                    Back
                                </span>
                            </button>
                            
                            <h1 className="h3 register__header-title">
                                Tell us about your business
                            </h1>
                            <div className="steps">
                                <div className="step is-done">
                                    <span className="step__number">1</span>
                                    <span className="step__text">Profile</span>
                                </div>
                                <div className="step is-active">
                                    <span className="step__number">2</span>
                                    <span className="step__text">Business Details</span>
                                </div>
                                {/* <!-- Step 3 (If Large contractors is selected at start) --> */}
                                {this.state.queryParams?.type === 'Electrician' && (
                                    <div className="step">
                                        <span className="step__number">3</span>
                                        <span className="step__text">Select Wholesalers</span>
                                    </div>  
                                )}
                            </div>
                        </div>
                        <div className="register__body">
                            <div className="register__body-content">
                                <form id="businessForm" className="form" data-submit-intent="false" onSubmit={this.submitBusinessForm} noValidate>
                                    <h4 className="h5">
                                        Business Details
                                    </h4>
                                    
                                    <div className="row">
                                        <div className="col col-6">
                                            <div className="form__group form__group--native-validation">
                                                <input id="register-business-name" className="form__input input" type="text" maxLength="1000" required
                                                    onBlur={() => this.validateForm('businessForm') } />
                                                <label className='form__label p--sm' htmlFor="register-business-name">Business Name</label>
                                                <span data-error-for="register-business-name" className='form__group-error'></span>
                                            </div>
                                        </div>
                                        {/* <!-- If No Contractors then render below --> */}
                                        {this.state.queryParams?.type !== 'Electrician' && (
                                            <div className="col col-6">
                                                <div className="form__group form__group--native-validation">
                                                    <input id="register-business-account-number" className="form__input input" type="text" maxLength="25" autoComplete="off"
                                                    onBlur={() => this.validateForm('businessForm') } />
                                                    <label className='form__label p--sm' htmlFor="register-business-account-number">Account ID <span className="u_mute-text">(Optional)</span></label>
                                                    <span data-error-for="register-business-account-number" className='form__group-error'></span>
                                                </div>
                                            </div>
                                        )}
                                        {this.state.queryParams?.type === 'Electrician' && (
                                            <div className="col col-6">
                                                <div className="form__group form__group--native-validation">
                                                    <input id="register-business-ABN-ACN" className="form__input input" type="text" maxLength="11" required
                                                    onBlur={() => this.validateForm('businessForm') } />
                                                    <label className='form__label p--sm' htmlFor="register-business-ABN-ACN">ABN/ACN</label>
                                                    <span data-error-for="register-business-ABN-ACN" className='form__group-error'></span>
                                                </div>
                                            </div>
                                        )}
                                        <div className="col col-6">
                                            <div className="form__group form__group--native-validation">
                                                <input id="register-business-phone" className="form__input input" type="tel" pattern="\d{7,20}" required
                                                    onBlur={() => this.validateForm('businessForm') } />
                                                <label className='form__label p--sm' htmlFor="register-business-phone">Phone</label>
                                                <span data-error-for="register-business-phone" className='form__group-error'></span>
                                            </div>
                                        </div>
                                        <div className="col col-6">
                                            <div className="form__group form__group--native-validation">
                                                <input id="register-business-website" placeholder="https://" className="form__input input" type="text"
                                                    onBlur={() => this.validateForm('businessForm') } />
                                                <label className='form__label p--sm' htmlFor="register-business-website">Website <span className="u_mute-text">(Optional)</span></label>
                                                <span data-error-for="register-business-website" className='form__group-error'></span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col col-12">
                                            <div className="form__group form__group--native-validation">
                                                <input id="register-business-address" className="form__input input" type="text" maxLength="1000" required
                                                    onBlur={() => this.validateForm('businessForm') } />
                                                <label className='form__label p--sm' htmlFor="register-business-address">Address</label>
                                                <span data-error-for="register-business-address" className='form__group-error'></span>
                                            </div>
                                        </div>

                                        <div className="col col--sm">
                                            <div className="form__group form__group--native-validation">
                                                <input id="register-business-postal-code" className="form__input input" type="tel" data-postal maxLength="4" required
                                                    onBlur={() => this.validateForm('businessForm') } />
                                                <label className='form__label p--sm' htmlFor="register-business-postal-code">Postal Code</label>
                                                <span data-error-for="register-business-postal-code" className='form__group-error'></span>
                                            </div>
                                        </div>
                                        <div className="col col--md">
                                            <div className="form__group form__group--native-validation">
                                                <input id="register-business-suburb" className="form__input input" type="text" maxLength="255" required
                                                    onBlur={() => this.validateForm('businessForm') } />
                                                <label className='form__label p--sm' htmlFor="register-business-suburb">Suburb</label>
                                                <span data-error-for="register-business-suburb" className='form__group-error'></span>
                                            </div>
                                        </div>
                                        <div className="col col--md">
                                            <div className="form__group form__group--native-validation">
                                                <select id="register-business-state" className="form__select select--custom" name="state" required
                                                    onBlur={() => this.validateForm('businessForm') } >
                                                    <option value="" hidden>Please select</option>
                                                    <option value="ACT">ACT</option>
                                                    <option value="NSW">NSW</option>
                                                    <option value="NT">NT</option>
                                                    <option value="QLD">QLD</option>
                                                    <option value="SA">SA</option>
                                                    <option value="TAS">TAS</option>
                                                    <option value="VIC">VIC</option>
                                                    <option value="WA">WA</option>
                                                </select>
                                                <label className='form__label p--sm' htmlFor="register-business-state">State</label>
                                                <span data-error-for="register-business-state" className='form__group-error'></span>
                                            </div>
                                        </div>  
                                    </div>

                                    {this.state.queryParams?.type !== 'Electrician' && (
                                        <div className="form__group form__group--native-validation">
                                            <div className="form__control checkbox">
                                                <input id="register-terms" type="checkbox" name="Register Terms" className="form__control-input" required
                                                    onBlur={() => this.validateForm('businessForm') } />
                                                <label htmlFor="register-terms" className="form__control-label">
                                                    I have read and agree to the <a href="https://www.se.com/au/en/about-us/legal/privacy-policy.jsp" target="_blank" rel="nofollow noopener">Privacy Policy</a> &amp; <a href="https://flexselect.schneider-electric.com/eoi/flexselecttermsofuse.aspx" target="_blank" rel="nofollow noopener">Terms of Use</a>
                                                    <br /><br />
                                                    <span style={{ fontSize: '.8em'}}>By checking the above box you agree to the disclosure of your Personal Information to your nominated electrical wholesaler for the sole purpose of preparing the quote for the products and services you are purchasing through the FlexSelect tool. </span>
                                                </label>
                                                <span data-error-for="register-terms" className='form__group-error'>Please confirm that you have read our Privacy Policy & Terms and Conditions.</span>
                                            </div>
                                            <div className="form__control checkbox">
                                                <input id="keep-up-to-date" type="checkbox" name="Keep up to date" className="form__control-input"
                                                    onBlur={() => this.validateForm('businessForm') } />
                                                <label htmlFor="keep-up-to-date" className="form__control-label">Keep me updated on Industry News & Updates, Events and Trade shows</label>
                                                <span data-error-for="keep-up-to-date" className='form__group-error'></span>
                                            </div>
                                            <div className="form__control re-captcha">
                                                <ReCAPTCHA
                                                    ref={recaptchaRef}
                                                    sitekey={CONST.RECAPTCH_SITE_KEY}
                                                />
                                            </div>
                                        </div>
                                    )}
                                    
                                    {/* <!-- ---------- --> */}
                                    
                                    <div className="form__actions">
                                        <button type='submit' className="button button--1" disabled={this.state.isLoading}>
                                            <span className="button__text">
                                                {this.state.isFinalStep ? this.state.isLoading ? 'Loading' : 'Register' : 'Next Step'}
                                            </span>
                                            <span className="button__icon">
                                                <span className="i-directional-right"></span>
                                            </span>
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>

                    {/* <!-- Step 3 (If contractors is selected at start) --> */}
                    <div id="wholesalersSection" hidden={this.state.currentSection !== SECTIONS.wholesalersSection} className="register">
                        <div className="register__header register__header--step">
                            <button type="button" className="button button--back"
                                onClick={() => { this.setState({ currentSection: SECTIONS.businessSection }) }}
                            >
                            <span className="button__text">
                                    Back
                                </span>
                            </button>
                            <h1 className="h3 register__header-title">
                                Select your wholesalers
                            </h1>
                            <div className="steps">
                                <div className="step is-done">
                                    <span className="step__number">1</span>
                                    <span className="step__text">Profile</span>
                                </div>
                                <div className="step is-done">
                                    <span className="step__number">2</span>
                                    <span className="step__text">Business Details</span>
                                </div>
                                <div className="step is-active">
                                    <span className="step__number">3</span>
                                    <span className="step__text">Select Wholesalers</span>
                                </div>
                            </div>
                        </div>
                        <div className="register__body">
                            <div className="register__body-content">
                                <form id="wholesalersForm" className="form" data-submit-intent="false" onSubmit={this.submitWholesalersForm} noValidate>
                                    <h4 className="h5">
                                        Wholesalers available
                                    </h4>
                                    
                                    <div className="form__group form__group--native-validation">
                                        <InputwholesalersLookUp id="register-wholesaler-1" label="1st Wholesaler" placeholder="Select your first wholesaler" required />
                                        <span data-error-for="register-wholesaler-1" className='form__group-error'></span>
                                    </div>
                                    
                                    <div className="form__group form__group--native-validation">
                                        <InputwholesalersLookUp id="register-wholesaler-2" label="2nd Wholesaler" placeholder="Select your second wholesaler (Optional)" />
                                        <span data-error-for="register-wholesaler-2" className='form__group-error'></span>
                                    </div>
                                    
                                    <div className="form__group form__group--native-validation">
                                        <InputwholesalersLookUp id="register-wholesaler-3" label="3rd Wholesaler" placeholder="Select your third wholesaler (Optional)" />
                                        <span data-error-for="register-wholesaler-3" className='form__group-error'></span>
                                    </div>
                                
                                    <div className="form__group form__group--native-validation">
                                        <div className="form__control checkbox">
                                            <input id="register-terms" type="checkbox" name="Register Terms" className="form__control-input" required
                                            onBlur={() => this.validateForm('wholesalersForm') } />
                                            <label htmlFor="register-terms" className="form__control-label">
                                                I have read and agree to the <a href="https://www.se.com/au/en/about-us/legal/privacy-policy.jsp" target="_blank" rel="nofollow noopener">Privacy Policy</a> &amp; <a href="https://flexselect.schneider-electric.com/eoi/flexselecttermsofuse.aspx" target="_blank" rel="nofollow noopener">Terms of Use</a>
                                                <br /><br />
                                                <span style={{ fontSize: '.8em'}}>By checking the above box you agree to the disclosure of your Personal Information to your nominated electrical wholesaler for the sole purpose of preparing the quote for the products and services you are purchasing through the FlexSelect tool. </span>
                                            </label>
                                            <span data-error-for="register-terms" className='form__group-error'>Please confirm that you have read our Privacy Policy & Terms and Conditions.</span>
                                        </div>
                                        <div className="form__control checkbox">
                                            <input id="keep-up-to-date" type="checkbox" name="Keep up to date" className="form__control-input"
                                            onBlur={() => this.validateForm('wholesalersForm') } />
                                            <label htmlFor="keep-up-to-date" className="form__control-label">Keep me updated on Industry News & Updates, Events and Trade shows</label>
                                            <span data-error-for="keep-up-to-date" className='form__group-error'></span>
                                        </div>
                                        <div className="form__control re-captcha">
                                            <ReCAPTCHA
                                                ref={recaptchaRefElectrician}
                                                sitekey={CONST.RECAPTCH_SITE_KEY}
                                            />
                                        </div>
                                    </div>

                                    <div className="form__actions">
                                        <button type="submit" className="button button--1">
                                            <span className="button__text">
                                                Register
                                            </span>
                                            <span className="button__icon">
                                                <span className="i-directional-right"></span>
                                            </span>
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>

                    {/* <!-- Thanks for registering --> */}
                    <div id="thankYouSection" hidden={this.state.currentSection !== SECTIONS.thankYouSection} className="register">
                        <div className="register__body">
                            <div className="register__success">
                                <div className="register__success-container">
                                    <h3 className="h3 register__success-title">
                                        <span>Thanks for Registering</span>
                                        <span className="i-check-circle"></span>
                                    </h3>

                                    <p className="register__success-lead h5">
                                        Your registration is awaiting approval.
                                    </p>

                                    <p className="register__success-text h5">
                                        We need to approve your account before you will have access. This can take up to two business days.
                                    </p>
                                    
                                    <Link type="button" className="button button--back" to="/home">
                                        <span className="button__text">
                                            Back to Home
                                        </span>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                </div>
            </div>
        )
    }
}
