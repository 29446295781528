import React from 'react'
import ReactDOM from 'react-dom'
import './resources/scss/main.scss'
import App from './App'
import { BrowserRouter } from 'react-router-dom'
import reportWebVitals from './reportWebVitals'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import CookieSetting from './components/cookie-settings/cookieSetting'

const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href')
const rootElement = document.getElementById('root')
ReactDOM.render(
  <DndProvider backend={ HTML5Backend }>
    <BrowserRouter basename={baseUrl}>
      <React.StrictMode>
        <App />
        <CookieSetting />
      </React.StrictMode>
    </BrowserRouter>
  </DndProvider>,
  rootElement
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
