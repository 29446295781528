import { getNumberOfRowsFromPolesString, getPolesCountFromPolesString } from './../utils/helpers'

class BoardService {
    getDetails = (boardOptionData, EnclosureID, MainSwitchID, SPDID, MetalBlackPlateID) => {
        const enclosureDetails = this.getBoardComponentDetails(boardOptionData, EnclosureID, 'Enclosures')
        const metalBlackPlateDetails = this.getBoardComponentDetails(boardOptionData, MetalBlackPlateID, 'Enclosures')
        const mainSwitchDetails = this.getBoardComponentDetails(boardOptionData, MainSwitchID, 'MainSwitches')
        const SDPDetails = this.getBoardComponentDetails(boardOptionData, SPDID, 'SDPs')

        const boardDetails = {
            EnclosureType: enclosureDetails.EnclosureType,
            PolesNeeded: enclosureDetails.PolesNeeded,
            MetalBlackPlate: metalBlackPlateDetails !== undefined ? true : null, 
            IncomerPhase: mainSwitchDetails.IncomerPhase,
            MainSwitchType: mainSwitchDetails.SwitchType,
            CircuitBreaker: mainSwitchDetails.Rating,
            MainSwitchRating: mainSwitchDetails.CurrentRating,
            SurgeProtection: SDPDetails !== undefined ? true : false,
            SurgeProtectionDetails: SDPDetails !== undefined ? SDPDetails : null,
            MaxDischargeCapacity: SDPDetails !== undefined ? SDPDetails.MaxDischargeCapacity : ''
        }
        return boardDetails
    }

    getBoardComponentDetails = (boardOptionData, itemID, componentsType) => {
        return boardOptionData[componentsType].find(boardOption => boardOption.ItemID === itemID)
    }

    getBoardCompletionDetails = (boardData, boardDetails) => {
        const numberOfRows = getNumberOfRowsFromPolesString(boardDetails.PolesNeeded)
        const numberOfPoles = getPolesCountFromPolesString(boardDetails.PolesNeeded)
        const totalNumberOfBoardPoles = numberOfRows * numberOfPoles
        let totalNumberOfOccupiedPoles = 0
        let completionPercentage = 0
        // User needs at least 4 breakers to see a quote, previously the board needed to be filled at least to 30%
        // Not all devices count towards the completion of the board
        // Initialize at 1 as we always have the main switch and it always count toward the completion 
        let numberOfDevicesToAllowSeeingTheQuote = 1 
        let additionalPolesOccupied = this.getMainSwitchIncomingPhasesPoles(boardDetails.IncomerPhase) // there is always a main switch
        if (boardData.SPDID !== null && boardData.SPDID !== 0) {
            additionalPolesOccupied = additionalPolesOccupied + 1
            numberOfDevicesToAllowSeeingTheQuote += 1
        }

        // Consider breakers
        boardData.ProjectBoardBreakerCollection.forEach(breaker => {
            const spaceUsed = parseInt(breaker.BreakerTypeReadOnly.SpaceUsed, 10)
            totalNumberOfOccupiedPoles = totalNumberOfOccupiedPoles + spaceUsed
            numberOfDevicesToAllowSeeingTheQuote += 1
        })

        // Consider contactors
        boardData.ProjectBoardContractorCollection.forEach(contactor => {
            const spaceUsed = parseInt(contactor.ContractorReadOnly.SpaceUsed, 10)
            totalNumberOfOccupiedPoles = totalNumberOfOccupiedPoles + spaceUsed
            numberOfDevicesToAllowSeeingTheQuote += 1
        })

        // Consider fitted options
        boardData.ProjectBoardFittedOptionCollection.forEach(contactor => {
            const spaceUsed = parseInt(contactor.FittedOptionReadOnly.SpaceUsed, 10)
            totalNumberOfOccupiedPoles = totalNumberOfOccupiedPoles + spaceUsed
            numberOfDevicesToAllowSeeingTheQuote += 1
        })

        // Consider the Wiser Energy Gateway
        if (boardData.HasWiserEnergyGateway) {
            totalNumberOfOccupiedPoles = totalNumberOfOccupiedPoles + 3
            numberOfDevicesToAllowSeeingTheQuote += 1
        }

        totalNumberOfOccupiedPoles = totalNumberOfOccupiedPoles + additionalPolesOccupied
        completionPercentage = totalNumberOfOccupiedPoles * 100 / totalNumberOfBoardPoles

        return {
            totalPoles: totalNumberOfBoardPoles,
            totalPolesConfigured: totalNumberOfOccupiedPoles,
            totalPolesRemaining: totalNumberOfBoardPoles - totalNumberOfOccupiedPoles,
            completionPercentage: completionPercentage,
            isEnoughCompletedToSeeTheQuote: numberOfDevicesToAllowSeeingTheQuote >= 4
        }
    }

    // Given a string value of the incoming phases for the main switch return number of poles that it occupies on the board
    getMainSwitchIncomingPhasesPoles(incomerPhaseName) {
        if (incomerPhaseName === 'Single Phase') return 1
        else if (incomerPhaseName === '2 Phase') return 2
        else if (incomerPhaseName === '3 Phase') return 3
    }

    // In case of 1 phase incomer and 20kA Max discharge capacity: None MCB is required as Schneirder doesn’t publish anything in their instructions
    getSurgeProtectionRequiredMCBReference(incomerPhase, maxDischargeCapacity) {
        let MCBReference = null

        if (incomerPhase === 'Single Phase' && maxDischargeCapacity === '40kA') MCBReference = 'MX9MC140'
        if (incomerPhase === '3 Phase' && maxDischargeCapacity === '40kA') MCBReference = 'MX9MC340'

        return MCBReference
    }
}

const boardService = new BoardService()
export default boardService
  