import React, { Component } from 'react'
import Header from '../../components/header/Header'
import { InputwholesalersLookUp } from '../../components/inputwholesalersLookUp/InputwholesalersLookUp'
import { apiUpdateProfile } from '../../utils/apiCalls'
import { apiCallsCommercial } from '../../utils/apiCallsCommercial'

export class UserProfile extends Component {
    constructor(props) {
        super(props)

        this.state = {
            userSettings: {},
            hasCompletedLoading: false,
            preferredWholesalerData: null,
            isLoading: false,
            hasUpdatedUserProfile: false
        }

        apiCallsCommercial.apiUserSettings()
            .then((userDataObj) => {
                this.setState({
                    userSettings: userDataObj,
                    hasCompletedLoading: true
                })
            })
    }

    getExistingWholesalerData = (wholesalerID) => {
        if (!wholesalerID || !this.state.userSettings.PreferredWholesalers) return null

        const foundWholesaler = this.state.userSettings.PreferredWholesalers.find(wholesaler => wholesaler.WholesalerBranchID === wholesalerID)
        return foundWholesaler
    }

    validateEmail = (email) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
        return emailRegex.test(email)
    }

    validatePhone = (phone) => {
        const phoneRegex = /^\d{7,20}$/
        return phoneRegex.test(phone)
    }

    validatePostalCode = (postalCode) => {
        const postalCodeRegex = /^\d{2,4}$/
        return postalCodeRegex.test(postalCode)
    }

    validateForm = (formId) => {
        const form = document.getElementById(formId)
        if (form.dataset.submitIntent === 'false') return false

        const formElements = form.elements
        let formIsValid = true

        for (let i = 0; i < formElements.length; i++) {
            const element = formElements[i]
            if (!['INPUT', 'SELECT'].includes(element.nodeName)) continue
            if (!['profile-email', 'fax-mobile-number'].includes(element.id)) {
                const elementId = element.id
                const elementLabel = document.querySelector(`label[for="${elementId}"]`)?.textContent || 'Field'
                const elementValue = element.value
                const elementError = document.querySelector(`[data-error-for='${elementId}']`)
                if (element.required && elementValue.trim() === '') {
                    elementError.textContent = elementLabel + ' is required'
                    formIsValid = false
                } else if (element.type === 'email' && !this.validateEmail(elementValue)) {
                    elementError.textContent = 'Please enter a valid email address'
                    formIsValid = false
                } else if (element.type === 'tel' && element.dataset?.postal === 'true' && !this.validatePostalCode(elementValue)) {
                    elementError.textContent = 'Please enter a valid postal code'
                    formIsValid = false
                } else if (element.type === 'tel' && !element.dataset?.postal && !this.validatePhone(elementValue)) {
                    elementError.textContent = 'Please enter a valid phone number'
                    formIsValid = false
                } else {
                    elementError.textContent = ''
                }
                elementError.style.display = !formIsValid ? 'block' : 'none'
            }
        }
        return formIsValid
    }

    submitProfileForm = (event) => {
        event.preventDefault()
        event.target.dataset.submitIntent = 'true'
        const isValid = this.validateForm('profileForm')

        if (!isValid) return

        this.updateUser()
    }

    updateUser = () => {
        let data = {
            'userID': 0,
            'userFirstName': '',
            'userLastName': '',
            'userEmail': '',
            'userMobile': '',
            'userPostalAddress': '',
            'userPostcode': '',
            'userCity': '',
            'userState': '',
            'branchId': 0,
            'preferredWholesaler1Id': 0,
            'preferredWholesaler2Id': 0,
            'preferredWholesaler3Id': 0
        }

        data.userID = this.state.userSettings.UserID
        data.userFirstName = document.getElementById('profile-first-name').value
        data.userLastName = document.getElementById('profile-last-name').value
        data.userEmail = document.getElementById('profile-email').value
        data.userMobile = document.getElementById('profile-mobile-number').value
        data.userPostalAddress = document.getElementById('profile-shipping-address').value
        data.userPostcode = document.getElementById('profile-postal-code').value
        data.userCity = document.getElementById('profile-city').value
        data.userState = document.getElementById('profile-state').value
        data.branchId = this.state.userSettings.UserWholesalerBranchID

        const preferredWholesaler1Input = document.getElementById('register-wholesaler-1')
        const preferredWholesaler2Input = document.getElementById('register-wholesaler-2')
        const preferredWholesaler3Input = document.getElementById('register-wholesaler-3')

        if (preferredWholesaler1Input) {
            if (preferredWholesaler1Input.value !== '') {
                data.preferredWholesaler1Id = parseInt(preferredWholesaler1Input.value)
            } else {
                data.preferredWholesaler1Id = 0
            }
        }

        if (preferredWholesaler2Input) {
            if (preferredWholesaler2Input.value !== '') {
                data.preferredWholesaler2Id = parseInt(preferredWholesaler2Input.value)
            }
        }

        if (preferredWholesaler3Input) {
            if (preferredWholesaler3Input.value !== '') {
                data.preferredWholesaler3Id = parseInt(preferredWholesaler3Input.value)
            }
        }
        this.setState({ isLoading: true })
        apiUpdateProfile(data)
            .then(response => {
                this.setState({ hasUpdatedUserProfile: true })
                console.log('Update profile successful', response)
            })
            .catch(error => {
                window.alert(`Update profile failed: ${error.message}`)
            })
            .finally(() => {
                this.setState({ isLoading: false })
            })
    }

    render() {
        return (
            <div className="app">
                <Header isCommercial={true}>
                    <div className="header__section hs_project-start">
                        <h2 className="h2 header__title">
                            My Profile
                        </h2>
                    </div>
                </Header>
                <div className="app__body app__body--subpage">
                    <div className="app__body-content">
                        {
                            this.state.hasUpdatedUserProfile &&
                            <div className="subpage-content">
                                <div class="register">
                                    <div class="register__body">
                                        <div class="register__success">
                                            <div class="register__success-container">
                                                <h3 class="h3 register__success-title">
                                                    <span>Changes were saved</span>
                                                    <span class="i-check-circle"></span>
                                                </h3>
                                                <p class="register__success-lead h5">
                                                    Your profile is updated.
                                                </p>
                                                <p class="register__success-text h5">
                                                    Your changes have been saved to your account.
                                                </p>
                                                <a href="/flex/" type="button" class="button button--back">
                                                    <span class="button__text">
                                                        Back to Home
                                                    </span>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }

                        { !this.state.hasUpdatedUserProfile &&
                            <div className="subpage-content">
                                <form className="form subpage-content__width" id="profileForm" data-submit-intent="false" onSubmit={this.submitProfileForm} noValidate>

                                    <div className="form__section">
                                        <h4 className="h5">
                                            Your details
                                        </h4>
                                        <div className="row">
                                            <div className="col col-6">
                                                <div className="form__group form__group--native-validation">
                                                    <input 
                                                        id="profile-email" 
                                                        className="form__input input" 
                                                        type="email" 
                                                        readOnly 
                                                        disabled 
                                                        defaultValue={this.state.userSettings.UserEmail} 
                                                    />
                                                    <label className='form__label p--sm' for="profile-email">Email Address <span className="u_mute-text">(Non-editable)</span></label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col col-6">
                                                <div className="form__group form__group--native-validation">
                                                    <input 
                                                        id="profile-first-name"
                                                        className="form__input input"
                                                        type="text" 
                                                        required 
                                                        onBlur={() => this.validateForm('profileForm')}
                                                        defaultValue={this.state.userSettings.UserFirstName}
                                                    />
                                                    <label className='form__label p--sm' for="profile-first-name">First Name</label>
                                                    <span data-error-for="profile-first-name" className='form__group-error'></span>
                                                </div>
                                            </div>
                                            <div className="col col-6">
                                                <div className="form__group form__group--native-validation">
                                                    <input 
                                                        id="profile-last-name" 
                                                        className="form__input input" 
                                                        type="text" 
                                                        required 
                                                        onBlur={() => this.validateForm('profileForm')} 
                                                        defaultValue={this.state.userSettings.UserLastName}
                                                    />
                                                    <label className='form__label p--sm' for="profile-last-name">Last Name</label>
                                                    <span data-error-for="profile-last-name" className='form__group-error'></span>
                                                </div>
                                            </div>
                                            <div className="col col-6">
                                                <div className="form__group form__group--native-validation">
                                                    <input 
                                                        id="profile-mobile-number" 
                                                        className="form__input input" 
                                                        type="tel" 
                                                        pattern="\d{7,14}" 
                                                        required 
                                                        onBlur={() => this.validateForm('profileForm')}
                                                        defaultValue={this.state.userSettings.UserMobile} 
                                                    />
                                                    <label className='form__label p--sm' for="profile-mobile-number">Mobile Number</label>
                                                    <span data-error-for="profile-mobile-number" className='form__group-error'></span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form__section">
                                        <h4 className="h5">
                                            Shipping Address
                                        </h4>
                                        <div className="row">
                                            <div className="col col-12">
                                                <div className="form__group form__group--native-validation">
                                                    <input 
                                                        id="profile-shipping-address" 
                                                        className="form__input input" 
                                                        type="text" 
                                                        required 
                                                        onBlur={() => this.validateForm('profileForm')} 
                                                        defaultValue={this.state.userSettings.UserPostalAddress}
                                                    />
                                                    <label className='form__label p--sm' for="profile-shipping-address">Address</label>
                                                    <span data-error-for="profile-shipping-address" className='form__group-error'></span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col col--sm">
                                                <div className="form__group form__group--native-validation">
                                                    <input 
                                                        id="profile-postal-code" 
                                                        className="form__input input" 
                                                        type="number" 
                                                        pattern="\d{2,10}" 
                                                        required 
                                                        onBlur={() => this.validateForm('profileForm')} 
                                                        defaultValue={this.state.userSettings.UserPostcode}
                                                    />
                                                    <label className='form__label p--sm' for="profile-postal-code">Postal Code</label>
                                                    <span data-error-for="profile-postal-code" className='form__group-error'></span>
                                                </div>
                                            </div>
                                            <div className="col col--md">
                                                <div className="form__group form__group--native-validation">
                                                    <input 
                                                        id="profile-city" 
                                                        className="form__input input" 
                                                        type="text" 
                                                        required 
                                                        onBlur={() => this.validateForm('profileForm')} 
                                                        defaultValue={this.state.userSettings.UserCity}
                                                    />
                                                    <label className='form__label p--sm' for="profile-city">City</label>
                                                    <span data-error-for="profile-city" className='form__group-error'></span>
                                                </div>
                                            </div>
                                            <div className="col col--md">
                                                <div className="form__group form__group--native-validation">
                                                    <select 
                                                        className="form__select select--custom" 
                                                        id="profile-state"
                                                        required
                                                        onBlur={() => this.validateForm('profileForm')}
                                                        value={this.state.userSettings.UserState}
                                                        onChange={(event) => this.setState(
                                                            { userSettings: { 
                                                                ...this.state.userSettings, 
                                                                UserState: event.target.value
                                                            }}
                                                        )}
                                                    >
                                                        <option value="" hidden>Please Select</option>
                                                        <option value="ACT">ACT</option>
                                                        <option value="NSW">NSW</option>
                                                        <option value="NT">NT</option>
                                                        <option value="QLD">QLD</option>
                                                        <option value="SA">SA</option>
                                                        <option value="TAS">TAS</option>
                                                        <option value="VIC">VIC</option>
                                                        <option value="WA">WA</option>
                                                    </select>
                                                    <label className='form__label p--sm' for="profile-state">State</label>
                                                    <span data-error-for="profile-state" className='form__group-error'></span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    { this.state.userSettings.UserTypeString === 'Electrician' && this.state.hasCompletedLoading &&
                                        <div className="form__section">
                                            <h4 className="h5">
                                                Wholesalers available
                                            </h4>
                                            <div className="form__group form__group--native-validation">
                                                <InputwholesalersLookUp 
                                                    id="register-wholesaler-1" 
                                                    label="1st Wholesaler" 
                                                    placeholder="Select your first wholesaler"
                                                    existingWholesalerData={this.getExistingWholesalerData(this.state.userSettings.PreferredWholesaler1ItemID)}
                                                    required 
                                                />
                                                <span data-error-for="register-wholesaler-1" className='form__group-error'></span>
                                            </div>

                                            <div className="form__group form__group--native-validation">
                                                <InputwholesalersLookUp 
                                                    id="register-wholesaler-2" 
                                                    label="2nd Wholesaler" 
                                                    placeholder="Select your second wholesaler (Optional)"
                                                    existingWholesalerData={this.getExistingWholesalerData(this.state.userSettings.PreferredWholesaler2ItemID)}
                                                />
                                                <span data-error-for="register-wholesaler-2" className='form__group-error'></span>
                                            </div>

                                            <div className="form__group form__group--native-validation">
                                                <InputwholesalersLookUp 
                                                    id="register-wholesaler-3" 
                                                    label="3rd Wholesaler" 
                                                    placeholder="Select your third wholesaler (Optional)" 
                                                    existingWholesalerData={this.getExistingWholesalerData(this.state.userSettings.PreferredWholesaler3ItemID)}
                                                />
                                                <span data-error-for="register-wholesaler-3" className='form__group-error'></span>
                                            </div>
                                        </div>
                                    }

                                    <div className="form__actions">
                                        <button className={"button button--1 " + (this.state.isLoading ? 'disabled' : '')}>
                                            <span className="button__text">
                                                { this.state.isLoading ? 'Updating...' : 'Request Profile' }
                                            </span>
                                            <span className="button__icon">
                                                <span className="i-directional-right"></span>
                                            </span>
                                        </button>
                                    </div>

                                </form>
                            </div>
                        }
                    </div>
                </div>

            </div>
        )
    }
}