import Axios from 'axios'

const { CONST } = require('../CONST')

let SSS_SERVER = CONST.API_PATH_SSO_LOCALHOST
if (window.location.hostname !== 'localhost') {
    SSS_SERVER = window.location.origin
}

const apiGetLoginSsoUrl = `${SSS_SERVER}/api/authorization/login-sso-url`
const apiPostLoginSsoCallbackUrl = `${SSS_SERVER}/api/authorization/login-sso-callback`
const apiGetLogoutSsoUrl = `${SSS_SERVER}/api/authorization/logout-sso-url`
const apiRegister = `${SSS_SERVER}/api/user/register`
const updateProfile = `${SSS_SERVER}/api/user/update/`

export function apiGetLoginSSO() {
    return processRequest(
        Axios.get(apiGetLoginSsoUrl, {
            headers: {
                'currentSite': CONST.SSO_CURRENT_SITE,
                'api-key': CONST.SSO_API_KEY
            }
        }),
        'get login SSO URL'
    )
}

export function apiPostLoginSSOCallback(code) {
    return processRequest(
        Axios.post(apiPostLoginSsoCallbackUrl, null, {
            headers: {
                'currentSite': CONST.SSO_CURRENT_SITE,
                'api-key': CONST.SSO_API_KEY,
                'code': code
            }
        }),
        'post login SSO callback'
    )
}

export function apiGetLogoutSSO() {
    return processRequest(
        Axios.get(apiGetLogoutSsoUrl, {
            headers: {
                'api-key': CONST.SSO_API_KEY
            }
        }),
        'get logout SSO URL'
    )
}

export function apiRegisterUser(data) {
    return processRequest(
        Axios.post(apiRegister, data, {
            headers: {
                'api-key': CONST.SSO_API_KEY
            }
        }),
        'register user'
    )
}

export function apiUpdateProfile(data) {
    return processRequest(
        Axios.put(updateProfile + data.userID, data, {
            headers: {
                'api-key': CONST.SSO_API_KEY
            }
        }),
        'update profile'
    )
}

function processRequest(promiseRequest, actionName, dataName = 'data') {
    return new Promise((resolve, reject) => {
        promiseRequest
            .then(response => {
                if (response.data.success) {
                    // On successful response, resolve the promise
                    if (!response || !response.data || !response.data[dataName]) {
                        return resolve()
                    }
                    return resolve(response.data[dataName])
                }

                return reject(new Error(`${response.data.errors ? response.data.errors : response.data.responseMessage}`))
            })
            // On server error, we reject the promise with an error message
            .catch(error => {
                if (error.response.status === 400) {
                    return reject(error.response.data.responseMessage)
                } else {
                    return reject(new Error(`Failed to ${actionName}: ${error}`))
                }
            })
    })
}