import React, { Component } from 'react'

export class SvgChevronRight extends Component {
  render() {
    return (
        <svg {...this.props} width="16" height="16" viewBox="7.5 4.5 9 15" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M7.5 6.019L9.014 4.5 16.5 12l-7.486 7.5L7.5 17.98 13.467 12z" fill="#3DCD58"></path>
        </svg>
    );
  }
}
