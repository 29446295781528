class BusbarService {
    getBusbarStartIndex(mountingPosition, isRowWithMainSwitch, mainSwitchPosition, neutralTerminalBlock, firstConnectableBreaker) {

        // Determinate starting position for the busbar, so if it's not the row with the main switch, then start at the beggining of the row
        let busbarStartIndex = 0
        let lowerCasedMountingPosition = mountingPosition.toLowerCase()

        if (isRowWithMainSwitch) {
            if (lowerCasedMountingPosition === 'top') {
                // Busbar initial position will cover the main switch
                busbarStartIndex = mainSwitchPosition

                if (neutralTerminalBlock) {
                    // Here we know that the busbar should have a Neutral Terminal block before, so the initial position of the 
                    // busbar should also cover this device
                    busbarStartIndex = mainSwitchPosition - 1
                } 
            } else if (lowerCasedMountingPosition === 'bottom') {
                // Bottom busbar can't cover the main switch
                // If we have busbar on bottom of the first row where the main switch is present, then the busbar will start
                // on the position of the first connectable breaker device
                busbarStartIndex = firstConnectableBreaker
            }
        } else {
            busbarStartIndex = firstConnectableBreaker
        }

        return busbarStartIndex
    }

    getBusbarEndIndex(startPosition, busbarPoles, extraPoles) {
        return startPosition + (busbarPoles + extraPoles -1) // -1 to return array based result
    }

    getBusbarMinimumLengthToCoverAllRowDevices(busbarStartIndex, lastConnectableBreakerPositionIndex, lastConnectableBreakerSpaceUsed) {
        return (lastConnectableBreakerPositionIndex - busbarStartIndex) + lastConnectableBreakerSpaceUsed
    }

    getMaximumAllowedExtraPoles(busbarStartIndex, lastConnectableBreakerPosition, lastConnectableBreakerSpaceUsed, busBarPoles, rowLength, firstIncompatibleDevicePoleIndexOnRightSideOfLastCompatibleDevice, willBeMovingDevicesToMakeSpaceForNeutralTerminalBlock = false) {
        // If the busbar will be having the neutral terminal block that means that the main switch will need to be potentially moved
        // automatically by one space to the right to automatically insert it, which means that all breakers to the right will be shifted by one space
        // And this needs to be considered when calculating the maximum allowed extra poles, the easiest way to do so seems to be by moving the last braker
        // position by the space occupied by the neutral terminal block, which is one space
        if (willBeMovingDevicesToMakeSpaceForNeutralTerminalBlock) {
            lastConnectableBreakerPosition += 1
        }
        
        let busbarRequiredLengthToCoverAllDevices = this.getBusbarMinimumLengthToCoverAllRowDevices(busbarStartIndex, lastConnectableBreakerPosition, lastConnectableBreakerSpaceUsed)
        let maximumPolesConsideringLengthOfTheBusbar = busBarPoles - busbarRequiredLengthToCoverAllDevices
        let maximumPolesConsideringLengthOfTheRowAndBusbarSize = rowLength - (lastConnectableBreakerPosition + lastConnectableBreakerSpaceUsed)
        let maximumPolesConsideringIncompatibleDeviceOnRightSide = null

        if (firstIncompatibleDevicePoleIndexOnRightSideOfLastCompatibleDevice !== null) {
            if (willBeMovingDevicesToMakeSpaceForNeutralTerminalBlock) firstIncompatibleDevicePoleIndexOnRightSideOfLastCompatibleDevice += 1
            maximumPolesConsideringIncompatibleDeviceOnRightSide = firstIncompatibleDevicePoleIndexOnRightSideOfLastCompatibleDevice - (lastConnectableBreakerPosition + lastConnectableBreakerSpaceUsed)
        }

        if (maximumPolesConsideringLengthOfTheBusbar > maximumPolesConsideringLengthOfTheRowAndBusbarSize) {
            if (maximumPolesConsideringIncompatibleDeviceOnRightSide !== null && maximumPolesConsideringIncompatibleDeviceOnRightSide < maximumPolesConsideringLengthOfTheRowAndBusbarSize) {
                return maximumPolesConsideringIncompatibleDeviceOnRightSide
            } else {
                return maximumPolesConsideringLengthOfTheRowAndBusbarSize
            }
        } else {
            if (maximumPolesConsideringIncompatibleDeviceOnRightSide !== null && maximumPolesConsideringIncompatibleDeviceOnRightSide < maximumPolesConsideringLengthOfTheBusbar) {
                return maximumPolesConsideringIncompatibleDeviceOnRightSide
            } else {
                return maximumPolesConsideringLengthOfTheBusbar
            }
        }
    }

    getBusbarsWithNeutralTerminalBlockReferences() {
        return ['MX9B112N', 'MX9B115N', 'MX9B121N', 'MX9B315N', 'MX9B321N']
    }
}

const busbarService = new BusbarService()
export default busbarService
  