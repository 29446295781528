// This data defines what options to show for the board options, the values here are hardcoded
// and the format is what we'd expect from the server if these is one day to be maintained on
// the server side.
export const apiUrlGetBoardOptionLabels = {
    "Success": true,
    "Error": null,
    "Message": null,
    "Data": {
        "OptionsIPRating": [{
            "value": "IP42",
            "label": "IP42",
            "warning": ""
        },
        {
            "value": "IP66",
            "label": "IP66",
            "warning": ""
        }],
        "OptionsBoardColour": [{
            "value": "Mild Steel - N42 Grey",
            "label": "Mild Steel - N42 Grey",
            "warning": ""
        },
        {
            "value": "Mild Steel - X15 Orange",
            "label": "Mild Steel - X15 Orange",
            "warning": ""
        },
        {
            "value": "Stainless Steel - Natural Finish",
            "label": "Stainless Steel - Natural Finish",
            "warning": "* longer lead times may apply"
        }],
        "OptionsMainSwitchType": [{
            "value": "CVS",
            "label": "Isolator",
            "warning": ""
        },
        {
            "value": "NSX",
            "label": "NSX Circuit Breaker",
            "warning": ""
            // "warning": "* Isolator main switch is presently unavailable. This stock issue will be rectified ASAP."
        }],
        "OptionsRatingAmperes": [{
            "value": 100,
            "label": "100A",
            "warning": ""
        },
        {
            "value": 160,
            "label": "160A",
            "warning": ""
        },
        {
            "value": 250,
            "label": "250A",
            "warning": ""
        }],
        "OptionsChassisCount": [{
            "value": 1,
            "label": "One",
            "warning": ""
        },
        {
            "value": 2,
            "label": "Two",
            "warning": ""
        }],
        "OptionsChassisType": [{
            "value": "MSC",
            "label": "Standard chassis",
            "warning": ""
        },
        {
            "value": "Isobar",
            "label": "Isobar chassis",
            "warning": ""
        },
        {
            "value": "Hybrid",
            "label": "Hybrid chassis",
            "warning": ""
        }],
        "OptionsBreakingCapacity": [{
            "value": 6,
            "label": "6kA",
            "warning": ""
        },
        {
            "value": 10,
            "label": "10kA",
            "warning": ""
        }],
        "OptionsMetering": [{
            "value": true,
            "label": "Yes",
            "warning": ""
        },
        {
            "value": false,
            "label": "No",
            "warning": ""
        }],
        "OptionsMeterType": [{
            "value": "DM",
            "label": "Dual Meter",
            "warning": ""
        },
        {
            "value": "EM",
            "label": "EM3255-NMI",
            "warning": ""
        },
        {
            "value": "PM",
            "label": "PM3255",
            "warning": ""
        }],
        "OptionsChassis1PoleCount27mm": [{
            "value": 6,
            "label": "6",
            "warning": ""
        },
        {
            "value": 12,
            "label": "12",
            "warning": ""
        }],
        "OptionsChassis1PoleCount": [{
            "value": 12,
            "label": "12",
            "warning": ""
        },
        {
            "value": 18,
            "label": "18",
            "warning": ""
        },
        {
            "value": 24,
            "label": "24",
            "warning": ""
        },
        {
            "value": 36,
            "label": "36",
            "warning": ""
        },
        {
            "value": 48,
            "label": "48",
            "warning": ""
        },
        {
            "value": 54,
            "label": "54",
            "warning": ""
        },
        {
            "value": 60,
            "label": "60",
            "warning": ""
        },
        {
            "value": 72,
            "label": "72",
            "warning": ""
        },
        {
            "value": 84,
            "label": "84",
            "warning": ""
        },
        {
            "value": 96,
            "label": "96",
            "warning": ""
        }],
        "OptionsChassis2PoleCount": [{
            "value": 18,
            "label": "18",
            "warning": ""
        },
        {
            "value": 24,
            "label": "24",
            "warning": ""
        },
        {
            "value": 36,
            "label": "36",
            "warning": ""
        },
        {
            "value": 48,
            "label": "48",
            "warning": ""
        },
        {
            "value": 54,
            "label": "54",
            "warning": ""
        },
        {
            "value": 60,
            "label": "60",
            "warning": ""
        },
        {
            "value": 72,
            "label": "72",
            "warning": ""
        },
        {
            "value": 84,
            "label": "84",
            "warning": ""
        },
        {
            "value": 96,
            "label": "96",
            "warning": ""
        }]
    }
}
