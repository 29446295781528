import React from 'react'
import { DragSource } from 'react-dnd'

export class ChassisBreaker extends React.Component {

    constructor(props) {
        super(props)
        // Bind this to element function calls for better performance / practise
        // - ref: https://github.com/yannickcr/eslint-plugin-react/blob/master/docs/rules/jsx-no-bind.md
        this.editClickCall = this.editClickCall.bind(this)
        this.deleteCall = this.deleteCall.bind(this)
        this.updateBrekerChassisNoteCall = this.updateBrekerChassisNoteCall.bind(this)

        // Internal states that no one else needs to know about holds info on the selection options
        this.state = {
            showEditOptions: false,
            isDeleting: false,
            BreakerCircuitLabelInput: this.props.BreakerCircuitLabelInput
        }
    }

    deleteCall() {
        this.setState({ isDeleting: true })
        this.props.deletedCallback(this.props.RowIndex, this.props.PoleIndex, this.props.itemId)
    }

    editClickCall() {
        this.setState({ showEditOptions: !this.state.showEditOptions }, () => {
            if (this.state.showEditOptions) this.refs.labelInput.focus()
        })
    }

    updateBrekerChassisNoteCall() {
        this.props.updateBrekerChassisNote(this.props.itemId, this.props.RowIndex, this.props.PoleIndex, this.state.BreakerCircuitLabelInput)
        this.setState({ showEditOptions: false })
    }

    generateBrekerPositions(startNumber) {
        const spaceUsed = parseInt(this.props.poles, 10)
        const displayNumbers = []

        for (let i = 0; i < spaceUsed; i++) {
            let displayNumber = startNumber + i
            if (displayNumber < 10) displayNumber = '0' + displayNumber

            displayNumbers.push(
                <div className="breaker-check-container" key={displayNumber}>
                    <div className="switchboard__breaker-check">{ displayNumber }</div>
                </div>
            )
        }

        return displayNumbers
    }

    togglePowerTag(addPowerTag, position) {
        this.props.togglePowerTagCallback(this.props.RowIndex, this.props.PoleIndex, this.props.itemId, position, addPowerTag)
    }

    render() {
        const uniqueIndex = 'p-tag-row' + this.props.RowIndex + '-' + this.props.PoleIndex

        if (this.props.isDragging) {
            return <div className={`switchboard__slot`} ref={this.props.connectDragSource} />
        }
        
        return (
            <div className={`switchboard__slot` + (this.props.isMandatoryMCB ? ' is-disabled special-slot is-mandatory-mcb' : '')} 
                key={uniqueIndex} ref={ this.props.connectDragSource }>
                <span className="switchboard__slot-number">{ this.props.PoleIndex + 1 }</span>
                <form className={`switchboard__breaker is-${this.props.poles}-slot ${this.state.showEditOptions ? 'is-edit' : ''}`}
                    onSubmit={ (event) => { event.preventDefault(); this.updateBrekerChassisNoteCall(); }}>
                    {
                        !this.props.BottomPowerTag && 
                        <div className="power-tag power-tag--top">
                            <div className="form__control checkbox">
                                <input id={uniqueIndex} type="checkbox" name="powerTagCheckbox" className="form__control-input" 
                                    checked={ this.props.TopPowerTag }
                                    onChange={(event) => this.togglePowerTag(event.target.checked, 'top') } />
                                <label htmlFor={uniqueIndex} className="form__control-label">
                                    <span>Add PowerTag</span>
                                </label>
                            </div>
                        </div>
                    }
                    <div className="switchboard__breaker-checklist">
                        { this.generateBrekerPositions(this.props.PoleIndex + 1) }
                    </div>
                    <div className="switchboard__breaker-content">
                        <input className="input" type="text" ref="labelInput" maxLength="30" required value={ this.state.BreakerCircuitLabelInput } onChange={ (event) => this.setState({ BreakerCircuitLabelInput: event.target.value }) } />
                        <span className="switchboard__breaker-text">
                            { this.props.currentRating } <br></br>
                            { this.props.code }
                        </span>

                        {
                            this.state.BreakerCircuitLabelInput !== '' && !this.state.showEditOptions &&
                            <div className="switchboard__breaker-note">
                                { this.state.BreakerCircuitLabelInput }
                            </div>
                        }
                    </div>
                    <div className="switchboard__breaker-actions">
                        <div className="actions actions--default">
                            <button className={ `button button--icon edit ${this.props.itemId === 0 ? 'is-disabled' : ''}`} type="button" onClick={ this.editClickCall }>
                                <span className="i-edit"></span>
                            </button>
                            <button className={ `button button--icon delete ${this.state.isDeleting ? 'is-deleting' : ''} ${this.props.itemId === 0 ? 'is-disabled' : ''}`} type="button" onClick={ this.deleteCall } disabled={this.props.itemId === 0}>
                                <span className="i-trash-bin"></span>
                            </button>
                        </div>
                        <div className="actions actions--edit">
                            <button className="button button--icon confirm" type="button" onClick={ this.updateBrekerChassisNoteCall }>
                                <span className="i-check"></span>
                            </button>
                            <button className="button button--icon cancel" type="button" onClick={ this.editClickCall }>
                                <span className="i-close"></span>
                            </button>
                        </div>
                    </div>
                    {
                        !this.props.TopPowerTag &&
                        <div className="power-tag power-tag--bottom">
                            <div className="form__control checkbox">
                                <input id={uniqueIndex + '-bottom'} type="checkbox" name="powerTagCheckbox" className="form__control-input" 
                                    checked={ this.props.BottomPowerTag }
                                    onChange={(event) => this.togglePowerTag(event.target.checked, 'bottom') }  />
                                <label htmlFor={uniqueIndex + '-bottom'} className="form__control-label">
                                    <span>Add PowerTag</span>
                                </label>
                            </div>
                        </div>
                    }
                </form>
            </div>
        )
    }
}

export default DragSource('ResidentialChassisPole', 
    {
        beginDrag: () => {
            return {}
        },
        endDrag(props, monitor) {
            const dropResult = monitor.getDropResult() 
            
            if (dropResult) {
                let poleIndex = dropResult.poleIndex
                let rowIndex = dropResult.rowIndex

                const canAddDeviceToPosition = props.canAddDeviceToPosition(props.poles, rowIndex, poleIndex, false, props.itemId)
                if (canAddDeviceToPosition) {
                    props.updateBreakerPosition(props.itemId, dropResult.rowIndex, dropResult.poleIndex, props.BreakerCircuitLabelInput)
                }
            }
        },
        canDrag(props) {
            return props.isAllowedToDragNDropPole && !props.isMandatoryMCB
        }
    }, 
    (connect, monitor) => {
        return {
            connectDragSource: connect.dragSource(),
            isDragging: monitor.isDragging()
        }
    }
)(ChassisBreaker)
