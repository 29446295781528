import { property } from 'lodash'
import React from 'react'
import { DropTarget } from 'react-dnd'

export class BoardEmptyPole extends React.Component {
    constructor (props) {
        super(props)
    }

    render () {
        const isActive = this.props.canDrop && this.props.isOver

        return (
            <div className={'switchboard__slot' + (this.props.isDraggingItem ? ' show-available-slots' : '') + (isActive ? ' dragging-on-top' : '') }
                ref={this.props.connectDropTarget}>
                <span className="switchboard__slot-number">{ this.props.poleDisplayIndex }</span>
                <div className="switchboard__slot-text">
                    <span className="available-text">
                        {
                            isActive ?
                            <React.Fragment>
                            {
                                this.props.isAllowedToDragNDropPole ?
                                <span>Release to drop</span>
                                :
                                <span>Busbar present <br></br> Not allowed</span>
                            }
                            </React.Fragment>
                            :
                            <span>Space<br /> Avl.</span>
                        }
                    </span>
                    <span className="hover-text">
                        {/* Need to fix */}
                        {/* <span>Drag and drop a breaker from your library</span> */}
                    </span>
                </div>
            </div>
        )
    }
}

export default DropTarget('ResidentialChassisPole',
    {
        drop: (props) => ({ 
            rowIndex: props.rowIndex,
            poleIndex: props.poleIndex,
            isAllowedToDragNDropPole: props.isAllowedToDragNDropPole
        })
    },
    (connect, monitor) => ({ 
        connectDropTarget: connect.dropTarget(),
        isOver: monitor.isOver(),
        canDrop: monitor.canDrop(),
        isDraggingItem: monitor.getItem() !== null
    })
)(BoardEmptyPole)