import React from 'react'
import { isNullOrUndefined, drawPercentProgressBars } from '../../../utils/helpers'
import { apiUrlGetDocument } from '../../../utils/apiCallsCommercial'
import analyticsDataLayer from '../../../services/AnalyticsDataLayer'

export default class BoardTile extends React.Component {

    constructor(props) {
        super(props)
        // Bind this to element function calls for better performance / practise
        // - ref: https://github.com/yannickcr/eslint-plugin-react/blob/master/docs/rules/jsx-no-bind.md
        this.editCall = this.editCall.bind(this)
        this.showBoardDetails = this.showBoardDetails.bind(this)
        this.copyCall = this.copyCall.bind(this)
        this.deleteCall = this.deleteCall.bind(this)

        this.state = {
            loading: false
        }
    }

    editCall() {
        if (this.props.isValid) {
            this.props.editCallback(this.props.id)
        }
    }

    showBoardDetails() {
        this.props.showBoardDetailsCallback(this.props.boardBreakers)
    }

    copyCall() {
        if (!this.props.maxBoardLimitReached && this.props.isValid) {
            this.props.copyCallback(this.props.id)
        }
    }

    deleteCall() {
        this.props.deleteCallback(this.props.id)
    }

    componentDidMount() {
        this.drawPercent()
    }

    shouldComponentUpdate(nextProps) {
        if (this.props.chassis1percent !== nextProps.chassis1percent ||
            this.props.chassis2percent !== nextProps.chassis2percent ||
            this.props.maxBoardLimitReached !== nextProps.maxBoardLimitReached ||
            isNullOrUndefined(this.props.chassis2percent) !== isNullOrUndefined(nextProps.chassis2percent)) {

            this.drawPercent()
        }

        return true
    }

    configureBoard(projectID, boardID) {
        this.props.configureChassisCallback(projectID, boardID)
    }

    drawPercent() {
        drawPercentProgressBars()
    }

    renderPercent(title, value, index) {
        if (isNullOrUndefined(value)) {
            return ''
        } else {
            return (
                <div className="card__chassis">
                    <div className="donut-container" >
                        <div className="donut progress-bar-percent" data-index={ index } data-value={ value ? value : '0' }></div>
                        <p className="card__chassis-title p p--xs p--caps">
                            { title }
                        </p>
                    </div>
                </div>
            )
        }
    }

    renderProjectBoardModals = (boardDescription = []) => {
        return (
            <div className="table-wrapper board-description">
                <table>
                    <tbody>
                        <tr>
                            <th>Qty</th>
                            <th>Item</th>
                        </tr>
                        {boardDescription.map((boardDescription, index) => {
                            return (
                                <tr key={index}>
                                    <td>{boardDescription.Quantity}</td>
                                    <td> {boardDescription.Description}</td>
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
            </div>
        )
    }

    render() {
        return (
                <div className="card-container">
                    {/* Add .is-error to put it in an error state */}
                    <div className="card">
                        <h4 className="card__title h4">
                            { !this.props.isValid && '[DATA CORRUPTED] - ' } { this.props.name }
                        </h4>
                        <div className="card__chassis-container">
                            {this.renderPercent('Chassis 1', this.props.isValid ? this.props.chassis1percent : null, 1)}
                            {this.renderPercent('Chassis 2', this.props.isValid ? this.props.chassis2percent : null, 2)}
                        </div>
                        
                        <div className="card__actions card__actions--hover">
                            { !this.props.isFinalised &&
                                <React.Fragment>
                                    {
                                        !this.props.maxBoardLimitReached &&
                                        <button className="button button--clear duplicate" onClick={ this.copyCall }>
                                            <span className="button__icon">
                                                <span className="i-duplicate"></span>
                                            </span>
                                            <span className="button__text">Copy Board</span>
                                        </button>
                                    }
                                    <button className="button button--clear delete" onClick={ this.deleteCall }>
                                        <span className="button__icon">
                                            <span className="i-trash-bin"></span>
                                        </span>
                                        <span className="button__text">Delete</span>
                                    </button>
                                </React.Fragment>
                            }
                        </div>
                        <div className="card__actions">
                            { !this.props.isFinalised &&
                            <React.Fragment>
                                <button className={`button button--3 ${this.props.isValid || 'btn-disabled'}`} onClick={ this.editCall }>
                                    <span className="button__icon">
                                        <span className="i-edit"></span>
                                    </span>
                                    <span className="button__text">
                                        Edit Board
                                    </span>
                                </button>
                                <button className="button button--1" onClick={() => { this.configureBoard(this.props.projectId, this.props.id) }}>
                                    <span className="card__text">
                                        Configure Board
                                    </span>
                                </button>
                            </React.Fragment>
                            }

                            { this.props.isFinalised &&
                            <React.Fragment>
                                <button className="button button--3" onClick={ this.showBoardDetails }>
                                    <span className="button__icon">
                                        <span className="i-edit"></span>
                                    </span>
                                    <span className="button__text">
                                        View Details
                                    </span>
                                </button>

                                {
                                    this.props.drawingGuid ?
                                    <a href={`${apiUrlGetDocument}/${this.props.drawingGuid}`} className="button button--2" >
                                        <span className="button__icon">
                                            <span className="i-download"></span>
                                        </span>
                                        <span className="button__text" onClick={() => {
                                             analyticsDataLayer.downloadLayout({
                                                "document_id": this.props.drawingGuid,
                                                "document_name": "Download Drawings",
                                                "document_type": "pdf"
                                            })
                                        }}>
                                            View Drawings
                                        </span>
                                    </a>
                                    :
                                    <button className="button button--2 disabled">
                                        <span className="button__icon">
                                            <span className="i-download"></span>
                                        </span>
                                        <span className="button__text">
                                            {
                                                this.props.loadingDrawings ? 'Loading' : 'No board drawing available'
                                            }
                                        </span>
                                    </button>
                                }
                            </React.Fragment>
                            }
                        </div>
                    </div>

            </div>
        )

    }
}
