import React from 'react'
import { DragSource } from 'react-dnd'

export class WiserEnergyGateway extends React.Component {
    constructor (props) {
        super(props)
    }

    generateDevicePositions(startNumber) {
        const spaceUsed = parseInt(this.props.poles, 10)
        const displayNumbers = []

        for (let i = 0; i < spaceUsed; i++) {
            let displayNumber = startNumber + i
            if (displayNumber < 10) displayNumber = '0' + displayNumber

            displayNumbers.push(
                <div className="breaker-check-container" key={displayNumber}>
                    <div className="switchboard__breaker-check">{ displayNumber }</div>
                </div>
            )
        }

        return displayNumbers
    }

    render () {
        if (this.props.isDragging) {
            return <div className={`switchboard__slot`} ref={ this.props.connectDragSource }></div>
        }

        return (
            <div className={`switchboard__slot is-disabled is-wiser-energy-gateway is-${this.props.poles}-slot`} ref={ this.props.connectDragSource }>
                <div className="special-slot">
                    <div className="switchboard__breaker-checklist">
                        { this.generateDevicePositions(this.props.poleIndex + 1) }
                    </div>
                    <div className="switchboard__slot-text">
                        Wiser Energy Gateway
                    </div>
                </div>
            </div>
        )
    }
}

export default DragSource('ResidentialChassisPole', 
    {
        beginDrag: (props) => {
            return {}
        },
        endDrag(props, monitor) {
            const dropResult = monitor.getDropResult() 

            if (dropResult) {
                let poleIndex = dropResult.poleIndex
                let rowIndex = dropResult.rowIndex

                if (props.canAddDeviceToPosition(props.poles, rowIndex, poleIndex)) {
                    props.updateWiserEnergyGatewayPosition(rowIndex, poleIndex)
                }
            }
        },
        canDrag(props) {
            return props.isAllowedToDragNDropPole
        }
    }, 
    (connect, monitor) => {
        return {
            connectDragSource: connect.dragSource(),
            isDragging: monitor.isDragging()
        }
    }
)(WiserEnergyGateway)
