// This data defines what options to show, the values here are hardcoded
// and the format is what we'd expect from the server if these is one day to be maintained on the server side.
export const apiUrlGetBreakerOptionLabels = {
    "Success": true,
    "Error": null,
    "Message": null,
    "Data": {
        "TypeOfDevice": [
            { "value": "MCB-4.5kA", "label": "MCB-4.5kA" },
            { "value": "AFDD+RCBO - 6kA", "label": "AFDD+RCBO-6kA" },
            { "value": "MCB-6kA", "label": "MCB-6kA" },
            { "value": "RCD-4.5kA", "label": "RCD-4.5kA" },
            { "value": "RCBO-4.5kA", "label": "RCBO-4.5kA" },
            { "value": "RCD - 6kA", "label": "RCD-6kA" },
            { "value": "RCBO - 6kA", "label": "RCBO-6kA" }
        ],
        "NumberOfPoles": [
            { "value": "1P", "label": "1P" },
            { "value": "2P", "label": "2P" },
            { "value": "3P", "label": "3P" },
            { "value": "4P", "label": "4P" },
            { "value": "SLIM 1P+N", "label": "Slim 1P+N" },
            { "value": "36 mm 1P+N", "label": "36 mm 1P+N" },
            { "value": "3P+N", "label": "3P+N" }
        ],
        "CurrentRating": [
            { "value": "2A", "label": "2A" },
            { "value": "4A", "label": "4A" },
            { "value": "6A", "label": "6A" },
            { "value": "10A", "label": "10A" },
            { "value": "16A", "label": "16A" },
            { "value": "20A", "label": "20A" },
            { "value": "25A", "label": "25A" },
            { "value": "32A", "label": "32A" },
            { "value": "40A", "label": "40A" },
            { "value": "50A", "label": "50A" },
            { "value": "63A", "label": "63A" }
        ]
    }
}
