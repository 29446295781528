// This data defines what options to show, the values here are hardcoded
// and the format is what we'd expect from the server if these is one day to be maintained on the server side.
export const apiUrlGetBreakerOptionLabels = {
    "Success": true,
    "Error": null,
    "Message": null,
    "Data": {
        "OptionsTypeCode": [
            {"value": "MCB-6kA", "label": "MCB-6kA", "breakingCapacity": [6]},
            {"value": "RCBO-6kA", "label": "RCBO-6kA", "breakingCapacity": [6]},
            {"value": "MCB-10kA", "label": "MCB-10kA", "breakingCapacity": [10]},
            {"value": "RCBO-10kA", "label": "RCBO-10kA", "breakingCapacity": [10]},
            {"value": "3PH-VIGI", "label": "MCB(3P) + RCD(3P+N)", "breakingCapacity": [6, 10]}
        ],
        "OptionsPoleCount": [
            {"value": 1, "label": "1P"},
            {"value": 3, "label": "3P"},
            {"value": 6, "label": "3P + 3P"}
        ],
        "OptionsPitchPoleSize": [
            {"value": 18, "label": "18mm"},
            {"value": 27, "label": "27mm"}
        ],
        "OptionsCurrentRating": [
            {"value": 6, "label": "6A"},
            {"value": 10, "label": "10A"},
            {"value": 16, "label": "16A"},
            {"value": 20, "label": "20A"},
            {"value": 25, "label": "25A"},
            {"value": 32, "label": "32A"},
            {"value": 40, "label": "40A"},
            {"value": 50, "label": "50A"},
            {"value": 63, "label": "63A"},
            {"value": 80, "label": "80A"},
            {"value": 100, "label": "100A"},
            {"value": 125, "label": "125A"}
        ]
    }
}
