import React from 'react'
import cloneDeep from 'lodash.clonedeep'
import RadioObjectGroup from '../../radio-object-group/RadioObjectGroup'
import { isNullOrUndefined } from '../../../utils/helpers'

export default class BreakerDetailsFormContainer extends React.Component {
    constructor(props) {
        super(props)
        // Bind this to element function calls for better performance / practise
        // - ref: https://github.com/yannickcr/eslint-plugin-react/blob/master/docs/rules/jsx-no-bind.md
        this.updateCall = this.updateCall.bind(this)
        this.deleteCall = this.deleteCall.bind(this)
        this.optionSelectedCall = this.optionSelectedCall.bind(this)

        // Internal states that no one else needs to know about
        // holds info on the selection options
        this.initStates(this.props)

        // Setup the business logic
        this.logic = new BreakerDetailsFormBusinessLogic(props.breakerOptions, props.breakerOptionData, this.state.breakerDetails, this.props.isHybridBoard, this.props.breakingCapacity)
    }

    componentWillReceiveProps(nextProps) {
        if (!this.props.breaker && !nextProps.breaker) {
            return
        }
        if (!this.props.breaker || !nextProps.breaker || this.props.breaker.ItemID !== nextProps.breaker.ItemID) {
            this.initStates(nextProps, true)
        }
    }

    initStates(propsData, useSetState = false) {
        const existingBreaker = propsData.breaker ? propsData.breakerOptionData.find(item => item.ItemID === propsData.breaker.BreakerTypeItemID) : null
        const existingBreakerPitch = (existingBreaker && existingBreaker.CurrentRating >= 80) ? 27 : 18
        const breakerTypeCode = existingBreaker ? existingBreaker.TypeCode : null
        let breakerCurrentRating = existingBreaker ? existingBreaker.CurrentRating : null

        // If we have a VIGI breaker, then we need to get its current rating from the parent breaker
        if (breakerTypeCode === '3PH-VIGI') {
            const parentBreaker = propsData.breakerOptionData.find(item => item.ItemID === propsData.breaker.ParentBreakerTypeItemID)
            breakerCurrentRating = parentBreaker.CurrentRating
        }

        const updatedState = {
            breakerDetails: {
                TypeCode: breakerTypeCode,
                PoleCount: existingBreaker ? existingBreaker.PoleCount : null,
                PitchPoleSize: existingBreaker ? existingBreakerPitch : (this.props.isHybridBoard ? null : 18),
                CurrentRating: breakerCurrentRating
            },
            commercialAllPartsAvailable: propsData.commercialAllPartsAvailable,
            validationMessage: '',
            isOutOfStock: false
        }
        if (useSetState) {
            this.setState(updatedState)
        } else {
            this.state = updatedState
        }
    }

    deleteCall() {
        this.props.deleteCallback(this.props.breaker.BreakerTypeItemID, this.props.breaker.ParentBreakerTypeItemID)
    }

    checkIfFilterDataAreTheSame(filterData) {
        let allTheSame = true
        const checkFields = ['PoleCount', 'TypeCode', 'CurrentRating', 'PitchPoleSize']
        filterData.forEach(item => {
            checkFields.forEach(field => {
                if (filterData[0][field] !== item[field]) {
                    allTheSame = false
                }
            })
        })
        return allTheSame
    }

    updateCall() {
        const finalSelections = cloneDeep(this.state.breakerDetails)
        const finalFilteredBreakerData = this.logic.setAndClearRelatedFields(finalSelections)

        // Assumption that the Breaker type options from the server will always have distinct setup
        if (finalFilteredBreakerData.length !== 1 && !this.checkIfFilterDataAreTheSame(finalFilteredBreakerData)) {
            this.setState({
                validationMessage: 'Please make sure all fields are filled in'
            })
            return
        }

        // Create the final Breaker object to be passed to the server
        const finalBreaker = finalFilteredBreakerData[0]

        if (finalBreaker.TypeCode === '3PH-VIGI') {
            // IF we are dealing with a VIGI breaker....
            // We need to find the matching 3P breaker and send that breaker's type ID as this breaker's parent ID
            const parentBreaker = this.props.breakerOptionData.find(breakerObj => {
                if (breakerObj.PoleCount === 3 && breakerObj.CurrentRating === finalSelections['CurrentRating']) {
                    // Also need the parent breaker to be of the correct breaking capacity as the board
                    const breakCode = breakerObj.TypeCode.split('-')
                    if (breakCode.length < 2) {
                        // not the right breaker if it's got a weird typecode that doesnt follow the pattern....
                        return false
                    }
                    if (breakCode[1].startsWith(`${this.props.breakingCapacity}`)) {
                        // Get the breaker with the code that starts with the correct breaking capacity
                        return true
                    }
                }
                return false
            })
            // Calls create / update callback based on is new Breaker flag
            if (this.props.breaker) {
                this.props.updateCallback(finalBreaker.ItemID, parentBreaker.ItemID)
            } else {
                this.props.createCallback(finalBreaker.ItemID, parentBreaker.ItemID)
            }
        } else {
            // Normal breakers...
            // Calls create / update callback based on is new Breaker flag
            if (this.props.breaker) {
                this.props.updateCallback(finalBreaker.ItemID, null)
            } else {
                this.props.createCallback(finalBreaker.ItemID, null)
            }
        }
    }

    // Update the selected option value in local state
    optionSelectedCall(value, key) {
        const updatebreaker = cloneDeep(this.state.breakerDetails)
        updatebreaker[key] = value

        // Update values on the affected related fields
        // When users select ChassisCount or Chassis1PoleCount this affects other fields.
        this.logic.setAndClearRelatedFields(updatebreaker)
        this.setState({
            breakerDetails: updatebreaker
        }, () => {
            if (this.state.breakerDetails.CurrentRating !== null) {
                const finalSelections = cloneDeep(this.state.breakerDetails)
                const finalFilteredBreakerData = this.logic.setAndClearRelatedFields(finalSelections)

                if (finalFilteredBreakerData.length !== 1 && !this.checkIfFilterDataAreTheSame(finalFilteredBreakerData)) {
                    this.setState({
                        validationMessage: 'Please make sure all fields are filled in'
                    })
                    return
                }

                const finalBreaker = finalFilteredBreakerData[0]
                const availabilityDataForSelectedBreaker = this.state.commercialAllPartsAvailable.find(item => item.ItemCode === finalBreaker.BreakerReference)
                console.log('availabilityDataForSelectedBreaker', availabilityDataForSelectedBreaker)
                console.log('finalBreaker', finalBreaker)
                if (!availabilityDataForSelectedBreaker.IsInStock) {
                    this.setState({
                        validationMessage: `${finalBreaker.DrawingDesc} is out of stock. Please, contact <a href="mailto:au.estimating@se.com">au.estimating@se.com</a>`,
                        isOutOfStock: true
                    })
                } else if (availabilityDataForSelectedBreaker.IsLowStock) {
                    this.setState({
                        validationMessage: 'Low stock, longer lead times may apply',
                        isOutOfStock: false
                    })
                } else {
                    this.setState({ validationMessage: '', isOutOfStock: false })
                }
            }
        })
    }


    render() {
        // Setup the business logic
        this.logic = new BreakerDetailsFormBusinessLogic(this.props.breakerOptions, this.props.breakerOptionData, this.state.breakerDetails, this.props.isHybridBoard, this.props.breakingCapacity)

        const breakerOptions = this.props.breakerOptions
        const breakerDetails = this.state.breakerDetails
        const breakingCapacity = this.props.breakingCapacity

        return (
            <div className="breaker-details-form-container slide-out-form-container">
                <div className="form-wrapper">
                    <div className="row">
                        <div className="col-4">
                            <RadioObjectGroup
                                title={'Pole type'}
                                options={breakerOptions.OptionsTypeCode.filter(item => item.breakingCapacity.includes(breakingCapacity))}
                                selectedValue={breakerDetails.TypeCode}
                                selecteCallback={value => { this.optionSelectedCall(value, 'TypeCode') }}
                                enabledOptions={this.logic.getEnableOptions('TypeCode')} />
                        </div>

                        <div className="col-4">
                            <RadioObjectGroup
                                title={'Number of poles'}
                                options={breakerOptions.OptionsPoleCount}
                                selectedValue={breakerDetails.PoleCount}
                                selecteCallback={value => { this.optionSelectedCall(value, 'PoleCount') }}
                                enabledOptions={this.logic.getEnableOptions('PoleCount')} />
                        </div>

                        <div className="col-4">
                            <RadioObjectGroup
                                title={'Pitch size'}
                                options={breakerOptions.OptionsPitchPoleSize}
                                selectedValue={breakerDetails.PitchPoleSize}
                                selecteCallback={value => { this.optionSelectedCall(value, 'PitchPoleSize') }}
                                enabledOptions={this.logic.getEnableOptions('PitchPoleSize')} />
                        </div>
                    </div>
                    <hr />
                    <div className="row">
                        <div className="col-12">
                            <RadioObjectGroup
                                title={'Current rating'}
                                options={breakerOptions.OptionsCurrentRating}
                                selectedValue={breakerDetails.CurrentRating}
                                selecteCallback={value => { this.optionSelectedCall(value, 'CurrentRating') }}
                                layout={'radio-button-row'}
                                enabledOptions={this.logic.getEnableOptions('CurrentRating')} />
                        </div>
                    </div>
                </div>

                {
                    this.state.validationMessage &&
                    <div className="modal__validation-msg">
                        <span className="alert__icon">
                            <span className="i-exclamation"></span>
                        </span>
                        <span dangerouslySetInnerHTML={{ __html: this.state.validationMessage }}></span>
                    </div>
                }

                <div className="modal__footer">
                    {/* If the user specified Current Rating which is the very last input to choose, then enable Save/Edit button */}
                    <button
                        className="button button--1"
                        onClick={() => { this.updateCall() }}
                        disabled={(this.state.breakerDetails.CurrentRating === null && this.state.validationMessage !== '') || this.state.isOutOfStock}
                    >
                        <span className="button__text">
                            {!this.props.breaker && <span>Add Breaker</span>}
                            {this.props.breaker && <span>Edit Breaker</span>}
                        </span>
                    </button>
                </div>
            </div>
        )
    }
}

export class BreakerDetailsFormBusinessLogic {

    // The logic is constructed with all the available options and all the type combination data
    constructor(options, data, selectedOptions, isHybridBoard, breakingCapacity) {
        this.options = options
        this.data = this.trimData(data, breakingCapacity)
        this.isHybridBoard = isHybridBoard
        this.setAndClearRelatedFields(selectedOptions)
    }

    trimData(data, breakingCapacity) {
        data = data.filter(item => {
            // VIGI breakers dont have a breaking capacity on it, it is based off its parent breaker
            if (item.TypeCode === '3PH-VIGI') {
                return true
            }

            // Filter out the breakers of the wrong breaking capacity
            const breakCode = item.TypeCode.split('-')
            if (breakCode.length < 2) {
                // not the right breaker if it's got a weird typecode that doesnt follow the pattern....
                return false
            }
            if (breakCode[1].startsWith(`${breakingCapacity}`)) {
                // Get the breaker with the code that starts with the correct breaking capacity
                return true
            }

            return false
        })
        return data
    }

    // ------------------------------
    // Logic to handle auto selecting and clearing of data when an option is selected
    // ------------------------------

    setAndClearRelatedFields(selectedOptions) {
        // This function should check the remaining valid board type selections and
        // auto select the options that is the only avialable option left
        let filteredData = cloneDeep(this.data)
        let canEnableNext = true

        // walk through the selectedOptions in order, from top down
        // for each of the selectOption (top down in order)...
        for (var property in selectedOptions) {
            if (selectedOptions.hasOwnProperty(property)) {
                // check which option should be enabled
                canEnableNext = this.checkOptions(property, canEnableNext, selectedOptions, filteredData)

                // Filter down the filtered board type data with this option selection
                if (property === 'CurrentRating' && selectedOptions['TypeCode'] === '3PH-VIGI') {
                    // The VIGI breakers are spacial case....
                    // In the data, the VIGI breaker's current rating is 0 .... and there should be only 1 VIGI breaker data
                    // And due to the special cases surrounding the VIGI breaker, our code will allow user to select all available 3P current ratings...
                    filteredData = filteredData.filter(filterItem => filterItem[property] === 0)
                } else {
                    filteredData = filteredData.filter(filterItem => isNullOrUndefined(selectedOptions[property]) || isNullOrUndefined(filterItem[property]) || selectedOptions[property] === filterItem[property])
                }
            }
        }

        // Return the list of filtered Data - it can be used to retrieve BoardTypeID
        return filteredData
    }

    // Check if the options under a specific config (optionName) can be enabled or not
    // While performing the check, the function may auto select options if it is the last option available
    // This will also auto de-select options if the selected value is not in the available options list
    checkOptions(optionName, canEnableNext, selectedOptions, filteredData) {
        if (!this.options[`Options${optionName}`]) { return }

        // Has enabled options under this config
        let hasEnabled = false


        // Loop through the options of the specified config - enable the config's options based on a number of logic checks
        this.options[`Options${optionName}`].forEach((item, index) => {
            if (!canEnableNext) {
                // Disable all options under the config if board name is blank
                item['enabled'] = false
            } else if (optionName === 'PitchPoleSize') {
                // PitchPoleSize data filters are based on isHybridBoard setting
                if (this.isHybridBoard) {
                    // 27 mm pitch size is only available on hybrid board and its not available for a VIGI breaker
                    if (selectedOptions['TypeCode'] === '3PH-VIGI') {
                        item['enabled'] = (index === 0)
                    } else {
                        // Check the filtered data, check if there is any board type still available under the current option
                        const currentData = filteredData.filter(filterItem => filterItem[optionName] === item.value)

                        // All checks passed, enable the option
                        item['enabled'] = currentData.length ? true : false
                    }
                } else {
                    // for non-hybrid board we only enable the first option
                    item['enabled'] = (index === 0)
                }
            } else if (optionName === 'CurrentRating' && selectedOptions['TypeCode'] === '3PH-VIGI') {
                // The VIGI breakers are spacial case....
                // If the user selected the MCB(3P) + RCD(3P+N) pole type then its a VIGI breaker, and we need to enable all the current rating selection that
                // is available to a normal 3P breaker

                // Check if we can find a normal 3P breaker with this option's current rating - if so, enable it
                let tempData = cloneDeep(this.data)
                tempData = tempData.filter(tempItem => (tempItem['CurrentRating'] === item.value && tempItem['PoleCount'] === 3 && tempItem['PitchPoleSize'] === selectedOptions['PitchPoleSize']))
                item['enabled'] = tempData.length ? true : false
            } else {
                // Check the filtered data, check if there is any board type still available under the current option
                const currentData = filteredData.filter(filterItem => filterItem[optionName] === item.value)

                // All checks passed, enable the option
                item['enabled'] = currentData.length ? true : false
            }

            // Set the has enabled flag if the config's option item has been enabled after the checks
            if (item['enabled']) {
                hasEnabled = true
            }
        })


        // Now we check the selected option (if any), we need to de-select it if it is not one of the available options
        if (!isNullOrUndefined(selectedOptions[optionName])) {
            const selectedValidOptions = this.options[`Options${optionName}`].filter(item => (item.enabled && item.value === selectedOptions[optionName]))
            if (!selectedValidOptions.length) {
                selectedOptions[optionName] = null
            }
        }

        // Time to check the current config's options, if only one available option is left, then we need to set that option as the
        // selected option and also disable that option's selection (so the user dont get a choice on it)
        const availableOptions = this.options[`Options${optionName}`].filter(item => item.enabled)
        if (availableOptions.length === 1) {
            availableOptions[0].enabled = false
            selectedOptions[optionName] = availableOptions[0].value
            hasEnabled = false
        }

        // Return if the next config can be selected or not, it can be if this item it self can be enabled
        // AND it either has already got a option selection or none of the options are valid
        return canEnableNext && (!isNullOrUndefined(selectedOptions[optionName]) || !hasEnabled)
    }

    // ------------------------------
    // Logic to get the enabled options for a specific radion group
    // ------------------------------
    getEnableOptions(key) {
        return this.options[`Options${key}`] ? this.options[`Options${key}`].map(item => item.enabled ? item.value : null) : []
    }
}
