import React, { Component } from 'react'
import HeaderLogo from '../../components/header-logo/HeaderLogo'
import Header from '../../components/header/Header'

const FAQs = [
    {
        question: 'Can we meet customers DB ‘smoke seal’ requirements with Flexselect?',
        answer: 'AS/NZS3000 has a requirement of IP5X against the spread of smoke from a switchboard. Flexselect boards come in IP42 and IP66, the IP66 board would meet this requirement.'
    },
    {
        question: 'Are we able to offer a DB locking solution for Schools, Mines, and PWD locks with Flexselect?',
        answer: 'IP66 boards have an option of a ‘padlockable handle’ this way customers can use their own padlocks in the above situations.'
    }, {
        question: 'Do Flexselect boards offer a separate BMS section which does not require moving the escutcheon?',
        answer: 'Flexselect offer a polycarbonate enclosure on the side of the board for easy access. Board is predrilled but enclosure is supplied loose to avoid damage in transit.'
    }, {
        question: 'CanFlexselect boards be offered with NMI meters?',
        answer: 'Flexselect has 3 metering options, Dual meter, power meter (PM3255) and NMI certified meter (EM3255-NMI)'
    }, {
        question: 'Does Flexselect come in marine grade stainless steel?',
        answer: 'The standard rangeof Flexselect are offered in mild steelbut we also offer Flexselect in 316 Stainless Steel with a slightly longer lead time.'
    }, {
        question: 'Are Flexselect boards offered as61439.2 compliant?',
        answer: 'The Flexselect tool calculates watts loses and Ina as you load the board with breakers, this is only a small part of 61439.2 complianceso a certificate of compliance is supplied with the final drawings.'
    }, {
        question: 'Can Flexselect boards be offered with both 18mm and 27mm pitch breakers?',
        answer: 'Hybrid chassis (fits both 18mm and 27mm pitchbreakers) are an option in Flexselect.'
    }, {
        question: 'I need aboard on site next week, is this possible with Flexselect?',
        answer: 'The standard dispatch time for Flexselect boards is 5 days dependent on sheet metal and component availability.'
    }, {
        question: 'Are Isobar chassis and option in Flexselect?',
        answer: 'Schneider’s isolation chassis is available in Flexselect.'
    }, {
        question: 'I need 3x boards on site every week for the next 4 weeks for a total of 12x boards, is this order suitable for Flexselect?',
        answer: 'With Flexselect up to 20x boards can be ordered at a time. The first 4x boards will be dispatched in 5 days with the remainder of 8x boards dispatching over the following 3 weeks.Depending on stock availability.'
    }, {
        question: 'Do my Flexselect boards come with the standard 1 year Schneider warranty?',
        answer: 'As per Schneiders standard terms and conditions Flexselect boards come with1 year warranty.'
    }, {
        question: 'Is my Flexselect board supplied with a test report and certificate of compliance to 61439.2?',
        answer: 'All Flexselect boards are issued with a QR code on the outside of the door. Once the customer has scanned this code and request transfer of the digital asset,they will have access to the drawings, test repots, 61439.2 documentation and data sheets for all components supplied in the board.'
    }
]

const RESIDENTIALFAQS = [
    {
        question: 'What are the benefits of using FlexSelect for loaded boards?',
        answer: ``
    },
    {
        question: 'Are the FlexSelect loaded boards supplied with complete wiring done?',
        answer: 'Clipsal by Schneider Electric only loads the breakers in the boards and connects the busbar to them as per the user’s selection. It is the responsibility of Licensed electrician to make sure that the products selected are suitable for their applications. Before powering up the board, a licensed electrician must also check all the busbar connections to make sure the connection hasn’t come loose during transportation and handling.'
    }, {
        question: 'What are recommended applications for FlexSelect residential?',
        answer: 'FlexSelect loaded boards under ‘Residential’ category are more suitable for applications where electrician needs multiple plastic enclosure with similar configuration. An example of that could be a big apartment building where the user might only have single digit variations of plastic enclosures.'
    }, {
        question: 'Is there any minimum order qty for ordering load centres using FlexSelect?',
        answer: 'Minimum order quantity of 30 boards only applies to the total number of boards in a project. Without a minimum qty of 30 boards in one project (hence in 1 order) the user can’t proceed to view the quote or place an order using FlexSelect residential. User might have different type of board configurations in a project but the total of these various configurations in a project must be equal to or more than 30.'
    }, {
        question: 'Are the circuit ID labels attached to the front cover of enclosure?',
        answer: 'No the circuit ID labels are not attached to the front cover of enclosure. If there are circuit ID labels inside the board commercial reference, then the same circuit ID labels are repacked inside the load centres. In some cases, the user might need to order extra circuit ID labels through their wholesaler or e-commerce partner.'
    }, {
        question: 'How do I know if the product that I am selecting is a Resi MAX or MAX9 product? ',
        answer: 'You can work this out by looking at the kA rating of the breakers when you click on ‘Add Breaker’ option. Resi MAX products are 4.5kA rated and MAX9 products are 6kA rated (see below image)'
    }, {
        question: 'What are the busbar options available for Clipsal RCBOs',
        answer: ''
    }, {
        question: 'What is the recommended product selection procedure/sequence for loaded boards in FlexSelect?',
        answer: ''
    }, {
        question: 'Is neutral terminal block added with top feeding busbar switching neutral?',
        answer: 'Yes, the neutral terminal block is added automatically to the product layout as soon as you choose top mounted busbar option for a row with main incomer switch. Please note that this neutral terminal block is a solid neutral link and doesn’t switch the neutral.'
    }, {
        question: 'What are the benefits of selecting extra poles when selecting busbars?',
        answer: 'When you are making your selections for busbars by clicking on ‘Busbar’ button, you will notice that there is an option of extra poles. By selecting the number of extra poles that you need, you can request a longer length of busbar that will overhang the unoccupied poles in that row of the board. This helps you to make your board future ready….if you need to add more RCBO’s in future then you can just remove the tooth cover and add the device.'
    }
]
export class TechnicalInformation extends Component {
    constructor(props) {
        super(props)

        this.state = {
            isCommercial: true,
            selectedProjectTypeForCreation: 'commercial'
        }
    }

    render() {
        return (
            <div className="app">

                <Header isCommercial={this.state.isCommercial}>
                    <div className="header__section hs_title hs_project-start">
                        <h1 className="header__title h3">
                            Technical Information
                            <label className="config-switch">
                                <input type="checkbox" className="switch__input" checked={!this.state.isCommercial}
                                    onChange={() => this.setState({ isCommercial: !this.state.isCommercial })} />

                                <div className="switch__button switch__button--on">
                                    <span className="switch__button-icon">
                                        <span className="i-commercial"></span>
                                    </span>
                                    <span className="switch__button-text">Commercial</span>
                                </div>
                                <span className="switch__slider slider round"></span>
                                <div className="switch__button switch__button--off">
                                    <span className="switch__button-icon">
                                        <span className="i-home"></span>
                                    </span>
                                    <span className="switch__button-text">Residential</span>
                                </div>
                            </label>
                        </h1>
                    </div>
                </Header>

                <div className="app__body app__body--subpage">
                    <div className="app__body-content">

                        <div className="subpage-content">

                            {this.state.isCommercial &&
                                <div>
                                    <section className="guide">
                                        <h3 className="guide__title h4">
                                            MAIN SWITCH
                                        </h3>
                                        <div>
                                            <div>
                                                <p className="guide__info">
                                                    Switch disconnector CVS250NA<br />
                                                </p>
                                                <span className="guide__subtitle p">Technical Data Sheets</span>
                                                <div className="guide__links">
                                                    <div className="guide__link-container">
                                                        <a href="https://www.se.com/au/en/product/LV525425/switch-disconnector-easypact-cvs250na-3-poles-250-a-ac22a-ac23a/" target="_blank">
                                                            <span className="">
                                                                More Information
                                                            </span>
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                            <div>
                                                <p className="guide__info">
                                                    NSX100F TM-D (36kA at 415V) molded case circuit breaker<br />
                                                </p>
                                                <span className="guide__subtitle p">Technical Data Sheets</span>
                                                <div className="guide__links">
                                                    <div className="guide__link-container">
                                                        <a href="https://www.se.com/au/en/product/C10F3TM100/circuit-breaker-compact-nsx100f-36ka-415vac-3-poles-tmd-trip-unit-100a/" target="_blank">
                                                            <span className="">
                                                                More Information
                                                            </span>
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                            <div>
                                                <p className="guide__info">
                                                    NSX160F TM-D (36kA at 415V) molded case circuit breaker<br />
                                                </p>
                                                <span className="guide__subtitle p">Technical Data Sheets</span>
                                                <div className="guide__links">
                                                    <div className="guide__link-container">
                                                        <a href="https://www.se.com/au/en/product/C16F3TM160/circuit-breaker-compact-nsx160f-36ka-415vac-3-poles-tmd-trip-unit-160a/" target="_blank">
                                                            <span className="">
                                                                More Information
                                                            </span>
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                            <div>
                                                <p className="guide__info">
                                                    NSX250F TM-D (36kA at 415V) molded case circuit breaker<br />
                                                </p>
                                                <span className="guide__subtitle p">Technical Data Sheets</span>
                                                <div className="guide__links">
                                                    <div className="guide__link-container">
                                                        <a href="https://www.se.com/au/en/product/C25F3TM250/circuit-breaker-compact-nsx250f-36ka-415vac-3-poles-tmd-trip-unit-250a/" target="_blank">
                                                            <span className="">
                                                                More Information
                                                            </span>
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </section>
                                    <section className="guide">
                                        <h3 className="guide__title h4">
                                            POWER METERS
                                        </h3>
                                        <div className="guide__info">
                                            <h3 className='h4'>A9MEM3255-NMI</h3>
                                            <p>
                                                Electrical Parameters (kWh (4Q), kVArh, I, U, F, P, Q, S, PF)<br />
                                                kWh Accuracy Class 0.5S<br />
                                                Modbus RS485 communication<br />
                                                1 DI, 1 DO - Over Load alarm or Energy pulsing<br />
                                                Multi-tariff (max 4 tariffs)<br />
                                            </p>
                                            <span className="guide__subtitle p">Technical Data Sheets</span>
                                            <div className="guide__links">
                                                <div className="guide__link-container">
                                                    <a href="https://www.se.com/au/en/product/A9MEM3255-NMI/acti9-iem3255-energy-meter-ct-modbus-1-digital-i-1-digital-o-multitariff-nmi/" target="_blank">
                                                        <span className="">
                                                            More Information
                                                        </span>
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="guide__info">
                                            <h3 className='h4'>METSEEMD461R</h3>
                                            <p >
                                                Dual load multifunction energy meter<br />
                                                Quick-connect CTs<br />
                                                Electrical Parameters (kWh, kVArh, I, U, F, P, Q, S, PF)<br />
                                                kWh Accuracy Class 1<br />
                                                Modbus RS485 communication<br />
                                            </p>
                                            <span className="guide__subtitle p">Technical Data Sheets</span>
                                            <div className="guide__links">
                                                <div className="guide__link-container">
                                                    <a href="https://www.se.com/au/en/faqs/FAQ000236097/" target="_blank">
                                                        <span className="">
                                                            More Information
                                                        </span>
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="guide__info">
                                            <h3 className='h4'>METSEPM3255</h3>
                                            <p >
                                                3-phase PM3200 DIN-mount Power meter (5A external CTs)<br />
                                                kWh (4Q), kVArh, I, U, F, P, Q, S, PF, P&I demand, THD V&I<br />
                                                Class 0.5S<br />
                                                Modbus RS485 communication<br />
                                            </p>
                                            <span className="guide__subtitle p">Technical Data Sheets</span>
                                            <div className="guide__links">
                                                <div className="guide__link-container">
                                                    <a href="https://www.se.com/au/en/product/METSEPM3255/powerlogic-pm3255-power-meter-2-digital-i-2-digital-o-rs485/" target="_blank">
                                                        <span className="">
                                                            More Information
                                                        </span>
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </section>

                                    <section className="guide">
                                        <h3 className="guide__title h4">
                                            RESIDUAL CIRCUIT DEVICES (RCBOS) – 30mA, 6kA or 10kA, C CURVE
                                        </h3>
                                        <p className="guide__info">
                                            The single-phase iC60N (6kA) and iC60H (10kA) RCBO’s self-contained residual current device provides protection of final circuits:<br />

                                        </p>

                                        <p className="guide__info">
                                            protection against short circuits and cable overloads<br />
                                            protection against electrocution by direct contact.<br />
                                        </p>

                                        <p className='guide__info'>
                                            The neutral is not interrupted when the device is tripped. Conforms to AS/NZS 61009.1<br />
                                        </p>
                                        <span className="guide__subtitle p">Product range</span>
                                        <div className="guide__links">
                                            <div className="guide__link-container">
                                                <a href="https://www.se.com/au/en/product-range/61470-acti9-ic60-rcbo/?parent-subcategory-id=1620&filter=business-5-residential-and-small-business" target="_blank">
                                                    <span className="button__text">
                                                        More Information
                                                    </span>
                                                </a>
                                            </div>
                                        </div>
                                    </section>

                                    <section className="guide">
                                        <h3 className="guide__title h4">
                                            MINIATURE CIRCUIT BREAKERS (MCBS) – 6kA or 10kA, C CURVE
                                        </h3>
                                        <p className="guide__info">
                                            iC60N (6kA) and iC60H (10kA) circuit breakers are multi-standard circuit breakers which combine the following functions:<br />
                                        </p>
                                        <p className="guide__info">
                                            circuit protection against short circuit currents<br />
                                            circuit protection against overload currents<br />
                                            suitable for industrial isolation according to IEC/EN 60947-2<br />
                                            fault tripping indication by a red mechanical indicator in circuit breaker front face.<br />
                                        </p>

                                        <span className="guide__subtitle p">Product range</span>
                                        <div className="guide__links">
                                            <div className="guide__link-container">
                                                <a href="https://www.se.com/au/en/product-range/7556-acti9-ic60/?parent-subcategory-id=1605&filter=business-5-residential-and-small-business" target="_blank">
                                                    <span className="button__text">
                                                        More Information
                                                    </span>
                                                </a>
                                            </div>
                                        </div>
                                    </section>
                                </div>

                            }

                            {!this.state.isCommercial &&
                                <div>
                                    <section className="guide">
                                        <h3 className="guide__title h4">
                                            MAX9™ - The Ultimate residential solution with MAX performance.
                                        </h3>
                                        <p className="guide__info">
                                            This optimum range covers wide range of circuit protection needs for both single phase and multi phase installations. It provides SLIM RCBOs with earth leakage sensitivity of 30mA and 10mA, Super Immune RCBOs, Main switches, Miniature circuit breakers (MCBs), Surge Protection Devices (SPD), Contactors, Energy Management by Wiser solution, Premium plastic enclosures with robust construction and generous wiring space and accessories to cover all your needs. Circuit breakers and RCBOs in this range are rated at short circuit breaking capacity of 6kA.
                                        </p>

                                        <div className="guide__links">
                                            <div className="guide__link-container">
                                                <a href="https://www.clipsal.com/max9" target="_blank">
                                                    <span className="">
                                                        More Information
                                                    </span>
                                                </a>
                                            </div>
                                        </div>

                                    </section>
                                    <section className="guide">
                                        <h3 className="guide__title h4">
                                            Resi MAX™ - Everyday essential needs for residential circuit protection.
                                        </h3>
                                        <p className="guide__info">
                                            The Clipsal Resi MAX™ range offers a variety of consumer switchboards, SLIM RCBOs, miniature circuit breakers (MCB), Surge protection device (SPD), safety switches (RCD), comb busbars and accessories. SLIM RCBOs and MCBs have short circuit breaking capacity rating of  4.5kA, it achieves a 6kA rating when combined with the HRC service fuse of up to 100A rating.
                                        </p>

                                        <div className="guide__links">
                                            <div className="guide__link-container">
                                                <a href="https://www.clipsal.com/resimax" target="_blank">
                                                    <span className="">
                                                        More Information
                                                    </span>
                                                </a>
                                            </div>
                                        </div>
                                    </section>
                                </div>
                            }
                        </div>
                        <div className="faqs-content">

                            {this.state.isCommercial &&
                                <div>
                                    <section className="faqs">
                                        <h3 className="faqs__title h4">
                                            Frequently Asked questions
                                        </h3>
                                    </section>
                                    <section className="faqs">
                                        <div className='faqs__questions faqs__video-title'>Learn how to configure and order your distribution boards </div>
                                        <iframe width="95%" height="289" src="https://www.youtube.com/embed/7ZOm99ud7y8?modestbranding=1&autohide=1&showinfo=0&controls=1&rel=0" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
                                    </section>
                                    <section className="faqs que-ans-container">
                                        {FAQs.map((faq, index) => {
                                            return (
                                                <div key={`queans-${index}`}>
                                                    <div className='faqs__questions'>
                                                        {faq.question}
                                                    </div>
                                                    <div className='faqs__answer'>
                                                        {faq.answer}
                                                    </div>
                                                </div>
                                            )
                                        })
                                        }
                                    </section>
                                </div>
                            }

                            {!this.state.isCommercial &&
                                <div>
                                    <section className="faqs">
                                        <h3 className="faqs__title h4">
                                            Frequently Asked questions
                                        </h3>
                                    </section>
                                    <section className="faqs">
                                        <div className='faqs__questions faqs__video-title'>Learn how to configure and order your distribution boards </div>
                                        <iframe width="95%" height="289" src="https://www.youtube.com/embed/FJjpmr_3PUQ?si=X0QER-W3b1De_hDD" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
                                    </section>
                                    <section className="faqs que-ans-container">
                                        {RESIDENTIALFAQS.map((faq, index) => {
                                            if (index === 0) {
                                                return (
                                                    <div key={`queans-${index}`}>
                                                        <div className='faqs__questions'>
                                                            {faq.question}
                                                        </div>
                                                        <div className='faqs__answer'>
                                                            <div>
                                                                <p>Some of the key benefits of using FlexSelect for loaded boards are:</p>
                                                                <ul className='faqs__points'>
                                                                    <li>Flexibility of customizing the layout of devices in a board as per your preference</li>
                                                                    <li>Manage both chassis boards and loaded boards using FlexSelect gives the user the option of managing the complete project in one location</li>
                                                                    <li>Faster installation on site as the boards come loaded with breakers and busbars based on your selection</li>
                                                                    <li>Less packaging waste on-site to dispose of</li>
                                                                    <li>Faster turnaround on quotes for projects and delivery times of loaded boards</li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            } else if (index === 5) {
                                                return (
                                                    <div key={`queans-${index}`}>
                                                        <div className='faqs__questions'>
                                                            {faq.question}
                                                        </div>
                                                        <div className='faqs__answer'>
                                                            <div>
                                                                <p>You can work this out by looking at the kA rating of the breakers when you click on ‘Add Breaker’ option. Resi MAX products are 4.5kA rated and MAX9 products are 6kA rated (see below image)</p>
                                                                <div>
                                                                    <img src="./addBreaker.png" alt="" />
                                                                </div>
                                                                <p>In case of selecting surge protection, Resi MAX SPD 20kA maximum discharge capacity and MAX9 SPDs have 40kA maximum discharge capacity.</p>
                                                                <div>
                                                                    <img src="./editBoard.png" alt="" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            } else if (index === 6) {
                                                return (
                                                    <div key={`queans-${index}`}>
                                                        <div className='faqs__questions'>
                                                            {faq.question}
                                                        </div>
                                                        <div className='faqs__answer'>
                                                            <div>
                                                                <p>There are 2 options for busbar selections for RCBOs:</p>
                                                                <ul className='faqs__points'>
                                                                    <li>Top mounted busbar – This is MAX9 MAXBAR and it connects to all type of Clipsal RCBOs, it distributes both active & neutral to all RCBOs (no need for terminating flying neutral leads) and it can connect directly to the main incomer.</li>
                                                                    <li>Bottom mounted busbar – These are Resi MAX SLIM comb busbars and can only connect SLIM RCBOs together…doesn’t connect to other type of RCBOs. This busbar also distributes both active & neutral to the SLIM RCBOs but doesn’t connect to main switch. You will need to bring in the active and neutral feed to these busbars using right size cables and connectors.</li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            } else if (index === 7) {
                                                return (
                                                    <div key={`queans-${index}`}>
                                                        <div className='faqs__questions'>
                                                            {faq.question}
                                                        </div>
                                                        <div className='faqs__answer'>
                                                            <div>
                                                                <p>Ideally, we would recommend the below sequence for selecting products using FlexSelect for loaded boards:</p>
                                                                <ul className='faqs__points'>
                                                                    <li>Choose the type (Surface/Flush) & size of your enclosure</li>
                                                                    <li>Select the main incomer type (Isolator switch or MCB), number of poles, and the ratings of the main incomer suitable for your application</li>
                                                                    <li>Decide if you need an SPD or not in your board</li>
                                                                    <li>Add breakers (MCB, RCD, RCBO, AFDD, etc.) to your board in your preferred location</li>
                                                                    <li>Add busbars (top or bottom connection) to all the rows of boards that you would like to connect to</li>
                                                                    <li>Add any ‘Additional items’ like contactors & DIN mounted socket outlets</li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            } else {
                                                return (
                                                    <div key={`queans-${index}`}>
                                                        <div className='faqs__questions'>
                                                            {faq.question}
                                                        </div>
                                                        <div className='faqs__answer'>
                                                            {faq.answer}
                                                        </div>
                                                    </div>
                                                )
                                            }
                                        })}
                                    </section>
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
